import axiosInstance from "../helpers/axiosIntercepter";

export const createFormation = async (formData) => {
  try {
    const response = await axiosInstance.post("/formation", formData);
    return response.data;
  } catch (error) {
    console.error("Error creating formation", error);
    throw error;
  }
};

export const assignFormation = async (formData) => {
  try {
    const response = await axiosInstance.post("/formation/assign", formData);
    return response.data;
  } catch (error) {
    console.error("Error assigning formation:", error.response ? error.response.data : error.message);
    throw new Error(error.response?.data?.message || "Failed to assign formation. Please try again later.");
  }
};


export const desassignFormation = async (formData) => {
  try {
    const response = await axiosInstance.post("/formation/deassign", formData);
    return response.data;
  } catch (error) {
    console.error("Error desassigning formation:", error.response ? error.response.data : error.message);
    throw new Error(error.response?.data?.message || "Failed to desassign formation. Please try again later.");
  }
};

export const getAllFormations = async (page = 1, limit = 10, searchTerm = "") => {
  try {
    const response = await axiosInstance.get("/formation", {
      params: {
        page,      
        limit,     
        searchTerm 
      },
    });

    return {
      data: response?.data?.formation || [], 
      totalDocs: response?.data?.totalDocs || 0, 
      totalPages: response?.data?.totalPages || 0, 
      currentPage: response?.data?.currentPage || 1,
      hasNextPage: response?.data?.hasNextPage || false,
      hasPreviousPage: response?.data?.hasPreviousPage || false, 
    };
  } catch (error) {
    console.error("Error fetching all formations:", error);
    throw new Error("Failed to fetch formations. Please try again later.");
  }
};



export const deleteFormation = async (id) => {
  try {
    const response = await axiosInstance.delete(`/formation/${id}`);
    return response.data;
  } catch (error) {
    console.error(`Error deleting formation with ID: ${id}`, error);
    throw new Error("Failed to delete the formation. Please try again later.");
  }
};

export const updateFormationn = async (id, formData) => {
  try {
    const response = await axiosInstance.patch(
      `/formation/${id}`,
      formData
    );
    return response.data;
  } catch (error) {
    console.error(`Error updating formation with ID: ${id}`, error);
    throw new Error("Failed to update the formation. Please try again later.");
  }
};


export const getFormationById = async (id) => {
  try {
    const response = await axiosInstance.get(`/formation/${id}`);
    return response.data;
  } catch (error) {
    console.error(`Error fetching formation with ID: ${id}`, error);
    throw error;
  }
};

export const getUsersRetenue = async (page = 1, limit = 10, searchTerm = "") => {
  try {
    const response = await axiosInstance.get("/survey-response/list-retunue-users", {
      params: {
        page,
        limit,
        searchTerm
      },
    });

    return {
      data: response?.data || [],
      totalDocs: response?.data?.totalDocs || 0,
      totalPages: response?.data?.totalPages || 0,
      currentPage: response?.data?.currentPage || 1,
      hasNextPage: response?.data?.hasNextPage || false,
      hasPreviousPage: response?.data?.hasPreviousPage || false,
    };
  } catch (error) {
    console.error("Error fetching all users:", error);
    throw new Error("Failed to fetch users. Please try again later.");
  }
};

export const getFormationsByUserId = async (keycloakUserId) => {
  try {
    const response = await axiosInstance.get(`/formation/user/${keycloakUserId}`);
    return response.data;
  } catch (error) {
    console.error(`Error fetching formations for user with ID: ${keycloakUserId}`, error);
    throw new Error("Failed to fetch formations. Please try again later.");
  }
};