import React from "react";

const Page3 = ({
  entreprise,
  montant_subvention,
  date_notif,
  date_signature,
  date_disposition,
  parapheCanvas,
  ParaphCoordinateur,
}) => {
  return (
    <div
      className="p-8 bg-white text-black"
      style={{
        width: "210mm",
        minHeight: "300mm",
        margin: "0 auto",
        padding: "10px",
        textAlign: "justify",
        boxSizing: "border-box",
        position: "relative",
      }}
    >
      <p className="mb-2 leading-[1.4]">
        <strong>•</strong> Lesdites subventions servent à financer les sous
        projets des Bénéficiaires dont les plans d’affaires auront été
        sélectionnés par le Consortium « Deloitte, I&F Entrepreneuriat et Kivu
        Entrepreneurs », dans le cadre du Concours des Plans d’Affaires, COPA,
        en sigle :
      </p>
      <p className="mb-2 leading-[1.4]">
        Pour les besoins de l’exécution de la présente convention, les parties
        contractantes acceptent expressément que toutes les
        notifications/significations pourront valablement leur être faites aux
        adresses respectives renseignées ci-dessus.
      </p>
      <h3 className="text-lg font-bold mb-3">
        CELA ETANT, IL A ETE ARRETE ET CONVENU CE QUI SUIT :
      </h3>

      <h4 className="text-md font-bold mb-2">
        Article 1 : Modalités d'exécution de la Convention de financement
      </h4>

      <h5 className="text-sm font-semibold mb-2">a - Conditions Générales :</h5>
      <p className="mb-2 leading-[1.4]">
        Les conditions générales telles que définies dans le document de
        présentation du Concours de Plan d'Affaires (COPA) sont annexées aux
        Présentes.
      </p>

      <h5 className="text-sm font-semibold mb-2">
        b - Lutte contre la Fraude et la Corruption :
      </h5>
      <p className="mb-2 leading-[1.4]">
        L’UCP-PADMPME et le Bénéficiaire veillent à ce que la présente
        Convention de financement soit exécutée conformément aux dispositions
        des « Directives pour la Prévention et la Lutte contre la Fraude et la
        Corruption dans le cadre des Projets financés par la Banque mondiale »
        annexées aux Présentes.
      </p>

      <h4 className="text-md font-bold mb-2">
        Article 2 : Objet du financement
      </h4>
      <p className="mb-2 leading-[1.4]">
        Après analyse des éléments du dossier du Bénéficiaire par le Consortium,
        il a été décidé de retenir l’entreprise {entreprise}. parmi les
        Bénéficiaires du Projet d’appui aux Micro, Petites et Moyennes
        Entreprises (PADMPME en sigle), particulièrement du Concours des Plans
        d’Affaires organisé dans le cadre du Projet.
      </p>
      <p className="mb-2 leading-[1.4]">
        La présente convention a pour objet d’octroyer au Bénéficiaire, sous
        forme de subvention, la somme de {montant_subvention}. en guise
        d’assistance au développement de ses activités, en particulier pour lui
        permettre de mettre en œuvre les activités prioritaires du plan
        d’affaires élaboré dans le contexte du COPA organisé par le PADMPME et
        qui a fait l’objet d’un recadrage en collaboration étroite avec le
        Consortium « Deloitte, I&F Entrepreneuriat et Kivu Entrepreneurs »,
        Consultants d’exécution du COPA.
      </p>
      <p className="mb-2 leading-[1.4]">
        Les fonds octroyés dans le cadre du présent financement seront mis à
        disposition du Bénéficiaire par virement sur un compte spécifique dédié
        au COPA et ouvert au nom du Bénéficiaire dans une des institutions
        financières agréées par l’UCP-PADMPME, dont les coordonnées sont
        annexées aux présentes. Le Consortium instruira utilement le
        Bénéficiaire sur cette opération, en se conformant aux dispositions
        convenues entre l’UCP-PADMPME et les institutions financières
        concernées.
      </p>
      <p className="mb-2 leading-[1.4]">
        Cette subvention lui est accordée conformément à la lettre de
        notification du <strong>{date_notif}</strong>
      </p>
      <p className="mb-2 leading-[1.4]">
        La subvention octroyée n'est assujettie à aucune taxe directe ou
        indirecte suivant les termes de l’accord de prêt conclu entre l’Etat
        Congolais et la Banque mondiale.
      </p>

      <h4 className="text-md font-bold mb-2">
        Article 3 : Période de mise en œuvre de la Convention
      </h4>
      <p className="mb-2 leading-[1.4]">
        La présente convention entre en vigueur à la date de sa signature, soit
        le <strong>{date_signature}</strong> et expirera, sauf résiliation,
        conformément à ses dispositions, le <strong>{date_disposition}</strong>.
      </p>

      <div
        style={{
          position: "absolute",
          bottom: "10px",
          left: "50%",
          transform: "translateX(-50%)",
        }}
      >
        <p className="font-semibold">Page 3 sur 9</p>
      </div>
      {parapheCanvas && (
        <div
          style={{
            position: "absolute",
            bottom: "10px",
            left: "10px",
          }}
        >
          <img
            src={parapheCanvas}
            alt="Aperçu de la signature"
            style={{
              width: "70px",
              height: "60px",
              marginLeft: "20px",
              marginBottom: "-10px",
            }}
          />
        </div>
      )}
      {ParaphCoordinateur && (
        <div
          style={{
            position: "absolute",
            bottom: "10px",
            right: "10px",
            transform: "translateX(-50%)",
          }}
        >
          <img
            src={ParaphCoordinateur}
            alt="Aperçu de la signature"
            style={{
              width: "70px",
              height: "60px",
              marginLeft: "20px",
            }}
          />
        </div>
      )}
    </div>
  );
};

export default Page3;
