import React, { useEffect, useState } from "react";
import Banner from "./Banner";
import ActualitesCard from "./ActualitesCard";
import { getAllPublicActualites } from "../../services/actualites.service";
import "./DetailsActualite/EditorStyles.css"; // Custom CSS for additional styling

const ActualitesPage = () => {
  const [actualites, setActualites] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");

  const handleSearchChange = (query) => {
    setSearchQuery(query);
  };

  useEffect(() => {
    const fetchActualites = async () => {
      try {
        const response = await getAllPublicActualites(1, 10, searchQuery);
        setActualites(response?.data?.articles || []);
      } catch (error) {
        console.error("Error fetching actualités:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchActualites();
  }, [searchQuery]);

  return (
    <div className="refactor-table">
      <Banner searchQuery={searchQuery} onSearchChange={handleSearchChange} />

      <div className="container mx-auto py-12 w-[96%] md:w-[80%] ">
        <div className="my-4 md:my-12">
          <h2 className="section-title">Actualités et articles</h2>
          <p className="mt-2 text-gray-600 w-full md:w-2/3">
            Notre plateforme vous accompagne à chaque étape pour transformer vos
            idées en entreprises florissantes avec les outils et ressources
            nécessaires.
          </p>
        </div>

        <ActualitesCard actualites={actualites} loading={loading} />
      </div>
    </div>
  );
};

export default ActualitesPage;
