import React from "react";

const Page8 = ({ parapheCanvas, ParaphCoordinateur }) => {
  return (
    <div
      className="p-8 bg-white text-black"
      style={{
        width: "210mm",
        minHeight: "300mm", // Increased height
        margin: "0 auto",
        padding: "10px", // Reduced margin
        textAlign: "justify",
        boxSizing: "border-box",
        position: "relative",
      }}
    >
      <p className="mb-1 leading-[1.3] ">
        La présente convention peut également être résiliée notamment dans les
        cas suivants :
      </p>
      <div className="mb-1 ml-6 ">
        <p className="mb-1 before:content-['•'] before:mr-2">
          En cas de saisie pratiquée à charge du Bénéficiaire ;
        </p>
        <p className="before:content-['•'] before:mr-2 mb-1">
          Lorsque le Bénéficiaire est déclaré en état de faillite ou qu’il fait
          l’objet d’une procédure d’insolvabilité ;
        </p>
        <p className="before:content-['•'] before:mr-2 mb-1">
          Lorsque le Bénéficiaire a été reconnu coupable d’un délit mettant en
          cause sa moralité professionnelle ;
        </p>
        <p className="before:content-['•'] before:mr-2 mb-1">
          Lorsqu’il est établi, par un jugement définitif ou une décision
          administrative définitive ou sur base de preuves irréfutables que le
          Bénéficiaire a commis des actes de fraude ou de corruption ou a
          participé à une organisation criminelle, à des opérations de
          blanchiment d’argent ou de financement du terrorisme, infraction liée
          au terrorisme, travail des enfants ou toute autre forme de traite
          d’êtres humains ou a commis une irrégularité ;
        </p>
        <p className="before:content-['•'] before:mr-2 mb-1">
          Lorsqu’un changement juridique, financier, technique, d’organisation
          du Bénéficiaire ou de changement de l’Actionnariat remet en cause la
          décision de l’octroi de la subvention ;
        </p>
        <p className="before:content-['•'] before:mr-2 mb-1">
          Lorsque le Bénéficiaire ou toute personne apparentée s’est rendu
          coupable de fausses déclarations en fournissant des renseignements
          exigés pour la participation à la procédure de sélection des
          Bénéficiaires ou n’a pas fourni ces renseignements ou ne les a pas
          fournis dans les délais requis ;
        </p>
        <p className="before:content-['•'] before:mr-2 mb-1">
          Lorsqu’il est établi que le Bénéficiaire se trouve dans une situation
          de conflit d’intérêts.
        </p>
      </div>

      <h4 className="text-md font-bold mb-1">
        Article 14 : Effets de la résiliation
      </h4>
      <p className="mb-1 leading-[1.3]">
        En cas de résiliation de la présente convention, il est mis un terme aux
        paiements liés à son exécution. Si cette résiliation intervient pour
        non-respect des clauses de la présente convention, l’UCP-PADMPME se
        réserve le droit d’exiger le remboursement avant le terme convenu de la
        totalité des fonds alloués - capital de la subvention, intérêts et
        pénalités inclus - et ce, aux taux les plus élevés pratiqués par
        l’institution financière dans laquelle la subvention est logée.
      </p>
      <p className="mb-1 leading-[1.3]">
        Faute d’exécution volontaire du Bénéficiaire, l’UCP-PADMPME pourra être
        amenée à initier toute procédure de recouvrement forcé.
      </p>

      <h4 className="text-md font-bold mb-1">Article 15 : Publicité</h4>
      <p className="mb-1 leading-[1.3]">
        Le Bénéficiaire accepte de reconnaître le soutien du Projet d’Appui au
        Développement des Micro, Petites et Moyennes Entreprises, PADMPME, dans
        tout document promotionnel relatif à son projet.
      </p>
      <p className="mb-1 leading-[1.3]">
        Il reconnaît également au Consortium et à l’UCP-PADMPME le droit
        d’utiliser ses images et produits concernant le Concours des Plans
        d’Affaires, « COPA », à des fins de campagne promotionnelle ou de
        communication.
      </p>

      <h4 className="text-md font-bold mb-1">Article 16 : Confidentialité</h4>
      <p className="mb-1 leading-[1.3]">
        Sauf accord préalable et écrit de la partie concernée, les parties
        s’engagent à préserver la confidentialité de toute information
        communiquée par écrit ou autrement, qui est liée à l’exécution de la
        présente convention. Il s’agit particulièrement des informations
        spécifiques sur le Bénéficiaire.
      </p>
      <p className="mb-1 leading-[1.3]">
        Les informations sur le modèle d’affaires ou toutes celles qui sont
        utilisées à des fins de visibilité, en vertu des dispositions de
        l’alinéa 1 du présent article ne sont pas considérées comme étant
        confidentielles.
      </p>

      <h4 className="text-md font-bold mb-1">
        Article 17 : Règlement des litiges
      </h4>
      <p className="mb-1 leading-[1.3]">
        En cas de litige résultant de l’interprétation ou de l’exécution de la
        présente convention, le litige fera
      </p>
      <div
        style={{
          position: "absolute",
          bottom: "10px",
          left: "50%",
          transform: "translateX(-50%)",
        }}
      >
        <p className="font-semibold">Page 8 sur 9</p>
      </div>
      {parapheCanvas && (
        <div
          style={{
            position: "absolute",
            bottom: "10px",
            left: "10px",
          }}
        >
          <img
            src={parapheCanvas}
            alt="Aperçu de la signature"
            style={{
              width: "70px",
              height: "60px",
              marginLeft: "20px",
              marginBottom: "-10px",
            }}
          />
        </div>
      )}
      {ParaphCoordinateur && (
        <div
          style={{
            position: "absolute",
            bottom: "10px",
            right: "10px",
            transform: "translateX(-50%)",
          }}
        >
          <img
            src={ParaphCoordinateur}
            alt="Aperçu de la signature"
            style={{
              width: "70px",
              height: "60px",
              marginLeft: "20px",
            }}
          />
        </div>
      )}
    </div>
  );
};

export default Page8;
