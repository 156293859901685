
import axiosInstance from "../helpers/axiosIntercepter";

export const assignDocumentAnnexe = async (formId, surveyResponseIds) => {
  try {
    const response = await axiosInstance.post(
      `/survey-response/assign-document-annexe/${formId}`,
      { surveyResponseIds }
    );
    return response.data;
  } catch (error) {
    console.error("Error assigning document de cadrage", error);
    throw error;
  }
};

export const assignDocumentCadrage = async (formId, surveyResponseIds) => {
  try {
    const response = await axiosInstance.post(
      `/survey-response/assign-document-cadrage/${formId}`,
      { surveyResponseIds }
    );
    return response.data;
  } catch (error) {
    console.error("Error assigning document de cadrage", error);
    throw error;
  }
};

export const unassignDocumentAnnexe = async (surveyResponseIds) => {
  try {
    const response = await axiosInstance.post(
      "/survey-response/unassign-document-annexe",
      { surveyResponseIds }
    );
    return response.data;
  } catch (error) {
    console.error("Error assigning document de cadrage", error);
    throw error;
  }
};

export const unassignDocumentCadrage = async (surveyResponseIds) => {
  try {
    const response = await axiosInstance.post(
      "/survey-response/unassign-document-cadrage",
      { surveyResponseIds }
    );
    return response.data;
  } catch (error) {
    console.error("Error assigning document de cadrage", error);
    throw error;
  }
};

export const uploadAnnexeDocs = async (surveyResponseId, annexeDocsResponses) => {
  try {
    const response = await axiosInstance.patch(
      `/survey-response/${surveyResponseId}/upload-annexe-docs`,
      annexeDocsResponses
    );
    return response.data;
  } catch (error) {
    console.error("Error uploading annexe documents", error);
    throw error;
  }
};

export const uploadCadrageDocs = async (surveyResponseId, cadrageDocsData) => {
  try {
    const response = await axiosInstance.patch(
      `/survey-response/${surveyResponseId}/upload-cadrage-docs`,
      cadrageDocsData
    );
    return response.data;
  } catch (error) {
    console.error("Error uploading cadrage documents", error);
    throw error;
  }
};