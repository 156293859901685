import Banner from "./Banner";
import Counter from "./Counter";
import Visibility from "./Visibility";
import Mission from "./Mission";
import Value from "./Value";
import processus from "../../assets/precessus-icon.png";
import acceesImg from "../../assets/accees.png";
import userImg from "../../assets/user.png";

const About = () => {
  const workflowContent = [
    {
      id: 1,
      title: "Améliorer leur résilience face aux chocs économiques ",
      description: "Une cohorte PME et 2 cohortes Nouvelles Entreprises.",
      icon: processus,
    },
    {
      id: 2,
      title: "Faciliter l'accès au financement ",
      description:
        "Des processus d’éligibilité et de sélection définis consensuellement avec les parties prenantes de l’écosystème entrepreneurial sur l’ensemble des villes du projet.",
      icon: acceesImg,
    },
    {
      id: 3,
      title: "Renforcer les performances, la compétitivité et l'accompagnement",
      description:
        "L’accès à des formations qualitatives visant à renforcer les capacités des entrepreneurs et à améliorer leur performance.",
      icon: userImg,
    },
    {
      id: 4,
      title: "Accélérer la création d’emplois durables.",
      description:
        "L’accès à des formations qualitatives visant à renforcer les capacités des entrepreneurs et à améliorer leur performance.",
      icon: userImg,
    },
  ];
  const ProcessCard = ({ title, description, icon }) => {
    return (
      <div className="flex flex-col justify-between items-center bg-[#F9F9F9] rounded-md p-6 max-w-[409px] h-full border-[0.75px] border-[#e1e0e0] flex-grow">
        <img src={icon} width={65} height={65} alt="" />
        <h1 className="font-semibold text-[#2F2F2F] text-sm text-center my-4 w-full">
          {title}
        </h1>
      </div>
    );
  };
  return (
    <>
      <Banner />
      <div className="container my-5">
        <div className="px-[2%] md:px-[10%] ">
          <Visibility />

          <Counter />

          <Mission />

          <div className="container flex flex-col items-center mt-[40px]">
            <h2 className="text-[#0976BC] font-roboto-slab text-center">
              Booster le développement de 3 850 entreprises à travers la RDC
            </h2>
            <ul className="flex flex-col items-center gap-2 w-full   text-gray-600 text-base p-0 ">
              <li className="flex flex-col px-4 text-center font-reboto">
                Priorité aux secteurs productifs (industrie légère,
                transformation et conditionnement, agroalimentaire,
                <br /> services à forte valeur ajoutée, entrepreneuriat social)
              </li>
              <li className="flex flex-col px-4 text-center font-reboto">
                Priorité à l’entrepreneuriat féminin
              </li>
              <li className="flex flex-col px-4 text-center font-reboto">
                Priorité au développement d’activités respectueuses de
                l’environnement
              </li>
            </ul>
          </div>

          <Value />
          <div>
            <h1 className="font-roboto-slab text-3xl mt-5 mb-2 text-center">
              Impact du COPA
            </h1>
            <p className="text-[#808080] text-xs text-center w-[90%] xl:w-[75%] 2xl:w-[55%] mx-auto font-reboto">
              Le COPA est un levier de pérennisation pour les PME et nouvelles
              entreprises. A travers un programme d’accompagnement technique et
              financier, le COPA TRANSFORME permettra aux entreprises de :
            </p>
            <div className="grid grid-cols-1 lg:grid-cols-4 gap-6 justify-items-stretch mb-6 ">
              {workflowContent.map((item) => (
                <ProcessCard
                  key={item.id}
                  title={item.title}
                  icon={item.icon}
                />
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* 
      <Team /> */}
    </>
  );
};

export default About;
