import React, { useState } from "react";
import Convention from "../../components/convention/Convention";

const DocsSigné = ({ survey_response_id, onClose }) => {
  const [isFullscreen, setIsFullscreen] = useState(false);

  const toggleFullscreen = () => {
    setIsFullscreen(!isFullscreen);
  };

  return (
    <div
      className={`fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center z-50 ${
        isFullscreen ? "overflow-hidden" : ""
      }`}
    >
      <div
        className={`bg-white rounded-lg shadow-lg ${
          isFullscreen
            ? "w-full h-full p-8"
            : "w-full max-w-5xl h-2/3 md:h-auto p-4 md:p-6"
        } flex flex-col`}
      >
        <div
          className="overflow-y-auto flex-grow"
          style={isFullscreen ? {} : { maxHeight: "60vh" }}
        >
          <Convention  survey_response_id={survey_response_id}/>
        </div>
        <div className="mt-6 flex justify-between items-center">
          <button
            onClick={toggleFullscreen}
            className="text-gray-500 hover:text-gray-700"
          >
            {isFullscreen ? "Quitter le plein écran" : "Plein écran"}
          </button>
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700 text-center"
          >
            Fermer
          </button>
        </div>
      </div>
    </div>
  );
};

export default DocsSigné;
