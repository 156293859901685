import { createContext, useContext, useEffect, useState } from "react";
import { decodeToken } from "../helpers/decodeToken";
import { loginService } from "../services/auth.service";
import { Bounce, toast } from "react-toastify";
import { getUserProfile } from "../services/user.service";

export const AuthContext = createContext(null);

export default function AuthContextProvider({ children }) {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState(null);
  const [userId, setUserId] = useState(null);
  const [userRole, setUserRole] = useState(null);

  const [userData, setUserData] = useState(null);
  const [token, setToken] = useState("");

  const fetchUserProfile = (user_id) => {
    getUserProfile(user_id)?.then((data) => setUserData(data));
  };

  useEffect(() => {
    const access_token = localStorage.getItem("access-token");

    if (access_token) {
      setIsAuthenticated(true);
      const decodedToken = decodeToken(access_token);
      setUser(decodedToken?.name);
      setUserId(decodedToken?.sub);
      fetchUserProfile(decodedToken?.sub);

      const realmRoles = decodedToken?.realm_access?.roles || [];

      if (realmRoles.includes("coach")) {
        setUserRole("coach");
      } else if (realmRoles.includes("unite_de_gestion")) {
        setUserRole("unite_de_gestion");
      } else if (realmRoles.includes("laureat")) {
        setUserRole("laureat");
      } else if (realmRoles.includes("unite_de_coordination_de_projet")) {
        setUserRole("unite_de_coordination_de_projet");
      } else if (realmRoles.includes("consortium")) {
        setUserRole("consortium");
      } else if (realmRoles.includes("evaluateur")) {
        setUserRole("evaluateur");
      } else if (realmRoles.includes("coordinateur")) {
        setUserRole("coordinateur");
      } else {
        setUserRole(null);
      }
    } else {
      setIsAuthenticated(false);
      setUserRole(null);
    }
  }, [token]);

  const loginAction = async (data) => {
    try {
      const response = await loginService(data);
      if (response.status === 201 || response.status === 200) {
        const access_token = response.data.access_token;
        const refresh_token = response.data.refresh_token;

        setToken(access_token);
        localStorage.setItem("access-token", access_token);
        localStorage.setItem("refresh-token", refresh_token);

        const decodedToken = decodeToken(access_token);
        setIsAuthenticated(true);
        setUser(decodedToken?.name);
        setUserId(decodedToken?.sub);

        const realmRoles = decodedToken?.realm_access?.roles || [];
        let role = null;
        if (realmRoles.includes("coach")) {
          role = "coach";
        } else if (realmRoles.includes("unite_de_gestion")) {
          role = "unite_de_gestion";
        } else if (realmRoles.includes("laureat")) {
          role = "laureat";
        } else if (realmRoles.includes("unite_de_coordination_de_projet")) {
          role = "unite_de_coordination_de_projet";
        } else if (realmRoles.includes("consortium")) {
          role = "consortium";
        } else if (realmRoles.includes("evaluateur")) {
          role = "evaluateur";
        } else if (realmRoles.includes("coordinateur")) {
          role = "coordinateur";
        }
        setUserRole(role);
        return role;
      }
    } catch (err) {
      toast.error("Informations d'identification non valides", {
        position: "bottom-right",
        autoClose: 2000,
        theme: "colored",
        transition: Bounce,
      });
      throw err;
    }
  };

  const logOut = () => {
    setUser(null);
    setUserRole(null);
    setToken("");
    setIsAuthenticated(false);
    localStorage.removeItem("access-token");
    localStorage.removeItem("refresh-token");
    localStorage.removeItem("programId");
    localStorage.removeItem("savedResponses");
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        userRole,
        isAuthenticated,
        logOut,
        loginAction,
        userId,
        userData,
        fetchUserProfile,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
}
