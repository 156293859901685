import React, { useContext } from 'react';
import StepOne from './StepOne';
import StepTwo from './StepTwo';
import StepThree from './StepThree';
import { Outlet, useLocation } from 'react-router-dom';
import { StepContext } from '../../../../contexts/StepContext';

const CreateAppelACandidature = () => {
  const { currentStep, setCurrentStep, surveyData } = useContext(StepContext);
  const location = useLocation();

  const handleNext = () => {
    setCurrentStep(currentStep + 1);
  };

  const handlePrevious = () => {
    setCurrentStep(currentStep - 1);
  };

  const handleSubmit = () => {
    alert('Form Submitted!');
  };

  const stepStyle = (stepNumber) => {
    return currentStep >= stepNumber
      ? "text-white bg-red-500 border-red-500"
      : "text-gray-400 bg-gray-100 border-gray-200";
  };

  return (
    <div className="mx-auto p-6 text-foreground mb-4 bg-slate-50 h-full">
      <h1 className="text-3xl font-bold mb-3">
        Créer un nouvel appel à candidature
      </h1>

      {location.pathname.endsWith("create-form") ||
      location.pathname.endsWith("assign-form") ? (
        <Outlet />
      ) : (
        <div className="border rounded-lg p-4 bg-white ">
          <div className="mt-2">
            <div className="flex  items-center my-2 pb-3 ">
              <div className="flex items-center">
                <div
                  className={`w-8 h-8 flex items-center justify-center rounded-full border-1 ${stepStyle(
                    1
                  )}`}
                >
                  1
                </div>
                <span className="ml-2 text-base font-semibold text-gray-700">
                  Appel à candidature
                </span>
              </div>
              <div className="mx-3">
                <i
                  className="bi bi-chevron-right "
                  style={{ fontSize: "22px" }}
                ></i>
              </div>
              <div className="flex items-center">
                <div
                  className={`w-8 h-8 flex items-center justify-center rounded-full border-1 ${stepStyle(
                    2
                  )}`}
                >
                  2
                </div>
                <span className="ml-2 text-base font-semibold text-gray-700">
                  Formulaire
                </span>
              </div>
              <div className="mx-3">
                <i
                  className="bi bi-chevron-right "
                  style={{ fontSize: "22px" }}
                ></i>
              </div>
              <div className="flex items-center">
                <div
                  className={`w-8 h-8 flex items-center justify-center rounded-full border-1   ${stepStyle(
                    3
                  )}`}
                >
                  3
                </div>
                <span className="ml-2 text-base font-semibold text-gray-700">
                  Validation
                </span>
              </div>
            </div>
            
          </div>

          {currentStep === 1 && <StepOne handleNext={handleNext} />}
          {currentStep === 2 && (
            <StepTwo handleNext={handleNext} handlePrevious={handlePrevious} />
          )}
          {currentStep === 3 && (
            <StepThree
              handlePrevious={handlePrevious}
              handleSubmit={handleSubmit}
              surveyData={surveyData}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default CreateAppelACandidature;

