import { Button, CircularProgress } from "@mui/material";
import React, { useRef, useState } from "react";
import SignatureCanvas from "react-signature-canvas";
import { uploadFile } from "../../../../services/uploadFile.service";
import { signatureCoordinateur } from "../../../../services/convention.service";
import { Bounce, toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const SignatureCoordinateur = ({
  selectedIds,
  handleDialogClose,
  fetchCandidatures,
  setSelected,
}) => {
  const sigCanvas = useRef(null);
  const parapheCanvas = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isParapheSigned, setIsParapheSigned] = useState(false);
  const [isSignatureProvided, setIsSignatureProvided] = useState(false);

  const checkParapheSigned = () => {
    const parapheDataURL = parapheCanvas.current
      ?.getTrimmedCanvas()
      .toDataURL("image/png");
    const isNotEmpty =
      parapheDataURL && parapheDataURL !== "data:image/png;base64,";
    setIsParapheSigned(isNotEmpty);
  };

  const ensureHttps = (url) =>
    url && !url.startsWith("https://") ? `https://${url}` : url;

  const handleSignatureChange = () => {
    const signatureDataURL = sigCanvas.current
      ?.getTrimmedCanvas()
      .toDataURL("image/png");
    setIsSignatureProvided(
      signatureDataURL && signatureDataURL !== "data:image/png;base64,"
    );
  };

  const dataURLtoBlob = (dataurl) => {
    const arr = dataurl.split(",");
    const mimeMatch = arr[0].match(/:(.*?);/);
    const mime = mimeMatch ? mimeMatch[1] : "image/png";
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  };

  const clearSignature = () => {
    if (sigCanvas.current) {
      sigCanvas.current.clear();
      parapheCanvas.current.clear();
      setIsSignatureProvided(false);
      setIsParapheSigned(false);
    }
  };

  const handleSignClick = async () => {
    const signature = sigCanvas.current
      ? sigCanvas.current.getTrimmedCanvas().toDataURL("image/png")
      : null;

      const parapheUrl = parapheCanvas.current
      ? parapheCanvas.current.getTrimmedCanvas().toDataURL("image/png")
      : null;

    if (!signature || !parapheUrl || selectedIds.length === 0) {
      toast.warn(
        "Veuillez fournir une signature, un paraphe et sélectionner des identifiants.",
        {
          position: "bottom-right",
          autoClose: 2000,
          theme: "colored",
          transition: Bounce,
        }
      );
      return;
    }

    setIsLoading(true);

    try {
      const parapheBlob = dataURLtoBlob(parapheUrl);
      const signatureBlob = dataURLtoBlob(signature);

      const parapheFile = new File([parapheBlob], "paraphe.png", {
        type: "image/png",
      });
      const signatureFile = new File([signatureBlob], "signature.png", {
        type: "image/png",
      });

      const [signatureUrls, parapheUrls] = await Promise.all([
        uploadFile(signatureFile, "signatureFile", 18000, "public"),
        uploadFile(parapheFile, "parapheFile", 18000, "public"),
      ]);
  
      if (signatureUrls && parapheUrls &&  signatureUrls[0]?.url && parapheUrls[0]?.url ) {
        const signatureSecureUrl = ensureHttps(signatureUrls[0]?.url);
        const paraphSecureUrl = ensureHttps(parapheUrls[0]?.url);

        const payload = {
          signatureUrl: signatureSecureUrl,
          prapheUrl:paraphSecureUrl,
          ids: selectedIds,
        };

        await signatureCoordinateur(payload);

        toast.success("Signature envoyée avec succès !", {
          position: "bottom-right",
          autoClose: 2000,
          theme: "colored",
          transition: Bounce,
        });

        setTimeout(() => {
          handleDialogClose();
          setSelected([]);
        }, 2000);

        await fetchCandidatures();
      } else {
        throw new Error("Erreur lors du téléchargement du fichier.");
      }
    } catch (error) {
      toast.error(
        "Une erreur s'est produite lors de l'envoi de la signature.",
        {
          position: "bottom-right",
          autoClose: 2000,
          theme: "colored",
          transition: Bounce,
        }
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <p>Identifiants sélectionnés : {selectedIds.join(", ")}</p>

      <div className="flex justify-center m-4 space-x-4">
        <div>
          <h4>Paraphe*</h4>
          <SignatureCanvas
            ref={parapheCanvas}
            penColor="black"
            canvasProps={{
              width: 200,
              height: 150,
              className: "border border-black",
            }}
            onEnd={checkParapheSigned}
          />
        </div>
        <div>
          <h4>Signature*</h4>
          <SignatureCanvas
            ref={sigCanvas}
            penColor="black"
            canvasProps={{
              width: 200,
              height: 150,
              className: "border border-black",
            }}
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              zIndex: 2,
            }}
            onEnd={handleSignatureChange}
          />
        </div>
      </div>

      <div className="flex justify-center space-x-4">
        <Button
          onClick={handleDialogClose}
          variant="outlined"
          className="border-[#0976BC] text-[#0976BC] hover:bg-blue-100 rounded-full"
          style={{
            padding: "8px 24px",
            borderWidth: "2px",
            width: "80px",
            height: "35px",
          }}
        >
          Fermer
        </Button>
        <Button
          onClick={clearSignature}
          variant="outlined"
          className="border-[#EB5757] text-[#EB5757] hover:bg-red-100 rounded-full"
          style={{
            padding: "8px 24px",
            borderWidth: "2px",
            width: "80px",
            height: "35px",
          }}
        >
          Effacer
        </Button>
        <Button
          onClick={handleSignClick}
          variant="contained"
          className="rounded-full"
          style={{
            padding: "8px 24px",
            width: "80px",
            height: "35px",
            backgroundColor:
              isLoading || !isSignatureProvided ? "#c0c0c0" : "#0976BC", // Gray when disabled
            color: isLoading || !isSignatureProvided ? "#808080" : "white", // Adjust text color
            cursor:
              isLoading || !isSignatureProvided ? "not-allowed" : "pointer", // Adjust cursor style
          }}
          disabled={isLoading || (!isSignatureProvided && !isParapheSigned)} // Disable based on conditions
        >
          {isLoading ? <CircularProgress size={20} /> : "Signer"}
        </Button>
      </div>
      <ToastContainer />
    </div>
  );
};

export default SignatureCoordinateur;
