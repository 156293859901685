import React from "react";
import { useAuth } from '../../../contexts/AuthContext';
import UserSettings from "../../../components/common/UserSettings";

const UDGReglages = () => {
  const { userData, fetchUserProfile } = useAuth();

  return (
    <UserSettings userData={userData} fetchUserProfile={fetchUserProfile} />

  );
};

export default UDGReglages;
