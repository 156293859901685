import React from "react";
import bgPropos from "../../assets/images/Banner-about.jpg";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";

const Banner = ({ searchQuery, onSearchChange }) => {
  return (
    <div className="flex justify-center items-center w-full md:w-[80%] mx-auto flex-col h-[40vh] sm:h-[30vh] lg:h-[30vh] xl:h-[45vh] 2xl:h-[40vh] relative">
      <img
        src={bgPropos}
        className=" absolute inset-0 w-full h-full object-cover md:rounded-2xl"
        alt="bgPropos"
      />
      <div className="relative text-white flex flex-col justify-center items-center gap-2">
        <h1 className=" xl:w-[100%] text-center font-roboto-slab">
          Actualités et articles
        </h1>
        <p className="text-center w-[70%] sm:w-[60%]">
          Les informations courantes des activités du Projet
        </p>
      </div>
      <div className="absolute bottom-0 translate-y-1/2 w-full flex justify-center">
        <div className="relative md:w-[60%] w-[90%]">
          <MagnifyingGlassIcon className="absolute left-3 top-1/2 transform -translate-y-1/2 h-6 w-6 text-[#0976BC]" />
          <input
            type="text"
            placeholder="Recherche..."
            className="w-full pl-10 pr-4 py-3 rounded-full border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 shadow-sm"
            value={searchQuery}
            onChange={(e) => onSearchChange(e.target.value)}
          />
        </div>
      </div>
    </div>
  );
};

export default Banner;
