
import proposImg4 from "../../assets/images/Image4-propos.png";

const Mission = () => {
  return (
    <div className="flex flex-col md:flex-row justify-between items-center p-3 md:p-8 mt-5">
      <div className="w-full md:w-2/3 mb-8 md:mb-0">
        <h2 className=" mb-2 font-roboto-slab text-3xl">
          Qu'est-ce que le COPA ?
        </h2>
        <h3 className="text-xs text-reboto text-gray-500 mb-6 pr-[5%]">
          Le COPA TRANSFORME est une initiative phare du projet TRANSFORME qui a
          pour mission de renforcer le tissu économique et l'écosystème
          entrepreneurial des 13 villes retenues pour le projet. Plus
          spécifiquement, le COPA vise quatre objectifs :
        </h3>
        <ul className="space-y-4 ps-0 pr-[5%]">
          <li className="flex items-start">
            <i className="bi bi-check-circle h-6 w-6 text-green-500 mr-2"></i>
            <span className="text-sm">
              <b>Appuyer la création de nouvelles entreprises formelles,</b> en
              particulier celles dirigées par les femmes, afin d’élargir le pool
              de MPME motivées par les opportunités de marchés
            </span>
          </li>
          <li className="flex items-start">
            <i className="bi bi-check-circle h-6 w-6 text-green-500 mr-2"></i>
            <span className="text-sm">
              <b>Inciter les investissements dans des projets de croissance </b>
              à long terme qui permettent l’amélioration de la productivité et
              de la qualité, la mise à niveau technologique, la diversification
              et la création d’emplois durables et l’utilisation de technologies
              digitales résilientes au climat
            </span>
          </li>
          <li className="flex items-start">
            <i className="bi bi-check-circle h-6 w-6 text-green-500 mr-2"></i>
            <span className="text-sm">
              <b>Accompagner les institutions financières</b> partenaires pour
              assurer l’adéquation de leur offre et produits aux PME
              bénéficiaires
            </span>
          </li>
          <li className="flex items-start">
            <i className="bi bi-check-circle h-6 w-6 text-green-500 mr-2"></i>
            <span className="text-sm">
              <b>
                {" "}
                Renforcer les structures d’appui et fournisseurs de services{" "}
              </b>
              de développement des PME à travers un transfert de compétences
              pour renforcer durablement l’écosystème
            </span>
          </li>
        </ul>
      </div>

      <div className="w-full md:w-1/3 flex space-x-4 justify-center">
        {/* <img src={proposImg3} alt="Image 1" className="w-1/2 object-cover" /> */}
        <img src={proposImg4} alt="proposImg4" className="object-cover" />
      </div>
    </div>
  );
};

export default Mission;
