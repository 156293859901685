import React, { useEffect, useState } from "react";
import Banner from "../AppelsCandidature/Banner";
import { useParams, useNavigate } from "react-router-dom";
import photo from "../../assets/Photo.png";
import { getProgramById } from "../../services/program.service";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { EyeIcon } from "@heroicons/react/24/solid"; 


const DetailsAppelCandidature = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [programById, setProgramById] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false); 
  const [imageUrl, setImageUrl] = useState(photo); 

  useEffect(() => {
    const getProgram = async () => {
      try {
        const data = await getProgramById(id);
        setProgramById(data);
        const img = data?.image?.url;
        const imgURL = img?.startsWith("http")
          ? img
          : img
          ? `https://${img}`
          : photo;
        setImageUrl(imgURL); // Mettez à jour l'URL de l'image
      } catch (error) {
        console.error("Error fetching program:", error);
      }
    };

    if (id) {
      getProgram();
    }
  }, [id]);

  const handleApplyClick = () => {
    const accessToken = localStorage.getItem("access_token");
    if (accessToken) {
      localStorage.setItem("programId", id);
      navigate("/laureat-dashboard/fill-form");
    } else {
      localStorage.setItem("programId", id);
      navigate("/auth/login");
    }
  };

    const [isHovered, setIsHovered] = useState(false); 

  const openModal = () => setIsModalOpen(true);   const closeModal = () => setIsModalOpen(false); 
  return (
    <div>
      <Banner />
      <div className="container mt-20">
        <p className="text-gray-700">Accueil / Appel à candidature / Détails</p>
        <div className="flex flex-col md:flex-row gap-4 ">
         
            <div
              className="relative w-full md:w-4/6"
             
              
            >
            <LazyLoadImage
              src={imageUrl}
              alt="Program"
              className="rounded-[16px] mb-4 w-full h-[300px] object-cover cursor-pointer"
              onClick={openModal}
              
            />
            {isHovered && (
              <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 rounded-[20px]">
                <EyeIcon className="w-10 h-10 text-white" />
              </div>
            )}
            <p
              dangerouslySetInnerHTML={{ __html: programById?.description }}
            ></p>
            <h2 className="text-[#2F2F2F] font-semibold font-roboto-slab mt-4">
              Exigences
            </h2>
            <p
              dangerouslySetInnerHTML={{
                __html: programById?.exigence || "N/A",
              }}
            ></p>

            <div className="flex flex-col justify-center items-center gap-4 mb-4">
              <div className="w-full">
                <h2 className="section-title text-center mt-3">
                  Questions <br /> Fréquemment posées
                </h2>

                <div className="my-4">
                  {/* FAQ Items */}
                  <div className="border border-gray-600 rounded-md px-4 py-3 mb-2">
                    <h6 className="text-semi-bold font-roboto-slab flex justify-between items-center cursor-pointer">
                      1. Où est ma candidature ?
                      <ChevronDownIcon className="h-5 w-5 text-gray-600" />
                    </h6>
                  </div>
                  <div className="bg-gray-100 rounded-md px-4 py-3 mb-2">
                    <h6 className="text-semi-bold font-roboto-slab flex justify-between items-center cursor-pointer">
                      2. Comment puis-je retourner à ma candidature ?
                      <ChevronDownIcon className="h-5 w-5 text-gray-600" />
                    </h6>
                    <p className="text-gray-900 text-sm">
                      Pour retourner à votre candidature, vous pouvez vous
                      connecter à votre compte sur le portail de candidature.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Right Column */}
          <div className="bg-[#F6F6F6] p-6 mb-2 rounded-[16px] h-min border border-[#e1e0e0] w-full md:w-2/6">
            <h2 className="font-roboto-slab">Détails</h2>
            <p className="mb-1 text-sm">
              <span className="text-[#0976BC] text-base">
                Dates du programme
              </span>{" "}
              :{" "}
              {programById
                ? `${new Date(
                    programById.createdAt
                  ).toLocaleDateString()} - ${new Date(
                    programById.expiresIn
                  ).toLocaleDateString()}`
                : "loading"}
            </p>
            <p className="mb-1 text-sm">
              <span className="text-[#0976BC]">Organisateur </span>: UCP
              TRANSFORME
            </p>
            <p className="mb-1 text-sm">
              <span className="text-[#0976BC]">Secteurs </span>:{" "}
              {programById?.categories && programById?.categories.length > 0
                ? programById?.categories.map((cat) => cat).join(", ")
                : ""}
            </p>

            <button
              className="bg-[#0976BC] hover:bg-[#065A94] rounded-full py-2 px-6 mt-3 text-white no-underline w-full"
              onClick={handleApplyClick}
            >
              Postuler Maintenant !
            </button>
          </div>
        </div>
      </div>

      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-75">
          <div className="relative">
            <button
              className="absolute top-2 right-2 bg-white text-black rounded-full p-1"
              onClick={closeModal}
            >
              ✕
            </button>
            <LazyLoadImage
              alt="Program"
              src={imageUrl}
              className="max-w-full max-h-screen rounded-lg object-cover"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default DetailsAppelCandidature;
