import React from "react";
import NotFound from "../../assets/no-results.png";

const ErrorPage = () => {
  const handleRefresh = () => {
    window.location.reload();
  };

  return (
    <div className="flex flex-col md:flex-row items-center justify-center h-[95vh] p-[1%] bg-[#f5f6f6]">
      <div className="w-[65%] md:w-[65%] lg:w-[50%] gap-4 items-center justify-center md:items-start">
        <h1 className="text-5xl font-bold">Oups...</h1>
        <h2 className="text-xl font-semibold">Page introuvable</h2>
        <p className="text-lg text-[#4B4B4B]">
          Une erreur est survenue. Veuillez réessayer !<br />
          Nous vous suggérons d'actualiser la page.
        </p>
        <button
          className="w-[200px] bg-[#2369af] text-white py-2 px-4 rounded-md mt-4"
          onClick={handleRefresh}
        >
          Actualiser la page
        </button>
      </div>
      <div className="w-[70%] sm:w-[40%] md:w-[50%] xl:w-[30%] lg:w-[35%] p-6 md:block">
        <img src={NotFound} alt="Erreur" />
      </div>
    </div>
  );
};

export default ErrorPage;
