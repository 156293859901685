import React from "react";
import BannerImage from "../../../assets/BannerAppels.jpg";
import { calculateReadingTime } from "../../../features/ReadingTimeFunction";

const Banner = ({ actualite }) => {
  return (
    <div className="flex justify-center items-center md:w-[80%] w-full mx-auto flex-col h-[40vh] sm:h-[30vh] lg:h-[30vh] xl:h-[45vh] 2xl:h-[40vh] relative pt-3 mb-4">
      <img
        src={BannerImage}
        className="h-[40vh] sm:h-[30vh] lg:h-[30vh] xl:h-[45vh] 2xl:h-[40vh] object-cover absolute rounded-none sm:rounded-[16px]"
        alt="Banner"
      />
      <div className="relative text-white flex flex-col justify-center items-center gap-2">
        <h1 className="text-center font-roboto-slab text-3xl w-[90%]">
          Des informations détaillées pour vos réseaux sociaux
        </h1>
        <div className="flex items-center">
          <span className="text-sm mb-2 mr-2">•</span>
          <p className="text-sm mb-2">
            {new Date(actualite.created_at).toLocaleDateString("fr-FR", {
              year: "numeric",
              month: "long",
              day: "numeric",
            })}
          </p>
          <span className="text-sm mb-2 ml-8 mr-2">•</span>
          <p className="text-sm mb-2">
            {calculateReadingTime(actualite?.description || "3")} minutes de
            lecture
          </p>
        </div>
      </div>
    </div>
  );
};

export default Banner;
