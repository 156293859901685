import proposImg1 from "../../assets/images/Image1-propos.png";
import proposImg2 from "../../assets/images/Image2-propos.png";

const Visibility = () => {
  return (
    <div className="flex flex-wrap ">
      <div className="w-full lg:w-2/5 px-2 mb-4 lg:mb-0">
        <img className="w-full h-auto mb-2" src={proposImg1} alt="proposImg1" />
        <div className="flex space-x-2">
          <div className=" w-1/3 flex flex-col justify-center align-items-center text-white bg-[#0976BC] rounded-xl">
            <span className="text-4xl font-bold"> 500K</span>
            <span className="text-xs">Utilisateurs Actifs</span>
          </div>
          <img className="w-2/3 h-auto" src={proposImg2} alt="proposImg2" />
        </div>
      </div>

      <div className="w-full lg:w-3/5 px-1 sm:px-2">
        <div className="bg-white px-6 py-0">
          <h2 className=" text-2xl sm:text-3xl font-bold mb-2 font-roboto-slab ">
            C'est quoi COPA :<br />
            TRANSFORME ?
          </h2>
          <p className="text-gray-700 text-sm mb-2">
            Dans le cadre de la mise en œuvre de sa stratégie pour le
            développement du secteur privé, le Gouvernement de la RDC a lancé le
            "Projet d'autonomisation des femmes entrepreneures et mise à niveau
            des petites et moyennes entreprises pour la transformation
            économique et l'emploi en RDC », TRANSFORME RDC en sigle. Ce projet
            vise à améliorer la croissance et la création d'emplois des MPME
            (Micro, Petites et Moyennes Entreprises) nouvelles et existantes,
            notamment celles appartenant à des femmes. Le projet TRANSFORME RDC
            se décompose en plusieurs volets essentiels pour atteindre ses
            objectifs :
          </p>
          <p className="text-gray-700 text-sm">
            -        Soutien aux femmes entrepreneures, création d'entreprises
            et PME, visant à renforcer la capacité et la compétitivité des
            entreprises dirigées par des femmes.
          </p>
          <p className="text-gray-700 text-sm">
            -        Inclusion financière et accès durable au financement pour
            les femmes entrepreneures, afin de garantir des ressources
            financières adéquates pour leur expansion.
          </p>
          <p className="text-gray-700 text-sm">
            -        Développement de l'écosystème de l’entrepreneuriat, pour
            favoriser un environnement propice à la croissance des PME.
          </p>
          <p className="text-gray-700 text-sm">
            -        Réponse aux situations d’urgence (CERC), pour fournir une
            assistance rapide et appropriée en cas de crises ou d'urgences.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Visibility;
