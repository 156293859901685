import axiosInstance from "../helpers/axiosIntercepter";

export const sendContactEmail = async (contactData) => {
  try {
    const response = await axiosInstance.post("/contact", contactData);
    return response.data;
  } catch (error) {
    console.error("Failed to send contact message:", error);
    throw error;
  }
};
