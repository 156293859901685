import { CircularProgress } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { PlainLight } from "survey-core/themes";
import { Model, Survey } from "survey-react-ui";
import { getSurveyResponseById } from "../../../../services/surveyResponse.service";
import axiosInstance from "../../../../helpers/axiosIntercepter";
import ResponsesEvaluateurs from "./ResponsesEvaluateurs";
import { getUserProfile } from "../../../../services/user.service";
import { unassignEvaluator } from "../../../../services/evaluation.service";
import { toast, ToastContainer } from "react-toastify";

const DetailsCandidatures = () => {
  const { id } = useParams();
  const [evaluatorProfiles, setEvaluatorProfiles] = useState([]);
  const [data, setData] = useState(null);
  const [surveyModel, setSurveyModel] = useState(null);
  const [surveyModel2, setSurveyModel2] = useState(null);
  const [surveyModel3, setSurveyModel3] = useState(null);
  const [surveyModel4, setSurveyModel4] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showEvaluatorProfiles, setShowEvaluatorProfiles] = useState(true);

  const navigate = useNavigate();

  const isUrlExpired = (url) => {
    const urlParams = new URLSearchParams(url.split("?")[1]);

    const amzDate = urlParams.get("X-Amz-Date");
    const amzExpires = urlParams.get("X-Amz-Expires");

    if (amzDate && amzExpires) {
      const expirationDate = new Date(
        amzDate.slice(0, 4) +
          "-" + // Year
          amzDate.slice(4, 6) +
          "-" + // Month
          amzDate.slice(6, 8) +
          "T" + // Day
          amzDate.slice(9, 11) +
          ":" + // Hour
          amzDate.slice(11, 13) +
          ":" + // Minute
          amzDate.slice(13, 15) +
          "Z" // Second
      );

      const expiresInMs = parseInt(amzExpires, 10) * 1000;
      const expirationTime = expirationDate.getTime() + expiresInMs;

      return Date.now() > expirationTime;
    }

    return false;
  };

  const fetchUpdatedUrl = async (fileName) => {
    try {
      const response = await axiosInstance.get(
        `/upload/url?fileName=${fileName}&strategy=minio&visibility=private&expirationTime=180`
      );
      return response.data;
    } catch (error) {
      console.log("Error in updating expired url", error);
      return null;
    }
  };

  const updatedFilesDate = async (questions) => {
    const updatedObject = { ...questions };

    for (const [key, value] of Object.entries(updatedObject)) {
      if (Array.isArray(value) && value[0]?.content) {
        const file = value[0];
        const isExpired = isUrlExpired(file.content);
        if (isExpired) {
          const newUrl = await fetchUpdatedUrl(file.name);
          if (newUrl?.url) {
            updatedObject[key] = [{ ...file, content: newUrl.url }];
          }
        }
      }
    }

    return updatedObject;
  };

  const onDownloadFile = async (survey, options) => {
    const { file } = options;
    const { content, name } = file;

    if (content && name) {
      try {
        const response = await axiosInstance.get(content, {
          responseType: "blob",
        });

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", name);
        document.body.appendChild(link);
        link.click();
        if (link.parentNode) {
          link.parentNode.removeChild(link);
        }
        window.URL.revokeObjectURL(url);
      } catch (error) {
        console.error("Error downloading file:", error);
        alert("Failed to download the file. Please try again.");
      }
    } else {
      console.error("File content or name is missing.");
      alert("File information is incomplete.");
    }
  };

  const fetchSurveyResponse = useCallback(async () => {
    try {
      const responseData = await getSurveyResponseById(id);

      if (responseData) {
        const parsedJson = JSON.parse(
          responseData?.surveyResponse?.program?.form?.fields
        );

        const survey = new Model(parsedJson);
        survey.onDownloadFile.add(onDownloadFile);

        let mainData = responseData.surveyResponse.responses;
        mainData = await updatedFilesDate(mainData);

        survey.data = mainData;

        survey.mode = "display";
        survey.applyTheme(PlainLight);
        survey.locale = "fr";

        setSurveyModel(survey);

        const formJuridique = responseData?.surveyResponse?.juridiqueForm;

        if (formJuridique) {
          const parsedJsonJuridique = JSON.parse(formJuridique?.fildes);
          const surveyJuridique = new Model(parsedJsonJuridique);
          surveyJuridique.onDownloadFile.add(onDownloadFile);

          let juridiqueData =
            responseData?.surveyResponse?.juridiqueFormResponses;
          juridiqueData = await updatedFilesDate(juridiqueData);

          surveyJuridique.data = juridiqueData;
          surveyJuridique.mode = "display";
          surveyJuridique.applyTheme(PlainLight);
          surveyJuridique.locale = "fr";
          setSurveyModel2(surveyJuridique);
        }

        const formAnnexe = responseData?.surveyResponse?.documentAnnexe;

        if (formAnnexe) {
          const parsedJsonAnnexe = JSON.parse(formAnnexe?.fields);
          const surveyAnnexe = new Model(parsedJsonAnnexe);
          surveyAnnexe.onDownloadFile.add(onDownloadFile);

          let annexeData = responseData?.surveyResponse?.annexeDocsResponses;
          annexeData = await updatedFilesDate(annexeData);

          surveyAnnexe.data = annexeData;
          surveyAnnexe.mode = "display";
          surveyAnnexe.applyTheme(PlainLight);
          surveyAnnexe.locale = "fr";
          setSurveyModel3(surveyAnnexe);
        }

        const formCadrage = responseData?.surveyResponse?.documentCadrage;

        if (formCadrage) {
          const parsedJsonCadrage = JSON.parse(formCadrage?.fields);
          const surveyCadrage = new Model(parsedJsonCadrage);
          surveyCadrage.onDownloadFile.add(onDownloadFile);

          let cadrageData = responseData?.surveyResponse?.cadrageDocsResponses;
          cadrageData = await updatedFilesDate(cadrageData);

          surveyCadrage.data = cadrageData;
          surveyCadrage.mode = "display";
          surveyCadrage.applyTheme(PlainLight);
          surveyCadrage.locale = "fr";
          setSurveyModel4(surveyCadrage);
        }
      }
      setData(responseData);
    } catch (error) {
      console.error("Error fetching survey response:", error);
    } finally {
      setLoading(false);
    }
  }, [id]);

  useEffect(() => {
    fetchSurveyResponse();
  }, [id, fetchSurveyResponse]);

  // const evaluatorId = data?.surveyResponse?.evaluationActions?.map(
  //   (elem) => elem?.evaluatorKeyCloakId
  // );
  const fetchEvaluatorProfiles = useCallback(async (evaluators) => {
    setLoading(true);
    try {
      const profiles = await Promise.all(
        evaluators.map(async (evaluatorId) => {
          try {
            const profile = await getUserProfile(evaluatorId);
            return { evaluatorId, profile };
          } catch (error) {
            console.error(`Error fetching profile for ${evaluatorId}:`, error);
            return { evaluatorId, profile: null };
          }
        })
      );
      setEvaluatorProfiles(profiles);
    } catch (error) {
      console.error("Error fetching evaluator profiles:", error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (data?.surveyResponse?.evaluationActions) {
      const evaluatorIds = data.surveyResponse.evaluationActions
        .map((elem) => elem?.evaluatorKeyCloakId)
        .filter((id) => id !== null);

      const lastEvaluatorId = evaluatorIds[evaluatorIds.length - 1];
      if (lastEvaluatorId) {
        fetchEvaluatorProfiles([lastEvaluatorId]);
      }
    }
  }, [data, fetchEvaluatorProfiles]);

  const systemEval = data?.surveyResponse?.evaluationActions.filter(
    (item) => item?.isSystemEvaluation
  );

  if (loading) {
    return <CircularProgress size={24} />;
  }
  const handleUnassignEvaluator = async (surveyResponseId) => {
    if (!surveyResponseId) {
      toast.error("ID de réponse d'enquête manquant", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
      });
      return;
    }

    try {
      await unassignEvaluator(surveyResponseId);
      await refreshData();
      setShowEvaluatorProfiles(false);

      toast.success("Évaluateur désassigné avec succès", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
      });
    } catch (error) {
      console.error("Erreur lors du désassignement :", error);
      toast.error("Échec du désassignement. Veuillez réessayer.", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
      });
    }
  };

  const refreshData = async () => {
    await fetchEvaluatorProfiles();
  };

  return (
    <div className="mx-auto p-6 bg-card text-foreground  bg-slate-50 h-full">
      <h1 className="text-3xl font-bold mb-0">Détails de candidature</h1>
      <div className="border rounded-lg p-4 mt-3 bg-white ">
        <div className="space-y-3">
          <div className="text-gray-700 text-base">
            <span className="text-gray-950  text-lg">Status :</span>{" "}
            <div
              className={`inline-flex items-center justify-center px-[12px] py-[5px] rounded-full text-xs font-medium min-w-[90px]  ${
                data?.surveyResponse?.status === "SOUMIS"
                  ? "text-green-800 bg-green-100 border border-green-900"
                  : data?.surveyResponse?.status === "Brouillon"
                  ? "text-yellow-800 bg-yellow-100 border border-yellow-900"
                  : data?.surveyResponse?.status === "Éligible"
                  ? "text-black bg-green-100 border border-green-900"
                  : data?.surveyResponse?.status === "Rejeté"
                  ? "text-red-800 bg-red-100 border border-red-900"
                  : data?.surveyResponse?.status === "Sélectionné"
                  ? "text-black bg-green-100 border border-green-700"
                  : data?.surveyResponse?.status === "À Évaluer"
                  ? "text-black bg-yellow-100 border border-yellow-800"
                  : data?.surveyResponse?.status === "Retenue"
                  ? "text-black bg-green-100 border border-green-900"
                  : "text-blue-800 bg-blue-100 border border-blue-900"
              }`}
            >
              {data?.surveyResponse?.status || "N/A"}
            </div>
          </div>

          <div className="text-gray-700 text-base">
            <span className="text-gray-950 text-lg">Nom et prénom :</span>{" "}
            {`${data?.userData?.firstName} ${data?.userData?.lastName}` ||
              "N/A"}
          </div>

          <div className="text-gray-700 text-base">
            <span className="text-gray-950  text-lg">Adresse E-mail :</span>{" "}
            {data?.userData?.email || "N/A"}
          </div>
          <hr />
          <div className="text-gray-700 text-base">
            <span className="text-gray-950  text-lg">
              Description du projet :
            </span>{" "}
            <div
              dangerouslySetInnerHTML={{
                __html: data?.surveyResponse?.program?.description || "N/A",
              }}
            />
          </div>
          <hr />

          {systemEval && systemEval?.length > 0 && (
            <div className="flex flex-col border-2 border-[#E2F1FA] rounded-lg p-2">
              <h5>Évaluation initiale du systéme:</h5>
              <p>
                Note : {systemEval[0].note}/{systemEval[0].totalNote}
              </p>
              <p className="">
                Status :{" "}
                <span
                  className={` ${
                    systemEval[0].status === "Rejeté"
                      ? "text-[#EE5D50]"
                      : "text-[#05CD99]"
                  } `}
                >
                  {" "}
                  {systemEval[0].status === "Rejeté"
                    ? "Non éligible"
                    : systemEval[0].status}
                </span>
              </p>
            </div>
          )}
          <hr />
          {showEvaluatorProfiles && evaluatorProfiles?.length > 0
            ? evaluatorProfiles?.map((elem, index) => (
                <div key={index}>
                  <div className="flex flex-col border-2 border-[#E2F1FA] p-2 rounded-lg">
                    <h5>Évaluateur assigné :</h5>
                    {elem?.profile ? (
                      <>
                        <p className="text-gray-800">
                          <span className="font-semibold">Nom : </span>
                          {`${elem.profile.firstName} ${elem.profile.lastName}`}
                        </p>
                        <p className="text-gray-800">
                          <span className="font-semibold">E-mail : </span>
                          {elem.profile.email}
                        </p>
                      </>
                    ) : (
                      <p className="text-gray-600 italic">
                        Profil de l’évaluateur non disponible.
                      </p>
                    )}
                  </div>
                  <div className="mt-6 flex justify-between flex-row-reverse">
                    <button
                      onClick={() =>
                        handleUnassignEvaluator(data?.surveyResponse?.id)
                      }
                      className="bg-gray-200 text-gray-700 px-4 py-2 rounded-lg hover:bg-gray-300"
                    >
                      Déssigner
                    </button>
                  </div>
                  <hr />
                </div>
              ))
            : ""}

          {/* Render SurveyJS Models */}
          {surveyModel && <Survey model={surveyModel} />}
          {surveyModel2 && (
            <div className="mx-auto p-6 bg-card text-foreground bg-slate-50">
              <Survey model={surveyModel2} />
            </div>
          )}

          <ResponsesEvaluateurs
            arrayOfActions={data?.surveyResponse?.evaluationActions}
          />
        </div>
      </div>

      {surveyModel3 && (
        <>
          <h1 className="text-3xl font-bold my-4">Détails documents annexes</h1>
          <div className="border rounded-lg p-4 mt-3 bg-white ">
            <div className="space-y-3">
              <Survey model={surveyModel3} />
            </div>
          </div>
        </>
      )}

      {surveyModel4 && (
        <>
          <h1 className="text-3xl font-bold my-4">Détails documents cadrage</h1>
          <div className="border rounded-lg p-4 mt-3 bg-white ">
            <div className="space-y-3">
              <Survey model={surveyModel4} />
            </div>
          </div>
        </>
      )}

      <div className="mt-6 flex justify-between">
        <button
          onClick={() => navigate(-1)}
          className="bg-gray-200 text-gray-700 px-4 py-2 rounded-lg hover:bg-gray-300"
        >
          Retour à la page d'accueil
        </button>
      </div>
      <ToastContainer />
    </div>
  );
};

export default DetailsCandidatures;
