
export const previewFormJuridique = (formSting) => {
  let title 
  if(formSting){
      const json = JSON.parse(formSting)
      title = json.title 
    }
    return (
      <div className='flex'>
        <p className="mb-0">{title|| 'sans titre'}</p>
      </div>
    );
  };