import {
  Button,
  CircularProgress,
  Grid,
  Step,
  StepLabel,
  Stepper,
  TextField,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import SignatureCanvas from "react-signature-canvas";
import { toast, ToastContainer } from "react-toastify";
import VisulaizePdf from "./visulaizePdf";
import { uploadFile } from "../../../services/uploadFile.service";
import "react-toastify/dist/ReactToastify.css";
import {
  getMontantSubvention,
  signConvention,
  updateMontantSubvention,
} from "../../../services/convention.service";

const ConventionUploadModal = ({
  open,
  handleClose,
  surveyResponseId,
  laureat,
  program,
  conventionStatus,
}) => {
  const [activeStep, setActiveStep] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [stepLoading, setStepLoading] = useState(false);
  const [parapheDataURL, setParapheDataURL] = useState(null);
  const [signatureDataURL, setSignatureDataURL] = useState(null);
  const [isParapheSigned, setIsParapheSigned] = useState(false);
  const [isSignatureSigned, setIsSignatureSigned] = useState(false);
  const date = new Date();
  const formattedDate = `${date.getFullYear()}/${
    date.getMonth() + 1
  }/${date.getDate()}`;
  const [formData, setFormData] = useState({
    nb_dyn: "",
    cd_pme: "",
    cd_ville: "",
    grant_amount: "",
    disposition_date: "",
    ucp_address: "",
    num_ident_national: "",
    notification_date: "",
    montant_subvention: false,
    date_signature: formattedDate,
  });

  useEffect(() => {
    if (open) {
      getMontantSubvention(surveyResponseId).then((data) => {
        setFormData((prev) => ({
          ...prev,
          montant_subvention: data?.montant_subvention,
        }));
      });
    }
  }, [open]);

  const checkParapheSigned = () => {
    const parapheDataURL = parapheCanvas.current
      ?.getTrimmedCanvas()
      .toDataURL("image/png");
    const isNotEmpty =
      parapheDataURL && parapheDataURL !== "data:image/png;base64,";
    setIsParapheSigned(isNotEmpty);
  };

  const checkSignatureSigned = () => {
    const signatureDataURL = sigCanvas.current
      ?.getTrimmedCanvas()
      .toDataURL("image/png");
    const isNotEmpty =
      signatureDataURL && signatureDataURL !== "data:image/png;base64,";
    setIsSignatureSigned(isNotEmpty);
  };

  const sigCanvas = useRef(null);
  const parapheCanvas = useRef(null);

  useEffect(() => {
    if (!open) {
      resetState();
    }
  }, [open]);

  const resetState = () => {
    setActiveStep(0);
    setIsSubmitting(false);
    setStepLoading(false);
    setParapheDataURL(null);
    setSignatureDataURL(null);
    setFormData({
      nb_dyn: "",
      cd_pme: "",
      cd_ville: "",
      grant_amount: "",
      disposition_date: "",
      ucp_address: "",
      num_ident_national: "",
      notification_date: "",
      montant_subvention: "",
      date_signature: formattedDate,
    });
    setErrors({
      nb_dyn: false,
      cd_pme: false,
      cd_ville: false,
      grant_amount: false,
      disposition_date: false,
      ucp_address: false,
      num_ident_national: false,
      notification_date: false,
      montant_subvention: false,
    });
  };

  const [errors, setErrors] = useState({
    nb_dyn: false,
    cd_pme: false,
    cd_ville: false,
    grant_amount: false,
    disposition_date: false,
    ucp_address: false,
    num_ident_national: false,
    notification_date: false,
    montant_subvention: false,
  });

  const steps = [
    "Visualiser la convention",
    "Compléter les informations manquantes",
    "Visualiser la convention mise à jour",
    "Signer la convention",
    "Confirmer et soumettre",
  ];

  const dataURLtoBlob = (dataurl) => {
    const arr = dataurl.split(",");
    const mimeMatch = arr[0].match(/:(.*?);/);
    const mime = mimeMatch ? mimeMatch[1] : "image/png";
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  };

  const saveSignaturesAndNext = async () => {
    setStepLoading(true);
    try {
      const signatureDataURL = sigCanvas.current
        .getTrimmedCanvas()
        .toDataURL("image/png");
      const parapheDataURL = parapheCanvas.current
        .getTrimmedCanvas()
        .toDataURL("image/png");

      if (signatureDataURL === "data:image/png;base64,") {
        throw new Error("Signature est vide.");
      }
      if (parapheDataURL === "data:image/png;base64,") {
        throw new Error("Paraphe est vide.");
      }

      setSignatureDataURL(signatureDataURL);
      setParapheDataURL(parapheDataURL);

      setActiveStep((prevStep) => prevStep + 1);
    } catch (error) {
      console.error("Erreur lors de la sauvegarde des signatures:", error);
      toast.error(`Erreur: ${error.message}`, {
        position: "top-center",
        autoClose: 2000,
      });
    } finally {
      setStepLoading(false);
    }
  };

  const handleNext = async () => {
    if (activeStep === 1) {
      let formErrors = {
        nb_dyn: !formData.nb_dyn,
        cd_pme: !formData.cd_pme,
        cd_ville: !formData.cd_ville,
        grant_amount: !formData.grant_amount,
        disposition_date: !formData.disposition_date,
        ucp_address: !formData.ucp_address,
        num_ident_national: !formData.num_ident_national,
        notification_date: !formData.notification_date,
        montant_subvention: !formData.montant_subvention,
      };

      setErrors(formErrors);

      if (Object.values(formErrors).some((error) => error)) {
        return;
      }

      updateMontantSubvention(surveyResponseId, {
        montant_subvention: formData.montant_subvention,
      });
    }

    if (activeStep === 3) {
      await saveSignaturesAndNext();
      return;
    }

    setActiveStep((prevStep) => prevStep + 1);
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    setStepLoading(true);
    try {
      if (!parapheDataURL) {
        throw new Error("Paraphe n'a pas été sauvegardé.");
      }
      if (!signatureDataURL) {
        throw new Error("Signature n'a pas été sauvegardé.");
      }

      const parapheBlob = dataURLtoBlob(parapheDataURL);
      const signatureBlob = dataURLtoBlob(signatureDataURL);

      const parapheFile = new File([parapheBlob], "paraphe.png", {
        type: "image/png",
      });
      const signatureFile = new File([signatureBlob], "signature.png", {
        type: "image/png",
      });

      const [signatureUrls, parapheUrls] = await Promise.all([
        uploadFile(signatureFile, "signatureFile", 18000, "public"),
        uploadFile(parapheFile, "parapheFile", 18000, "public"),
      ]);

      await signConvention(
        surveyResponseId,
        signatureUrls[0]?.url,
        parapheUrls[0]?.url,
        formData
      );

      toast.success("Convention signée avec succès!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        onClose: () => {
          resetState();
          handleClose();
        },
      });
    } catch (error) {
      console.error(
        "Erreur lors de la soumission de la convention signée:",
        error
      );
      toast.error(
        `Une erreur s'est produite lors de la soumission: ${error.message}`,
        {
          position: "top-center",
          autoClose: 4000,
        }
      );
    } finally {
      setIsSubmitting(false);
      setStepLoading(false);
    }
  };

  const handleBack = () => {
    if (activeStep > 0) {
      setActiveStep((prevStep) => prevStep - 1);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const clearSignature = () => {
    sigCanvas.current?.clear();
    parapheCanvas.current?.clear();
    setSignatureDataURL(null);
    setParapheDataURL(null);
    setIsParapheSigned(false);
    setIsSignatureSigned(false);
  };

  if (!open) return null;

  if (conventionStatus === "SIGNÉ") {
    return (
      <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
        <div className="bg-white rounded-lg shadow-lg px-6 py-8 w-full max-w-4xl relative">
          <button
            className="absolute top-3 right-3 text-gray-400 hover:text-gray-600 focus:outline-none"
            onClick={handleClose}
          >
            {/* Close button SVG */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
          <VisulaizePdf survey_response_id={surveyResponseId} />
        </div>
      </div>
    );
  }

  const getDynamicTitle = (
    firstName = "N/A",
    lastName = "N/A",
    programName = "N/A"
  ) => {
    return `Signature du document par ${firstName} ${lastName} dans le cadre de l'appel à candidature: ${programName}.`;
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white rounded-lg shadow-lg px-6 py-8 w-full max-w-4xl relative">
        <button
          className="absolute top-3 right-3 text-gray-400 hover:text-gray-600 focus:outline-none"
          onClick={handleClose}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
        <h2 className="text-2xl font-bold text-center mx-10 mb-6 text-[#424243]">
          {getDynamicTitle(
            laureat?.firstName,
            laureat?.lastName,
            program?.title
          )}
        </h2>
        <Stepper
          activeStep={activeStep}
          className="my-3 border border-gray-500 rounded-md py-3 bg-slate-50"
        >
          {steps.map((label, index) => (
            <Step key={index}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>

        {activeStep === 0 && (
          <div>
            <VisulaizePdf survey_response_id={surveyResponseId} />
            <div className="mt-6 text-center">
              <Button
                variant="contained"
                color="primary"
                onClick={handleNext}
                style={{ textTransform: "none" }}
                className="bg-[#0976BC] hover:bg-[#065A94] text-white rounded-full px-4 py-2"
              >
                Suivant
              </Button>
            </div>
          </div>
        )}

        {activeStep === 1 && (
          <div>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  label="N° Convention"
                  name="nb_dyn"
                  value={formData.nb_dyn}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                  error={errors.nb_dyn}
                  helperText={
                    errors.nb_dyn ? "Veuillez remplir le N° de Convention " : ""
                  }
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  label="Montant subvention"
                  name="montant_subvention"
                  value={formData.montant_subvention}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                  error={errors.montant_subvention}
                  helperText={
                    errors.montant_subvention
                      ? "Veuillez remplir le Montant subvention "
                      : ""
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="N° identification national"
                  name="num_ident_national"
                  value={formData.num_ident_national}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                  error={errors.num_ident_national}
                  helperText={
                    errors.num_ident_national
                      ? "Veuillez remplir le N° d'identification national"
                      : ""
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Code PME"
                  name="cd_pme"
                  value={formData.cd_pme}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                  error={errors.cd_pme}
                  helperText={
                    errors.cd_pme ? "Veuillez remplir le code PME" : ""
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Code ville"
                  name="cd_ville"
                  value={formData.cd_ville}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                  error={errors.cd_ville}
                  helperText={
                    errors.cd_ville ? "Veuillez remplir le code ville" : ""
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Numéro d'impôt"
                  name="grant_amount"
                  value={formData.grant_amount}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                  error={errors.grant_amount}
                  helperText={
                    errors.grant_amount
                      ? "Veuillez remplir le numéro d'impôt"
                      : ""
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Date de disposition"
                  name="disposition_date"
                  type="date"
                  value={formData.disposition_date}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={errors.disposition_date}
                  helperText={
                    errors.disposition_date
                      ? "Veuillez remplir la date de disposition"
                      : ""
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Date de la lettre de notfication"
                  name="notification_date"
                  type="date"
                  value={formData.notification_date}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={errors.notification_date}
                  helperText={
                    errors.notification_date
                      ? "Veuillez remplir la date de notification"
                      : ""
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Adresse UCP"
                  name="ucp_address"
                  value={formData.ucp_address}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                  error={errors.ucp_address}
                  helperText={
                    errors.ucp_address ? "Veuillez remplir l'adresse UCP" : ""
                  }
                />
              </Grid>
            </Grid>

            <div className="mt-6 text-center">
              <Button
                variant="contained"
                color="primary"
                onClick={handleNext}
                style={{ textTransform: "none" }}
                className="bg-[#0976BC] hover:bg-[#065A94] text-white rounded-full px-4 py-2"
              >
                Suivant
              </Button>
            </div>
          </div>
        )}

        {activeStep === 2 && (
          <div>
            <VisulaizePdf
              survey_response_id={surveyResponseId}
              formData={formData}
            />
            <div className="mt-6 text-center">
              <Button
                variant="contained"
                color="primary"
                onClick={handleNext}
                style={{ textTransform: "none" }}
                className="bg-[#0976BC] hover:bg-[#065A94] text-white rounded-full px-4 py-2"
              >
                Suivant
              </Button>
            </div>
          </div>
        )}

        {activeStep === 3 && (
          <div className="text-center">
            <div className="flex justify-center mt-4 space-x-4">
              <div>
                <h4>Paraphe*</h4>
                <SignatureCanvas
                  ref={parapheCanvas}
                  penColor="black"
                  canvasProps={{
                    width: 200,
                    height: 150,
                    className: "border border-black",
                  }}
                  onEnd={checkParapheSigned}
                />
              </div>
              <div>
                <h4>Signature*</h4>
                <SignatureCanvas
                  ref={sigCanvas}
                  penColor="black"
                  canvasProps={{
                    width: 200,
                    height: 150,
                    className: "border border-black",
                  }}
                  onEnd={checkSignatureSigned}
                />
              </div>
            </div>

            <div className="mt-4">
              <Button
                onClick={clearSignature}
                variant="contained"
                color="secondary"
                style={{ textTransform: "none" }}
                className="bg-[#0976BC] hover:bg-[#065A94] text-white rounded-full px-4 py-2"
              >
                Effacer
              </Button>
              <Button
                variant="contained"
                onClick={handleNext}
                style={{
                  textTransform: "none",
                  backgroundColor:
                    stepLoading || !isParapheSigned || !isSignatureSigned
                      ? "#c0c0c0"
                      : "#0976BC",
                  color:
                    stepLoading || !isParapheSigned || !isSignatureSigned
                      ? "#808080"
                      : "white",
                }}
                className={`rounded-full px-4 py-2 ml-2 ${
                  stepLoading || !isParapheSigned || !isSignatureSigned
                    ? "cursor-not-allowed"
                    : "hover:bg-[#065A94] text-white"
                }`}
                disabled={
                  stepLoading || (!isParapheSigned && !isSignatureSigned)
                }
              >
                {stepLoading
                  ? "Sauvegarde..."
                  : "Sauvegarder la signature et continuer"}
              </Button>
            </div>
          </div>
        )}

        {activeStep === 4 && (
          <div>
            <VisulaizePdf
              survey_response_id={surveyResponseId}
              formData={formData}
              praphe={parapheDataURL}
              signature={signatureDataURL}
            />

            <div className="mt-6 text-center">
              {stepLoading ? (
                <div className="flex justify-center items-center">
                  <CircularProgress size={24} />
                </div>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                  disabled={isSubmitting || stepLoading}
                  style={{ textTransform: "none" }}
                  className="bg-[#0976BC] hover:bg-[#065A94] text-white rounded-full px-4 py-2"
                >
                  Confirmer et Soumettre
                </Button>
              )}
            </div>
          </div>
        )}

        {activeStep > 0 && (
          <div className="mt-6 text-center">
            <Button
              variant="text"
              color="secondary"
              onClick={handleBack}
              disabled={isSubmitting || stepLoading}
              style={{ textTransform: "none" }}
              className="bg-white hover:bg-[#065A94] hover:text-white border border-[#0976BC] text-[#0976BC] rounded-full px-4 py-2"
            >
              Retour
            </Button>
          </div>
        )}
      </div>
      <ToastContainer />
    </div>
  );
};

export default ConventionUploadModal;
