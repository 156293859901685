import bgPropos from "../../assets/images/Banner-about.jpg";

const Banner = () => {
  return (
    <div className="flex justify-center items-center w-full md:w-[80%] mx-auto flex-col h-[40vh] sm:h-[30vh] lg:h-[30vh] xl:h-[45vh] 2xl:h-[40vh] relative">
      <img
        src={bgPropos}
        className=" absolute inset-0 w-full h-full object-cover md:rounded-2xl"
        alt="bgPropos"
      />
      <div className="relative  text-white flex flex-col justify-center items-center gap-2">
        <h2 className=" xl:w-3/4 text-center font-roboto-slab text-4xl sm:text-5xl">
          À propos
        </h2>
        <p className="text-center w-[80%] md:w-[48%]">
          Bienvenue sur le site officiel du projet COPA TRANSFORME. Découvrez
          comment ce programme innovant contribue au renforcement de
          l'écosystème entrepreneurial en République Démocratique du Congo
          (RDC).
        </p>
      </div>
      <div>
        <input className="" />
      </div>
    </div>
  );
};

export default Banner;
