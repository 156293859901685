import React from 'react'

const Counter = () => {
  return (
    <>
      <h2 className="text-center mt-5 mb-4 text-2xl sm:text-3xl font-roboto-slab mx-auto md:px-[20%]">
        Pour accélérer votre croissance, faites confiance à Transforme 
      </h2>
      <div className="flex flex-col gap-4 sm:gap-2 sm:flex-row justify-around p-2 sm:px-[8%] mx-auto">
        <div className="flex flex-col justify-center align-items-center gap-1 ">
          <h3 className="text-[#0976BC] text-3xl mb-0 font-bold">800</h3>
          <span className="text-xs text-[#808080] text-center">
            PME accompagnées
            <br /> et subventionnées
          </span>
        </div>

        <div className="flex flex-col justify-center align-items-center gap-1 ">
          <h3 className="text-[#0976BC] text-3xl mb-0 font-bold">3050</h3>
          <span className="text-xs text-[#808080] text-center">
            Nouvelles entreprises <br />
            accompagnées et subventionnées
          </span>
        </div>

        <div className="flex flex-col justify-center align-items-center gap-1 ">
          <h3 className="text-[#0976BC] text-3xl mb-0 font-bold">60%</h3>
          <span className="text-xs text-[#808080] text-center">
            PME gérées par des femmes <br />
            parmi les bénéficiaires
          </span>
        </div>

        <div className="flex flex-col justify-center align-items-center gap-1 ">
          <h3 className="text-[#0976BC] text-3xl mb-0 font-bold">30%</h3>
          <span className="text-xs text-[#808080] text-center">
            d’investissements dans le
            <br />
            domaine de la résilience climatique
          </span>
        </div>
      </div>
    </>
  );
}

export default Counter