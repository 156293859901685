import React from "react";

const ResponsesEvaluateurs = ({ arrayOfActions }) => {
  const evaluateursFeedback = arrayOfActions?.filter(
    (item) => item?.isSystemEvaluation === false
  );

  return (
    <div>
      <div className="flex gap-2 flex-col">
        {evaluateursFeedback?.map((elem) =>
          elem?.evaluatorComment != null ? (
            <div className="flex flex-col border-2 border-[#E2F1FA] p-2 rounded-lg p-1">
              <h5>Évaluation :</h5>
              <p className="">
                <span className="font-semibold"> Status :</span>{" "}
                <span
                  className={` ${
                    elem?.status === "Rejeté"
                      ? "text-[#EE5D50]"
                      : "text-[#05CD99]"
                  } `}
                >
                  {elem?.status}
                </span>
              </p>
              <p>
                <span className="font-semibold">Commentaire : </span>
                {elem?.evaluatorComment}
              </p>
            </div>
          ) : (
            <></>
          )
        )}
      </div>
    </div>
  );
};

export default ResponsesEvaluateurs;
