import React, { useEffect, useState } from "react";
import { BsPlusSquare } from "react-icons/bs";
import { Link, Outlet, useNavigate } from "react-router-dom";
import {
  Button,
  Checkbox,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Drawer,
  Menu,
  MenuItem,
  IconButton,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControl,
  Select,
  DialogContent,
} from "@mui/material";
import {
  getAllActualites,
  deleteActualite,
  updatePinnedActualite,
} from "../../../services/actualites.service";
// import detailsButton from "../../../assets/viewDetailsButton.png";
// import actionButton from "../../../assets/actionButton.png";
import viewIcon from "../../../assets/viewIcon.png";
import closeSidebarDetailsIcon from "../../../assets/closeSidebarDetailsIcon.png";
import { MoreVert } from "@mui/icons-material";
import deleteIcon from "../../../assets/supprimerIcon.png";
import updateIcon from "../../../assets/updateIcon.png";
import { PushPin } from "@mui/icons-material";
import {
  ChevronDownIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/outline";
import { Bounce, toast, ToastContainer } from "react-toastify";
import image1 from "../../../assets/actualites1.png";
import deleteIconActualite from "../../../assets/deleteIconActualite.png";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import CheckCircleIcon from "../../../assets/check_circle.svg";
import CancelIcon from "../../../assets/cancel.svg";
import TimeProgressIcon from "../../../assets/lets-icons_time-progress-duotone.svg";

const ListeActualites = () => {
  const [actualites, setActualites] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [selectedActualite, setSelectedActualite] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedMenuActualite, setSelectedMenuActualite] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredActualites, setFilteredActualites] = useState([]);
  const [statusFilter, setStatusFilter] = useState("");

  const navigate = useNavigate();

  const toggleSideBar = (actualite) => {
    setSelectedActualite(actualite);
    setIsSidebarOpen(true);
  };

  const handleSidebarClose = () => {
    setIsSidebarOpen(false);
  };

  const handleMenuClick = (event, actualite) => {
    setAnchorEl(event.currentTarget);
    setSelectedMenuActualite(actualite);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleOpenDeleteDialog = () => {
    setOpenDialog(true);
    handleMenuClose();
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleStatusChange = (event) => {
    setStatusFilter(event.target.value);
  };

  const handleDelete = async () => {
    try {
      if (selectedMenuActualite) {
        await deleteActualite(selectedMenuActualite.id);
        setOpenDialog(false);

        fetchActualites();

        toast.success("L'actualité a été supprimée avec succès.", {
          position: "bottom-right",
          autoClose: 2000,
          theme: "colored",
          transition: Bounce,
        });
      }
    } catch (error) {
      toast.error("Erreur lors de la suppression de l'actualité.", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        transition: Bounce,
      });
    }
  };

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalDocs, setTotalDocs] = useState(0);

  useEffect(() => {
    fetchActualites();
  }, [page, rowsPerPage, statusFilter]);

  useEffect(() => {
    if (searchQuery) {
      const filtered = actualites.filter((actualite) =>
        actualite.title.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setFilteredActualites(filtered);
    } else {
      setFilteredActualites(actualites);
    }
  }, [searchQuery, actualites]);

  const fetchActualites = async () => {
    setLoading(true);
    try {
      const response = await getAllActualites(
        page + 1,
        rowsPerPage,
        statusFilter
      );

      if (response?.data?.articles) {
        setActualites(response.data.articles);
        setTotalDocs(response.data.totalDocs);
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("fr-FR", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  const handleEdit = () => {
    if (selectedMenuActualite) {
      navigate(
        `/unite_de_gestion-dashboard/actualite/update/${selectedMenuActualite.id}`
      );
    }
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleTogglePin = async (actualite) => {
    try {
      const updatedData = { isPinned: !actualite.isPinned };
      await updatePinnedActualite(actualite.id, updatedData);
      fetchActualites();
      toast.success("L'actualité a été épinglée avec succès.", {
        position: "bottom-right",
        autoClose: 2000,
        theme: "colored",
        transition: Bounce,
      });
    } catch (error) {
      toast.error(
        "Échec de la mise à jour du statut d'épinglage. Veuillez réessayer.",
        {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          transition: Bounce,
        }
      );
    }
  };

  const isVideo = (url) => {
    const videoFormats = [".mp4", ".webm", ".ogg"];
    return videoFormats.some((format) => url.endsWith(format));
  };

  const renderThumbs = (media) =>
    media.map((mediaItem, idx) =>
      isVideo(mediaItem?.url) ? (
        <video
          src={mediaItem?.url}
          muted
          className="w-full h-16 object-cover"
          key={idx}
        />
      ) : (
        <img
          src={mediaItem?.url || image1}
          alt="thumb"
          className="w-full h-16 object-cover"
          key={idx}
          onError={(e) => {
            e.target.src = image1;
          }}
        />
      )
    );

  const getStatusIcon = (status) => {
    switch (status) {
      case "publié":
        return <img src={CheckCircleIcon} alt="Publié" className="h-5 w-5" />;
      case "fermé":
        return <img src={CancelIcon} alt="Fermé" className="h-5 w-5" />;
      case "brouillon":
        return (
          <img src={TimeProgressIcon} alt="Brouillon" className="h-5 w-5" />
        );
      default:
        return null;
    }
  };

  return (
    <div className="mx-auto p-6 bg-card text-foreground  bg-slate-50 h-full">
      <h1 className="text-3xl font-bold mb-0">Liste des actualités</h1>
      <p className="text-gray-500 text-sm mb-2">
        Gérez et suivez en temps réel toutes les informations et mises à jour
        importantes
      </p>
      <div className="flex justify-end mb-2">
        <Link to="create-actualite" className="no-underline">
          <Button
            className="bg-[#0976BC] hover:bg-[#065A94] transition-colors duration-300 text-white px-4 py-2 rounded-md mb-0 flex items-center font-medium no-underline"
            startIcon={<BsPlusSquare />}
            style={{ textTransform: "none" }}
          >
            Créer une nouvelle actualité
          </Button>
        </Link>
      </div>

      <div className="border rounded-lg p-3 bg-white">
        <div className="flex justify-between items-center mb-3">
          <div className="relative w-1/4">
            <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
              <MagnifyingGlassIcon className="h-5 w-5 text-gray-500" />
            </div>
            <input
              type="search"
              id="default-search"
              className="py-[12px] ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg focus:outline-none w-full"
              placeholder="Rechercher par titre..."
              onChange={handleSearchChange}
            />
          </div>
          <div className="flex items-center space-x-4">
            <FormControl variant="outlined" sx={{ width: 160 }}>
              <Select
                value={statusFilter}
                onChange={handleStatusChange}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                IconComponent={() => (
                  <ChevronDownIcon className="w-7 h-7 text-gray mr-3" />
                )}
                sx={{
                  backgroundColor: "white",
                  color: "#333",
                  borderRadius: "4px",
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "1px solid #dee2e6",
                  },
                  "& .MuiSelect-select": {
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    borderRadius: "30px",
                    paddingRight: "2.5rem",
                  },
                  "& .MuiSelect-icon": {
                    display: "none",
                  },
                }}
              >
                <MenuItem value="">Tous les statuts</MenuItem>
                <MenuItem value="publié">Publié</MenuItem>
                <MenuItem value="brouillon">Brouillon</MenuItem>
                <MenuItem value="fermé">Fermé</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>

        {loading ? (
          <div className="flex justify-center">
            <CircularProgress />
          </div>
        ) : error ? (
          <p className="text-red-500 text-center">Erreur : {error}</p>
        ) : (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox />
                  </TableCell>
                  <TableCell>Titre</TableCell>
                  <TableCell>Date de publication</TableCell>
                  <TableCell>Catégorie</TableCell>
                  <TableCell>Statut</TableCell>
                  <TableCell>Épinglé</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredActualites?.map((actualite) => (
                  <TableRow key={actualite.id} hover>
                    <TableCell padding="checkbox">
                      <Checkbox />
                    </TableCell>
                    <TableCell>{actualite.title}</TableCell>
                    <TableCell>{formatDate(actualite.updated_at)}</TableCell>
                    <TableCell>{actualite.categories.join(", ")}</TableCell>
                    <TableCell>
                      <div className="flex items-center ">
                        {getStatusIcon(actualite.status)}
                        <span
                          className={
                            actualite?.status === "Publié"
                              ? ""
                              : actualite?.status === "Brouillon"
                              ? ""
                              : actualite?.status === "Fermé"
                              ? ""
                              : ""
                          }
                        >
                          {actualite?.status === "publié"
                            ? "Publié"
                            : actualite?.status === "brouillon"
                            ? "Brouillon"
                            : actualite?.status === "fermé"
                            ? "Fermé"
                            : ""}
                        </span>
                      </div>
                    </TableCell>
                    <TableCell>
                      <IconButton
                        onClick={() => handleTogglePin(actualite)}
                        style={{
                          color: actualite.isPinned ? "green" : "grey",padding:"0",
                        }}
                      >
                        <PushPin />
                      </IconButton>
                    </TableCell>
                    <TableCell>
                      <div className="flex gap-2">
                        <IconButton
                          style={{
                            backgroundColor: "#fff",
                            borderRadius: "4px",
                            border: "1px solid #0976BC",
                            height: "32px",
                            width: "32px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            padding: "4px",
                            margin: "0",
                            cursor: "pointer",
                            transition: "all 0.2s",
                          }}
                          onClick={() => toggleSideBar(actualite)}
                        >
                          <img
                            className="w-6 h-4"
                            src={viewIcon}
                            alt="View Details"
                          />
                        </IconButton>
                        <IconButton
                          aria-controls="simple-menu"
                          aria-haspopup="true"
                          onClick={(event) => handleMenuClick(event, actualite)}
                          style={{
                            backgroundColor: "#fff",
                            borderRadius: "4px",
                            border: "1px solid #0976BC",
                            height: "32px",
                            width: "32px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            padding: "8px 12px",
                            margin: "0",
                            cursor: "pointer",
                            transition: "all 0.2s",
                          }}
                        >
                          <MoreVert
                            style={{
                              color: "#0976BC",
                            }}
                          />
                        </IconButton>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>

            <TablePagination
              rowsPerPageOptions={[10, 25, 50]}
              component="div"
              count={totalDocs}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        )}

        {/* Drawer */}
        <Drawer
          anchor="right"
          open={isSidebarOpen}
          onClose={handleSidebarClose}
          PaperProps={{
            style: {
              width: "20%",
            },
          }}
        >
          <div className="p-6 bg-white h-full">
            <div className="flex justify-between mb-8 border-b-2">
              <div className="flex items-center gap-2">
                <img src={viewIcon} alt="view icon" className="h-5 w-7" />
                <h2 className="text-2xl font-bold text-[#0976BC] mb-0">
                  Détails
                </h2>
              </div>
              <Button onClick={handleSidebarClose}>
                <img
                  src={closeSidebarDetailsIcon}
                  alt="Close"
                  className="w-6 h-6"
                />
              </Button>
            </div>

            {selectedActualite ? (
              <>
                <table className="table-fixed w-full border rounded-md mb-6">
                  <tbody>
                    <tr className="rounded-t-md">
                      <td className="text-[#0976BC] font-semibold p-2 bg-[#f7fcff] border-b border-r w-[30%]">
                        Titre
                      </td>
                      <td className="p-3 border-b">
                        {selectedActualite?.title || "N/A"}
                      </td>
                    </tr>
                    <tr className="rounded-t-md">
                      <td className="text-[#0976BC] font-semibold p-2 bg-[#f7fcff] border-b border-r w-[30%]">
                        Description
                      </td>
                      <td className="p-3 border-b">
                        <div
                          className="text-sm lg:text-base text-gray-700 overflow-x-auto overflow-y-auto max-h-48 max-w-full"
                          style={{ whiteSpace: "nowrap" }}
                        >
                          <p
                            dangerouslySetInnerHTML={{
                              __html: selectedActualite?.description || "N/A",
                            }}
                          ></p>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className="text-[#0976BC] font-semibold p-2 bg-[#f7fcff] border-b border-r w-[30%]">
                        Catégorie
                      </td>
                      <td className="p-3 border-b">
                        {selectedActualite?.categories?.map(
                          (category, index) => (
                            <span key={index}>
                              {category}
                              <br />
                            </span>
                          )
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-[#0976BC] font-semibold p-2 bg-[#f7fcff] border-b border-r w-[30%]">
                        Date de publication
                      </td>
                      <td className="p-3 border-b">
                        {selectedActualite.updated_at
                          ? formatDate(selectedActualite?.updated_at)
                          : "N/A"}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-[#0976BC] font-semibold p-2 bg-[#f7fcff] border-r">
                        Statut
                      </td>
                      <td className="p-3">{selectedActualite?.status}</td>
                    </tr>
                  </tbody>
                </table>

                <div className="media-section mb-6">
                  <h3 className="text-lg font-bold text-[#0976BC] mb-3">
                    Médias
                  </h3>
                  <Carousel
                    useKeyboardArrows
                    showThumbs={true}
                    renderThumbs={() =>
                      renderThumbs(
                        selectedActualite.media?.length
                          ? selectedActualite.media
                          : [{ url: image1 }]
                      )
                    }
                  >
                    {selectedActualite.media?.length > 0 ? (
                      selectedActualite.media.map((mediaItem, idx) => (
                        <div key={idx} className="h-[300px]">
                          {isVideo(mediaItem.url) ? (
                            <video
                              src={mediaItem.url}
                              muted
                              autoPlay
                              loop
                              playsInline
                              className="w-full h-full object-cover"
                            />
                          ) : (
                            <img
                              src={mediaItem.url}
                              alt={`Media ${idx}`}
                              className="w-full h-full object-cover"
                            />
                          )}
                        </div>
                      ))
                    ) : (
                      <div className="h-[300px]">
                        <img
                          src={image1}
                          alt="Default Media"
                          className="w-full h-full object-cover"
                        />
                      </div>
                    )}
                  </Carousel>
                </div>
              </>
            ) : (
              <p className="text-gray-600">Aucune information disponible</p>
            )}
          </div>
        </Drawer>

        {/* Action Menu */}
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          <MenuItem onClick={handleOpenDeleteDialog}>
            <div className="gap-2 flex">
              <img className="h-5 w-5" src={deleteIcon} alt="supprimer" />
              <p className="mb-0"> Supprimer</p>
            </div>
          </MenuItem>
          <MenuItem onClick={handleEdit}>
            <div className="gap-2 flex">
              <img className="h-5 w-5" src={updateIcon} alt="update" />
              <p className="mb-0"> Modifier </p>
            </div>
          </MenuItem>
        </Menu>

        {/* Confirmation Dialog for Delete */}
        <Dialog
          open={openDialog}
          onClose={handleCloseDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <div className="flex justify-center mt-12">
            <img
              src={deleteIconActualite}
              width={80}
              height={80}
              alt="deleteIconActualite"
            />
          </div>
          <DialogTitle id="alert-dialog-title" className="text-center">
            {"Êtes-vous sûr de vouloir supprimer cet élément ?"}
          </DialogTitle>
          <DialogContent className="text-center">
            Cette action est irréversible. Une fois supprimé, cet élément ne
            pourra pas être restauré.
          </DialogContent>
          <DialogActions className="m-auto pb-8">
            <Button
              onClick={handleCloseDialog}
              variant="outlined"
              className="border-[#0976BC] text-[#0976BC] hover:bg-blue-100 rounded-full"
              style={{
                padding: "8px 24px",
                borderWidth: "2px",
              }}
            >
              Annuler
            </Button>
            <Button
              onClick={handleDelete}
              variant="contained"
              className="bg-[#0976BC] hover:bg-[#065A94] text-white rounded-full"
              autoFocus
              style={{
                padding: "8px 24px",
                marginLeft: "16px",
              }}
            >
              Supprimer
            </Button>
          </DialogActions>
        </Dialog>

        <Outlet />
      </div>
      <ToastContainer />
    </div>
  );
};

export default ListeActualites;
