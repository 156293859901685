import React, { Component } from "react";
import ErrorPage from "./components/common/ErrorPage";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, countdown: 5 }; 
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error("Error caught in ErrorBoundary:", error, errorInfo);
  }

  componentDidUpdate() {
    if (this.state.hasError && this.state.countdown === 3) {
      this.startCountdown();
    }
  }

  startCountdown = () => {
    const interval = setInterval(() => {
      this.setState(
        (prevState) => ({ countdown: prevState.countdown - 1 }),
        () => {
          if (this.state.countdown === 0) {
            clearInterval(interval); 
            window.location.reload(); 
          }
        }
      );
    }, 1000); 
  };

  render() {
    if (this.state.hasError) {
      return (
        <ErrorPage/>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
