import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Survey } from "survey-react-ui";
import { Model } from "survey-core";
import { PlainLight } from "survey-core/themes";
import { Bounce, toast, ToastContainer } from "react-toastify";
import { uploadFile } from "../../../../../services/uploadFile.service";
import { CircularProgress } from "@mui/material";
import { getSurveyResponseById } from "../../../../../services/surveyResponse.service";
import { uploadCadrageDocs } from "../../../../../services/cadrage.service";

const UploadDocumentCadrage = () => {
  const { formId } = useParams();
  const { id } = useParams();
  const [formData, setFormData] = useState(null);
  const [surveyModel, setSurveyModel] = useState(null);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchFormCadrage = async () => {
      try {
        if (!formId) {
          setError("Form ID is missing or invalid.");
          return;
        }

        const response = await getSurveyResponseById(id);
        console.log(response, "responseeee")
        const form = response?.surveyResponse?.documentCadrage;
        setFormData(form);

        if (form?.fields) {
          const parsedSurvey = JSON.parse(form.fields);
          const survey = new Model(parsedSurvey);
          survey.getAllQuestions().forEach((question) => {
            if (question.getType() === "file") {
              question.storeDataAsText = false;
              question.allowImagesPreview = true;
              question.waitForUpload = true;
              question.allowMultiple = true;
            }
          });
          survey.applyTheme(PlainLight);
          survey.locale = "fr";

          survey.onUploadFiles.add(async (_, options) => {
            const uploadedUrls = [];
            for (const file of options.files) {
              const urls = await uploadFile(
                file,
                "cadrage/" + formId,
                18000,
                "private"
              );

              uploadedUrls.push(
                Object.assign({}, file, {
                  name: urls[0].name,
                  fullUrl: urls[0].url,
                  type: file.type,
                  size: file.size,
                  lastModified: file.lastModified,
                  lastModifiedDate: file.lastModifiedDate,
                  webkitRelativePath: file.webkitRelativePath,
                })
              );
            }
            options.files = uploadedUrls;

            options.callback(
              options.files.map((file) => {
                return {
                  file: file,
                  content: file.fullUrl,
                };
              })
            );
          });

          survey.onComplete.add(async (sender) => {
            const surveyData = sender.data;
            try {
              await uploadCadrageDocs(id, {
                keycloakCoachId: response?.surveyResponse?.keycloakCoachId,
                cadrageDocsResponses: surveyData,
              });

              toast.success(
                "Vos documents cadrage ont été envoyées avec succès!",
                {
                  position: "bottom-right",
                  autoClose: 2000,
                  theme: "colored",
                  transition: Bounce,
                }
              );

              setTimeout(() => {
                navigate("/laureat-dashboard");
              }, 2000);
            } catch (error) {
              console.error("Error uploading survey data", error);
              toast.error(
                "Erreur lors du téléchargement des données d'enquête. Veuillez réessayer.",
                {
                  position: "bottom-right",
                  autoClose: 2000,
                  theme: "colored",
                  transition: Bounce,
                }
              );

              setTimeout(() => {
                navigate("/coach-dashboard");
              }, 2000);
            }
          });

          setSurveyModel(survey);
        } else {
          setError("No survey data found");
        }
      } catch (error) {
        setError("Error fetching form data");
        console.error(error);
      }
    };

    if (id) {
      fetchFormCadrage();
    }
  }, [id]);

  return (
    <div className="mx-auto p-6 bg-card text-foreground bg-slate-50 h-full">
      <h1 className="text-3xl font-bold mb-0">
        Importer les documents des cadrages
      </h1>
      <div className="border rounded-lg p-4 mt-3 bg-white ">
        <div className="space-y-3">
          {formData ? (
            <div>
              {surveyModel ? (
                <Survey model={surveyModel} />
              ) : (
                <div>
                  <CircularProgress />
                </div>
              )}
            </div>
          ) : (
            <div>
              <CircularProgress />
            </div>
          )}
          <ToastContainer />
        </div>
      </div>
      {/* <div className="mt-6 flex justify-between">
        <button
          onClick={() => navigate(-1)}
          className="bg-gray-200 text-gray-700 px-4 py-2 rounded-lg hover:bg-gray-300"
        >
          Retour à la page d'accueil
        </button>
      </div> */}
    </div>
  );
};

export default UploadDocumentCadrage;
