import React from "react";
import imageOne from "../../../assets/page1-1.jpg";
import imageTwo from "../../../assets/page1-2.jpg";

const Page1 = ({
  num_convention ,
  code_pme,
  code_ville,
  annee,
  entreprise,
  parapheCanvas,
  ParaphCoordinateur
}) => {
  return (
    <div
      style={{
        width: "210mm",
        minHeight: "297mm",
        margin: "0 auto",
        padding: "20px",
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        boxSizing: "border-box",
        position: "relative",
      }}
      className="p-4 bg-white text-black relative border border-blue-600"
    >
      {/* Background Borders */}
      <div className="absolute inset-0 border-4 border-blue-600">
        <div className="absolute inset-[4px] border-4 border-red-600"></div>
        <div className="absolute inset-[8px] border-4 border-yellow-500"></div>
      </div>

      {/* Main Content */}
      <div className="absolute inset-[40px] flex flex-col items-center justify-between mb-4">
        {/* Header Section */}
        <div className="text-center">
          <h2 className="font-bold text-lg">
            REPUBLIQUE DEMOCRATIQUE DU CONGO
          </h2>
          <h3 className="font-medium text-base mt-1">
            MINISTERE DE L’ENTREPRENEURIAT, PETITES ET MOYENNES ENTREPRISES
          </h3>
        </div>

        {/* Images Section */}
        <div className="flex items-center justify-center gap-4 mt-3">
          <img
            src={imageOne}
            alt="Logo 1"
            className="w-[320px] h-[100px] object-cover"
          />
          <img
            src={imageTwo}
            alt="Logo 2"
            className="w-[140px] h-[100px] object-cover"
          />
        </div>

        {/* Main Title */}
        <div className="text-center mt-3">
          <h3 className="font-bold text-lg">
            Projet d’Appui au Développement des Micro, Petites et Moyennes
            Entreprises « PADMPME »
          </h3>
          <h4 className="font-semibold text-sm mt-1">
            Financement : Crédit IDA N° 6287-ZR
          </h4>
        </div>

        {/* Subtitle */}
        <div className="text-center mt-3">
          <h3 className="font-bold text-base">
            Convention de Financement de Subvention des Bénéficiaires
          </h3>
          <p className="font-medium text-base mt-1">
            Concours de Plans d’Affaires (COPA)
          </p>
          <p className="font-medium text-base">
            Convention N°{num_convention}/UCP-PADMPME/BM/COPA/JK1({code_pme}
            )/01({code_ville})/({annee})
          </p>
        </div>

        {/* Section: Parties */}
        <div className="text-center mt-3">
          <p className="font-medium text-base">
            Passe entre
            <br />
            <span className="font-bold text-base">
              L’Unité de Coordination du Projet d’Appui au Développement des
              Micro Petites et Moyennes Entreprises « UCP-PADMPME »
            </span>
          </p>
          <p className="font-bold mt-3 text-base">ET</p>
          <p className="font-bold mt-3 text-base">
            L’entreprise {entreprise}, lauréate du COPA
          </p>
        </div>

        {/* Footer */}
        <div className="text-center mt-3">
          <h3 className="font-bold text-base uppercase">
            Convention de Financement à Coûts Partagés au Profit des PME
            Établies Jeunes Entrepreneurs:
          </h3>
          <p className="mt-3 text-base">
            Mise en œuvre du Plan d’Affaires de l’entreprise {entreprise}.
          </p>
        </div>
      </div>
      {parapheCanvas && (
        <div
          style={{
            position: "absolute",
            bottom: "10px",
            left: "10px",
          }}
        >
          <img
            src={parapheCanvas}
            alt="Aperçu de la signature"
            style={{
              width: "70px",
              height: "60px",
              marginLeft: "20px",

            }}
          />
        </div>
      )}
      {ParaphCoordinateur && (
        <div
          style={{
            position: "absolute",
            bottom: "10px",
            right: "10px",
            transform: "translateX(-50%)",
          }}
        >
          <img
            src={ParaphCoordinateur}
            alt="Aperçu de la signature"
            style={{
              width: "70px",
              height: "60px",
              marginLeft: "20px",

            }}
          />
        </div>
      )}
    </div>
  );
};

export default Page1;
