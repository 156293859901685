import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Banner from "./Banner";
import image1 from "../../../assets/actualites1.png";
import { getPublicActualiteById } from "../../../services/actualites.service";
import { CircularProgress } from "@mui/material";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./EditorStyles.css"; 
// import { Style } from "@mui/icons-material";


const DetailsActualite = () => {
  const { id } = useParams();
  const [actualite, setActualite] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchActualite = async () => {
      try {
        const data = await getPublicActualiteById(id);
        setActualite(data);
        setLoading(false);
      } catch (error) {
        setError("Erreur lors du chargement de l'actualité");
        setLoading(false);
      }
    };

    fetchActualite();
  }, [id]);

  if (loading) {
    return (
      <div>
        <CircularProgress />
      </div>
    );
  }

  if (error) {
    return <p>{error}</p>;
  }

  const isVideo = (url) => {
    const videoExtensions = [".mp4", ".webm", ".ogg"];
    return videoExtensions.some((ext) => url.endsWith(ext));
  };

  const renderThumbs = (mediaItems) =>
    mediaItems.map((mediaItem, idx) =>
      isVideo(mediaItem.url) ? (
        <video
          src={mediaItem.url}
          muted
          className="w-full h-16 object-cover"
          key={idx}
        />
      ) : (
        <img
          src={mediaItem.url || image1}
          alt="thumb"
          className="w-full h-16 object-cover"
          key={idx}
          onError={(e) => {
            e.target.src = image1; 
          }}
        />
      )
    );

  return (
    <div className="refactor-table">
      <Banner actualite={actualite} />
      <div className="container mt-10">
        <p className="text-gray-700">Accueil / Actualité / Détails</p>
        <div className="flex flex-col md:flex-row gap-4">
          <div className="w-full ">

            <div className="flex justify-center mt-3">
            <div className="w-5/6 md:w-2/3 bg-slate-50 rounded-lg border-[0.75px] border-[#e1e0e0] overflow-hidden">
               <Carousel
              useKeyboardArrows
              dynamicHeight={false}
              className="overflow-hidden"
              showThumbs={true}
              renderThumbs={() =>
                renderThumbs(
                  actualite.media?.length ? actualite.media : [{ url: image1 }]
                )
              }
            >
              {actualite.media?.length > 0 &&
              actualite.media.some((mediaItem) =>
                mediaItem.url.includes(
                  "news-articles"
                )
              ) ? (
                actualite.media.map((mediaItem, idx) => (
                  <div key={idx} className="h-[300px]">
                    {isVideo(mediaItem.url) ? (
                      <video
                        src={mediaItem.url}
                        muted
                        autoPlay
                        loop
                        playsInline
                        className="w-full h-full object-cover"
                      />
                    ) : (
                      <img
                        src={mediaItem.url}
                        alt="Actualite"
                        className="w-full h-full object-cover"
                      />
                    )}
                  </div>
                ))
              ) : (
                <div className="h-[300px]">
                  <img
                    src={image1}
                    alt="Default Actualite"
                    className="w-full h-full object-cover"
                  />
                </div>
              )}
            </Carousel>
            </div>
          </div>

            <h2 className="text-[#2F2F2F] font-semibold font-roboto-slab mt-4">
              {actualite?.title || "Titre indisponible"}
            </h2>
            <p
              dangerouslySetInnerHTML={{
                __html: actualite?.description || "Description indisponible",
              }}
            ></p>
          </div>
        </div>
      </div>

    
    </div>
  );
};

export default DetailsActualite;
