import axios from "axios";
import config from "../assets/environments/config.json";
import { refreshTokenService } from "../services/auth.service";

// Create Axios instance with the base URL
const axiosInstance = axios.create({
  baseURL: config.apiUrl,
});

// Request interceptor to add authorization token to requests
axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("access-token");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response interceptor to handle token expiration and refresh
axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    // Handle token expiration and refresh logic
    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      const refreshToken = localStorage.getItem("refresh-token");
      if (refreshToken) {
        try {
          const response = await refreshTokenService(refreshToken);
          const newAccessToken = response.data.access_token;

          localStorage.setItem("access-token", newAccessToken);
          originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;

          return axios(originalRequest);
        } catch (err) {
          console.error("Failed to refresh token:", err);
          logOut();
        }
      }
    }

    return Promise.reject(error);
  }
);

// Log out function to clear tokens and redirect to login
const logOut = () => {
  localStorage.removeItem("access-token");
  localStorage.removeItem("refresh-token");
  window.location.href = ""; 
};

export default axiosInstance;
