import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Checkbox,
} from "@mui/material";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { ToastContainer, toast } from "react-toastify"; // Import toast from react-toastify
import {
  desassignFormation,
  getUsersRetenue,
  assignFormation
} from "../../../../services/formation.service";
import assignCoachIcon from "../../../../assets/assignCoachIcon.png";
import { useParams } from "react-router-dom";

// Custom debounce hook
const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
};

const AssignFormation = () => {
  const [page, setPage] = useState(0);
  const [selected, setSelected] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");
  const debouncedSearchQuery = useDebounce(searchQuery, 500); 
  const [laureats, setLaureats] = useState([]);
  const [totalDocs, setTotalDocs] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const { id } = useParams();

  const fetchLaureats = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await getUsersRetenue(page + 1, rowsPerPage, debouncedSearchQuery);
      setLaureats(response?.data?.data || []);
      setTotalDocs(response.totalDocs || 0);
    } catch (err) {
      setError(err.message || "An error occurred while fetching laureats.");
    } finally {
      setLoading(false);
    }
  }, [page, rowsPerPage, debouncedSearchQuery]);

  useEffect(() => {
    fetchLaureats();
  }, [fetchLaureats]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleCheckboxChange = (event, laureatId) => {
    setSelected((prevSelected) =>
      event.target.checked
        ? [...prevSelected, laureatId]
        : prevSelected.filter((id) => id !== laureatId)
    );
  };

  const handleAssignClick = async () => {
    if (selected.length === 0) {
      toast.error("Please select at least one laureat.");
      return;
    }

    setLoading(true);
    try {
      if (!id) {
        toast.error("Formation ID is missing.");
        return;
      }

      await assignFormation({
        surveyResponseIds: selected,
        formationId: Number(id),
      });

      toast.success("Formation assignée avec succès !", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
      });
      
      setSelected([]);

      fetchLaureats();
    } catch (error) {
      toast.error("Error assigning formation: " + (error?.message || error));
    } finally {
      setLoading(false);
    }
  };

  const handleDeassignClick = async (laureatId, formationId) => {
    setLoading(true);
    setError(null);

    try {
      const formData = {
        surveyResponseIds: [laureatId],
        formationId: Number(formationId),
      };
      await desassignFormation(formData);

      toast.success("Formation désassignée avec succès!", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
      });
      fetchLaureats();
    } catch (err) {
      toast.error(
        "Erreur lors de la désassignation de la formation: " +
          (err.message || err),
        {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
        }
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="mx-auto p-6 bg-card text-foreground bg-slate-50 h-full">
      <h1 className="text-3xl font-bold mb-0">Liste des lauréats</h1>
      <p className="text-gray-500 text-sm mb-2">
        Track and manage training activities.
      </p>

      <div className="flex justify-end mb-4">
        <Button
          onClick={handleAssignClick}
          style={{
            textTransform: "none",
            backgroundColor: selected.length > 0 ? "#0976BC" : "#ccc",
            color: selected.length > 0 ? "white" : "#666",
            cursor: selected.length > 0 ? "pointer" : "not-allowed",
          }}
          disabled={selected.length === 0}
          className="px-4 py-2 gap-2 rounded-md mb-0 flex items-center font-medium"
        >
          <img
            src={assignCoachIcon}
            alt="assignCoachIcon"
            width={20}
            height={20}
          />
          Sélectionner
        </Button>
      </div>

      <div className="border rounded-lg p-3 bg-white">
        <div className="flex justify-between items-center mb-3">
          <div className="relative w-1/4">
            <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
              <MagnifyingGlassIcon className="h-5 w-5 text-gray-500" />
            </div>
            <input
              type="search"
              className="py-[12px] ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg focus:outline-none w-full"
              placeholder="Rechercher par titre..."
              onChange={handleSearchChange}
            />
          </div>
        </div>

        {loading ? (
          <div className="flex justify-center">
            <CircularProgress />
          </div>
        ) : error ? (
          <p className="text-red-500 text-center">Erreur : {error}</p>
        ) : (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox />
                  </TableCell>
                  <TableCell>Ref ID</TableCell>
                  <TableCell>Nom de laureat</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {laureats?.map((laureat) => (
                  <TableRow key={laureat.id}>
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={selected?.includes(laureat.id)}
                        onChange={(e) => handleCheckboxChange(e, laureat.id)}
                        disabled={laureat?.formations?.some(
                          (formation) => formation?.id === Number(id)
                        )}
                      />
                    </TableCell>
                    <TableCell>{laureat?.user?.id}</TableCell>
                    <TableCell>
                      {laureat?.user?.firstName} {laureat?.user?.lastName}
                    </TableCell>
                    <TableCell>{laureat.user?.email}</TableCell>
                    <TableCell>
                      {laureat?.formations?.some(
                        (formation) => formation?.id === Number(id)
                      ) && (
                        <Button
                          className="no-underline bg-[#0976BC] hover:bg-[#065A94] transition-colors duration-300 text-white px-2 py-1 rounded-full text-xs flex items-center justify-center"
                          onClick={() => handleDeassignClick(laureat.id, id)}
                        >
                          Désassigner
                        </Button>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>

            <TablePagination
              rowsPerPageOptions={[10, 25, 50]}
              component="div"
              count={totalDocs}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        )}
      </div>
      <ToastContainer />
    </div>
  );
};

export default AssignFormation;
