import React, { useEffect, useState } from "react";
import {
  Box,
  Paper,
  Grid,
  TextField,
  FormControl,
  Select,
  MenuItem,
  Button,
  CircularProgress,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { getUserProfile } from "../../../../services/user.service";
import { getVilles } from "../../../../services/ville.service";
import { updateProfileService } from "../../../../services/auth.service";

const UpdateUtilisateur = () => {
  const { user_id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [isLoadingVilles, setIsLoadingVilles] = useState(true);
  const [prenomError, setPrenomError] = useState(false);
  const [nomError, setNomError] = useState(false);
  const [villeError, setVilleError] = useState(false);

  const [formData, setFormData] = useState({
    nom: "",
    prenom: "",
    postnom: "",
    ville: "",
    selectedVilleId: "",
  });

  const [villesOptions, setVillesOptions] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [userProfileResponse, villesResponse] = await Promise.all([
          getUserProfile(user_id),
          getVilles(),
        ]);

        const userProfile = userProfileResponse;
        const villesData = villesResponse.data.map((ville) => ({
          value: ville.id,
          label: ville.name,
        }));

        setVillesOptions(villesData);

        // Find the initial ville ID based on the ville name in attributes
        const initialVille = villesData.find(
          (option) => option.label === userProfile.attributes?.ville[0]
        );

        setFormData({
          nom: userProfile.firstName || "",
          prenom: userProfile.lastName || "",
          postnom: userProfile.attributes?.postnom || "",
          ville: userProfile.attributes?.ville[0] || "", // Display name of the ville
          selectedVilleId: initialVille ? initialVille.value : "", // Set ID for selection
        });
      } catch (error) {
        console.error("Error fetching data:", error);
        toast.error("Erreur lors du chargement des données utilisateur.");
      } finally {
        setIsLoadingVilles(false);
      }
    };

    if (user_id) {
      fetchData();
    }
  }, [user_id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleEnregistrerClick = async () => {
    setPrenomError(false);
    setNomError(false);
    setVilleError(false);

    if (!formData.prenom) {
      setPrenomError(true);
      toast.error("Le champ Prénom est obligatoire", {
        position: "bottom-right",
        autoClose: 2000,
      });
      return;
    }

    if (!formData.nom) {
      setNomError(true);
      toast.error("Le champ Nom est obligatoire", {
        position: "bottom-right",
        autoClose: 2000,
      });
      return;
    }

    if (!formData.selectedVilleId) {
      setVilleError(true);
      toast.error("Le champ Ville est obligatoire", {
        position: "bottom-right",
        autoClose: 2000,
      });
      return;
    }

    const updatedData = {
      first_name: formData.prenom,
      last_name: formData.nom,
      post_name: formData.postnom || "",
      ville_id: formData.selectedVilleId,
    };

    try {
      setLoading(true);
      await updateProfileService(user_id, updatedData);
      toast.success("Utilisateur mis à jour avec succès", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
      });
      setTimeout(() => {
        navigate(-1);
      }, 2000);
    } catch (error) {
      console.error("Error updating profile", error);
      toast.error("Erreur lors de la mise à jour du profil", {
        position: "bottom-right",
        autoClose: 2000,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="mx-auto p-6 bg-card text-foreground mb-5 bg-slate-50 h-full">
      <h1 className="text-3xl font-bold mb-4">Modifier l'utilisateur</h1>
      <Box className="mx-auto text-foreground mb-5 bg-slate-50 h-full">
        <Paper elevation={3} className="border rounded-lg bg-white shadow-none">
          <Box className="p-6">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <Box className="mb-2">
                  <label className="block mb-1 text-sm font-medium">Nom</label>
                  <TextField
                    fullWidth
                    placeholder="Nom"
                    variant="outlined"
                    name="nom"
                    value={formData.nom}
                    onChange={handleChange}
                    error={nomError}
                    helperText={nomError ? "Le champ Nom est obligatoire" : ""}
                  />
                </Box>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Box className="mb-2">
                  <label className="block mb-1 text-sm font-medium">
                    Prénom
                  </label>
                  <TextField
                    fullWidth
                    placeholder="Prénom"
                    variant="outlined"
                    name="prenom"
                    value={formData.prenom}
                    onChange={handleChange}
                    error={prenomError}
                    helperText={
                      prenomError ? "Le champ Prénom est obligatoire" : ""
                    }
                  />
                </Box>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Box className="mb-2">
                  <label className="block mb-1 text-sm font-medium">
                    Post nom
                  </label>
                  <TextField
                    fullWidth
                    placeholder="Post nom"
                    variant="outlined"
                    name="postnom"
                    value={formData.postnom}
                    onChange={handleChange}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box className="mb-2">
                  <label className="block mb-1 text-sm font-medium">
                    Ville
                  </label>
                  <FormControl fullWidth error={villeError}>
                    <Select
                      className="h-[56px]"
                      name="ville"
                      value={formData.selectedVilleId} // Use the ID as the value
                      onChange={(e) => {
                        const selectedId = e.target.value;
                        const selectedVille = villesOptions.find(
                          (option) => option.value === selectedId
                        );
                        setFormData((prev) => ({
                          ...prev,
                          selectedVilleId: selectedId, // Set the ID for the payload
                          ville: selectedVille ? selectedVille.label : "", // Display name
                        }));
                      }}
                      renderValue={
                        () => formData.ville || "Choisir une ville" // Display city name or placeholder
                      }
                      disabled={isLoadingVilles}
                    >
                      {isLoadingVilles ? (
                        <MenuItem disabled>
                          <CircularProgress size={20} />
                          Chargement...
                        </MenuItem>
                      ) : (
                        villesOptions.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))
                      )}
                    </Select>
                    {villeError && (
                      <p className="text-red-600">
                        Le champ Ville est obligatoire
                      </p>
                    )}
                  </FormControl>
                </Box>
              </Grid>
            </Grid>
            <div className="d-flex justify-content-end mt-4 gap-4">
              <Button
                variant="outlined"
                onClick={() => {
                  navigate(-1);
                }}
                className="text-[#0976BC] border-[#0976BC] hover:bg-[#f0f0f0] transition-colors duration-300 shadow-none rounded-full px-4 py-2"
                style={{
                  textTransform: "none",
                  color: "#0976BC",
                  borderColor: "#0976BC",
                }}
              >
                Annuler
              </Button>

              <Button
                onClick={handleEnregistrerClick}
                variant="contained"
                color="primary"
                className="bg-[#0976BC] hover:bg-[#065A94] transition-colors duration-300 text-white shadow-none rounded-full px-4 py-2"
                style={{ textTransform: "none" }}
              >
                {loading ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  "Mettre à jour"
                )}
              </Button>
            </div>
          </Box>

          <ToastContainer />
        </Paper>
      </Box>
    </div>
  );
};

export default UpdateUtilisateur;
