import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { SurveyCreatorComponent, SurveyCreator } from "survey-creator-react";
import "survey-core/defaultV2.min.css";
import "survey-creator-core/survey-creator-core.min.css";
import "survey-creator-core/i18n/french";
import { StepContext } from "../../../../../contexts/StepContext";
import { StylesManager } from "survey-core";
import "survey-core/survey.i18n";
import { Serializer } from "survey-core";


// Import the theme you want to use
import { BorderlessLight } from "survey-core/themes";
import { Bounce, toast, ToastContainer } from "react-toastify";

const UDGSurveyCreator = () => {
    Serializer.addProperty("itemvalue", {
        name: "score:number",
        // default: 0,
        // title: "Score",
        // category: "general",
        // visible: true
    });

    const { setCurrentStep, setFormData } = useContext(StepContext);
    const navigate = useNavigate();

    const optionsDuCréateur = {
        showLogicTab: false,
        showTranslationTab: false,
        showJSONEditorTab: false,
        isAutoSave: true,
    };

    const créateur = new SurveyCreator(optionsDuCréateur);
    créateur.locale = "fr";


    useEffect(() => {
        StylesManager.applyTheme(BorderlessLight);
    }, []);

    const handleNext = () => {
        const surveyData = créateur.text;
        window.localStorage.setItem("json-sondage", surveyData);
            const title = JSON.parse(surveyData).title
            if(!title){
                toast.error('Le titre est requis. Veuillez spécifier un titre pour votre formulaire.', {
                    position: "bottom-right",
                    autoClose: 3000,
                    theme: "colored",
                    transition: Bounce,
                });
            }
            else {
                setFormData(prevData => ({
                    ...prevData,
                    surveyData,
                }));
                setCurrentStep(3);
                navigate("../");
            }
    };

    return (
        <div className="border rounded-lg p-3 bg-white">
            <div className="flex flex-col justify-between items-center mb-3">
                <div className="w-full h-full">
                    <SurveyCreatorComponent creator={créateur} />
                </div>
                <div className="flex space-x-4 mt-4 flex-row-reverse gap-8 w-full">
                    <button
                        className="bg-[#0976BC] text-white rounded-[37px] py-3 px-9"
                        onClick={handleNext}
                    >
                        Suivant
                    </button>
                    <button
                        className="bg-gray-500 text-white rounded-[37px] py-3 px-9"
                        onClick={() => navigate(-1)}
                    >
                        Annuler
                    </button>
                </div>
                <ToastContainer/>
            </div>
        </div>

    );
};

export default UDGSurveyCreator;
