import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";
import InputField from "../common/InputField";
import { Form, Formik } from "formik";
import * as Yup from 'yup';
import { Bounce, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { useAuth } from "../../contexts/AuthContext";
import logo from "../../assets/logo.png"

const LoginForm = () => {
  const { loginAction } = useAuth();
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const initialValues = {
    email: '',
    password: '',
  };

  const validationSchema = Yup.object({
    email: Yup.string().email("Adresse e-mail invalide").required("Adresse e-mail est requise"),
    password: Yup.string().required('Mot de passe est requis'),
  });

  const onSubmit = async (values) => {
    try {
      const role = await loginAction(values);

      if (role === "laureat") {
        const programId = localStorage.getItem("programId");
        if (programId) {
          navigate(`/laureat-dashboard/fill-form`);
        } else {
          navigate(`/laureat-dashboard`);
        }
      } else if (role) {
        navigate(`/${role}-dashboard`);
      }
    } catch (error) {
      console.log(error);
      // toast.error('Login failed. Please try again.', {
      //   position: "bottom-right",
      //   autoClose: 2000,
      //   theme: "colored",
      //   transition: Bounce,
      // });
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
      <div className="flex flex-col items-center mb-4">
        <Link
          to={`/`}
          className=" flex justify-center "
        >
          <img
            src={logo}
            alt="Logo"
            className="w-50"
          />
        </Link>
      </div>
      <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-xl">
        <div className="flex flex-col items-center mb-6">
          <h1 className="text-4xl font-bold text-secondary-color">Bienvenue</h1>
          <p className="text-center text-[#3D3D3D] text-sm mt-2">
            Entrez vos identifiants pour vous connecter à la plateforme.
          </p>
        </div>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
          validateOnBlur
        >
          <Form className="flex flex-col gap-3">
            <InputField
              type="email"
              placeholder="Entrer votre e-mail"
              name="email"
              label="Adresse e-mail"
            />
            <div className="relative mb-6">
              <InputField
                type={showPassword ? "text" : "password"}
                name="password"
                label="Mot de passe"
                placeholder="Entrer votre mot de passe *"
              />
              <button
                type="button"
                onClick={togglePasswordVisibility}
                className=" inset-y-0 right-0 flex items-center px-3 cursor-pointer mt-[24px] absolute"
              >
                {showPassword ? (
                  <EyeSlashIcon className="h-5 w-5 text-gray-500" />
                ) : (
                  <EyeIcon className="h-5 w-5 text-gray-500" />
                )}
                <span className="ml-2 text-sm text-[#666666]">
                  {showPassword ? "Masquer" : "Afficher"}
                </span>
              </button>
            </div>
            <ToastContainer
              position="bottom-right"
              autoClose={2000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="colored"
              transition={Bounce}
            />
            <div>
              <button
                className="w-full bg-secondary-color hover:bg-[#065A94] text-white font-bold py-2 px-4 rounded-full focus:outline-none focus:shadow-outline"
                type="submit"
              >
                Se connecter
              </button>
            </div>
          </Form>
        </Formik>
        <div className="mt-6 text-center">
          <p className="text-gray-600 text-sm">
            Vous n'avez pas de compte?{" "}
            <Link
              to="/auth/register"
              className="text-secondary-color hover:text-[#065A94] font-semibold"
            >
              Inscrivez-vous
            </Link>
            <br />
            <span className="text-gray-600 text-sm mt-2 block">
              Vous avez oublié votre mot de passe?{" "}
              <Link
                to="/auth/forget-password"
                className="text-secondary-color hover:text-[#065A94] font-semibold"
              >
                Réinitialisez-le ici
              </Link>
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default LoginForm;
