import React from "react";

const Page7 = ({ parapheCanvas, ParaphCoordinateur }) => {
  return (
    <div
      className="p-8 bg-white text-black"
      style={{
        width: "210mm",
        minHeight: "300mm", // Increased height
        margin: "0 auto",
        padding: "10px", // Reduced margin
        textAlign: "justify",
        boxSizing: "border-box",
        position: "relative",
      }}
    >
      <p className="mb-1 leading-[1.3]">
        Toute situation de force majeure doit immédiatement et formellement être
        notifiée à l’autre partie, en précisant la nature, la durée probable et
        les effets prévisibles.
      </p>

      <p className="mb-1 leading-[1.3]">
        Les parties doivent immédiatement prendre toutes les mesures nécessaires
        pour limiter tout dommage, en cas de force majeure et faire de leur
        mieux pour limiter les dommages et pour reprendre la mise en œuvre du
        projet le plus tôt possible.
      </p>

      <p className="mb-1 leading-[1.3]">
        La force majeure est comprise comme étant un événement imprévisible et
        irrésistible qui, provenant d’une cause extérieure au débiteur d’une
        obligation ou à l’auteur d’un dommage, le libère de son obligation ou
        l’exonère de sa responsabilité.
      </p>

      <h4 className="text-md font-bold mb-1">Article 12 : Modification</h4>
      <p className="mb-1 leading-[1.3]">
        Toute modification de la présente convention, y compris de ses annexes,
        doit être effectuée et constatée par écrit et ce, pendant la période de
        son exécution. Elle sera annexée sous forme d’avenant à la présente
        convention avec laquelle elle formera un tout.
      </p>

      <p className="mb-1 leading-[1.3]">
        Aucun avenant ne peut avoir pour objet ou pour effet d’apporter à la
        présente convention des modifications susceptibles de remettre en cause
        la décision d’attribution de la subvention, ni violer l’égalité de
        traitement entre les Bénéficiaires ou lauréats du Concours des Plans
        d’Affaires.
      </p>

      <p className="mb-1 leading-[1.3]">
        Tout avenant ne pourra porter que sur le montant, la durée de la
        convention, le partenariat, les activités, les réalisations, les
        résultats ou les indicateurs de résultats.
      </p>

      <h4 className="text-md font-bold mb-1">Article 13 : Résiliation</h4>
      <p className="mb-1 leading-[1.3]">
        La présente convention prend fin de plein droit dans les cas ci-après :
      </p>
      <div className="mb-1 ml-6 ">
        <p className="before:content-['1.'] before:font-bold before:mr-2 mb-1">
          A la fin de la convention conformément aux dispositions de l’article
          3 ;
        </p>
        <p className="before:content-['2.'] before:font-bold before:mr-2 mb-1">
          Au terme du contrat conclu entre le Consortium et l’UCP-PADMPME ;
        </p>
        <p className="before:content-['3.'] before:font-bold before:mr-2 mb-1">
          A l’échéance finale du Projet.
        </p>
      </div>

      <p className="mb-1 leading-[1.3]">
        Nonobstant les cas prévus ci-dessus, la présente convention peut être
        résiliée si, sans justification aucune, le Bénéficiaire commet un
        manquement grave aux obligations substantielles qui lui incombent.
      </p>

      <p className="mb-1 leading-[1.3]">
        Les circonstances suivantes constituent un manquement grave rendant
        impossible le maintien de la relation contractuelle, notamment :
      </p>
      <div className="mb-1 ml-6 space-y-3">
        <p className="before:content-['•'] before:mr-2">
          Lorsque le Bénéficiaire ne fournit pas les rapports prévus par les
          dispositions de l’article 9 de la présente convention ;
        </p>
        <p className="before:content-['•'] before:mr-2">
          Si le Bénéficiaire fait l'objet des poursuites de nature à
          compromettre sa fortune ou sa réputation ;
        </p>
        <p className="before:content-['•'] before:mr-2">
          Si le Bénéficiaire n'affecte pas la subvention au financement des
          activités prévues dans le plan d’affaires convenu et validé ;
        </p>
        <p className="before:content-['•'] before:mr-2">
          Lorsque le Bénéficiaire n’utilise pas la subvention et que le montant
          y relatif reste inutilisé pendant plus de six mois dans son compte
          dédié ;
        </p>
        <p className="before:content-['•'] before:mr-2">
          En cas de détournement de la contrepartie ;
        </p>
        <p className="before:content-['•'] before:mr-2">
          En cas de cession à un tiers des droits et avantages résultant de la
          présente convention, sans l’accord préalable et formel du Consortium
          et de l’UCP-PADMPME ;
        </p>
        <p className="before:content-['•'] before:mr-2">
          Si le principal et/ou intérêt mentionné(s) dans le plan de paiement
          (remboursement) de la contrepartie n’est (ne sont) pas versé(s)
          intégralement et aux dates indiquées.
        </p>
      </div>

      <div
        style={{
          position: "absolute",
          bottom: "10px",
          left: "50%",
          transform: "translateX(-50%)",
        }}
      >
        <p className="font-semibold">Page 7 sur 9</p>
      </div>
      {parapheCanvas && (
        <div
          style={{
            position: "absolute",
            bottom: "10px",
            left: "10px",
          }}
        >
          <img
            src={parapheCanvas}
            alt="Aperçu de la signature"
            style={{
              width: "70px",
              height: "60px",
              marginLeft: "20px",
              marginBottom: "-10px",
            }}
          />
        </div>
      )}
      {ParaphCoordinateur && (
        <div
          style={{
            position: "absolute",
            bottom: "10px",
            right: "10px",
            transform: "translateX(-50%)",
          }}
        >
          <img
            src={ParaphCoordinateur}
            alt="Aperçu de la signature"
            style={{
              width: "70px",
              height: "60px",
              marginLeft: "20px",
            }}
          />
        </div>
      )}
    </div>
  );
};

export default Page7;
