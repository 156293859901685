import axiosInstance from "../helpers/axiosIntercepter";

export const getVilles = async (name = "", page = 1, limit = 100) => {
  try {
    const response = await axiosInstance.get("/villes", {
      params: { name, page, limit },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching villes:", error);
    throw new Error("Failed to fetch villes. Please try again later.");
  }
};
