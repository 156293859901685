import React, { useState, useEffect } from "react";
import { BsFilter, BsPlusSquare } from "react-icons/bs";
import { Link, Outlet, useLocation } from "react-router-dom";
import { getAllPrograms } from "../../../services/program.service";
import CheckCircleIcon from '../../../assets/check_circle.svg';
import CancelIcon from '../../../assets/cancel.svg';
import TimeProgressIcon from '../../../assets/lets-icons_time-progress-duotone.svg';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
  Paper,
  Button,
  TablePagination,
  Select,
  MenuItem,
  FormControl,
  InputBase,
  CircularProgress,
} from "@mui/material";
import { CalendarIcon, MagnifyingGlassIcon } from "@heroicons/react/24/outline";

const ListeDesAppelsACandidatures = () => {
  const [programs, setPrograms] = useState([]);
  const [filteredPrograms, setFilteredPrograms] = useState([]);
  const [selectedPrograms, setSelectedPrograms] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const [filter, setFilter] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(false);

  const location = useLocation();
  const isCreatingProgramme = location.pathname.includes("create-programme");

  const fetchPrograms = async (currentPage, pageSize) => {
    setLoading(true);
    try {
      const data = await getAllPrograms(currentPage, pageSize);
      setPrograms(data?.data || []);
      setFilteredPrograms(data?.data || []);
      setTotalItems(data?.totalDocs || 0);
    } catch (error) {
      console.error("Error fetching programs:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPrograms(page + 1, rowsPerPage);
  }, [page, rowsPerPage, refresh]);
  const triggerRefresh = () => {
    setRefresh((prevState) => !prevState);
  };
  const handleSearchInputChange = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchQuery(value);

    const filtered = programs.filter((program) =>
      program.title.toLowerCase().includes(value) &&
      (filter ? program.status.toLowerCase() === filter.toLowerCase() : true)
    );
    setFilteredPrograms(filtered);
  };

  const handleFilterChange = (status) => {
    setFilter(status);

    const filtered = programs.filter((program) =>
      program.title.toLowerCase().includes(searchQuery) &&
      (status ? program.status.toLowerCase() === status.toLowerCase() : true)
    );
    setFilteredPrograms(filtered);
  };

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      setSelectedPrograms(programs?.map((program) => program.id));
    } else {
      setSelectedPrograms([]);
    }
  };

  const handleSelectProgram = (id) => {
    if (selectedPrograms.includes(id)) {
      setSelectedPrograms(
        selectedPrograms.filter((programId) => programId !== id)
      );
    } else {
      setSelectedPrograms([...selectedPrograms, id]);
    }
  };

  const isSelected = (id) => selectedPrograms.includes(id);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getStatusIcon = (status) => {
    switch (status) {
      case "Publié":
        return <img src={CheckCircleIcon} alt="Publié" className="h-5 w-5" />;
      case "Fermé":
        return <img src={CancelIcon} alt="Fermé" className="h-5 w-5" />;
      case "Brouillon":
        return <img src={TimeProgressIcon} alt="Brouillon" className="h-5 w-5" />;
      default:
        return null;
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("fr-FR", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  if (isCreatingProgramme) {
    return <Outlet context={triggerRefresh} />;
  }

  return (
    <div className="mx-auto p-6 text-foreground  bg-slate-50 h-full">
      <h2 className="text-3xl font-bold mb-0">
        Liste des appels à candidatures
      </h2>
      <p className="text-gray-500 text-sm mb-2">
        Suivez et gérez les informations et les activités des clients.
      </p>
      <div className="flex justify-end mb-2">
        <Link to="create-programme" className="no-underline">
          <Button
            className="bg-[#0976BC] hover:bg-[#065A94] transition-colors duration-300 text-white px-4 py-2 rounded-md mb-0 flex items-center font-medium"
            startIcon={<BsPlusSquare />}
            style={{ textTransform: "none" }}
          >
            Créer un nouvel appel à candidature
          </Button>
        </Link>
      </div>
      <Paper className="border rounded-lg p-3 bg-white mt-0">
        <div className="flex justify-between items-center mb-3">
          <div className="relative w-1/4">
            <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
              <MagnifyingGlassIcon className="h-5 w-5 text-gray-500" />
            </div>
            <InputBase
              placeholder="Rechercher par titre,..."
              className="py-[12px] ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg w-full"
              value={searchQuery}
              onChange={handleSearchInputChange}
            />
          </div>

          <div className="flex items-center space-x-4">
            {/* <Button style={{ textTransform: "none" }} className="flex items-center text-gray-600 px-4 py-2 border border-gray-300 rounded-md hover:bg-transparent hover:border-gray-400 hover:bg-slate-50">
              <BsCalendar className="mr-2" />
              Date
            </Button> */}
            <FormControl className="relative">
              <div className="flex items-center relative">
                <BsFilter className="absolute left-3 text-gray-500" />
                <Select
                  value={filter}
                  onChange={(e) => handleFilterChange(e.target.value)}
                  className="pl-10 pr-5 py-[0px] text-base text-gray-900  rounded-md focus:outline-none focus:ring-0 "
                  displayEmpty
                // IconComponent={() => (
                //   <ChevronDownIcon className="h-6 w-6 text-gray-500 ml-2" />
                // )}
                // inputProps={{
                //   "aria-label": "Filter",
                //   style: {
                //     height: "42px",
                //     paddingTop: "0",
                //     paddingBottom: "0",
                //   },
                // }}
                // MenuProps={{
                //   PaperProps: {
                //     style: {
                //       marginTop: "8px",
                //       boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                //     },
                //   },
                // }}
                >
                  <MenuItem value="">Tous les statuts</MenuItem>
                  <MenuItem value="Brouillon">Brouillon</MenuItem>
                  <MenuItem value="Publié">Publié</MenuItem>
                  <MenuItem value="Fermé">Fermé</MenuItem>
                </Select>
              </div>
            </FormControl>

            {/* <Button style={{ textTransform: "none" }} className="flex items-center text-gray-600 px-4 py-2 border border-gray-300 rounded-md hover:bg-transparent hover:border-gray-400 hover:bg-slate-50 hover:shadow-sm">
              <BsFileEarmarkArrowDown className="mr-2" />
              Exporter
            </Button> */}
          </div>
        </div>

        <TableContainer component={Paper} className="shadow-md sm:rounded-lg">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    onChange={handleSelectAll}
                    checked={selectedPrograms?.length === programs?.length}
                  />
                </TableCell>
                <TableCell>Titre de l'appel à candidature</TableCell>
                <TableCell>Statut</TableCell>
                <TableCell>Nb acceptées</TableCell>
                <TableCell>Nb de candidatures</TableCell>
                <TableCell>Nb rejetées</TableCell>
                <TableCell>Date de création</TableCell>
                <TableCell>Date de clôture</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableRow>
                  <TableCell colSpan={9} align="center">
                    <CircularProgress />{" "}
                  </TableCell>
                </TableRow>
              ) : (
                filteredPrograms?.map((program) => (
                  <TableRow
                    hover
                    key={program.id}
                    selected={isSelected(program.id)}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={isSelected(program.id)}
                        onChange={() => handleSelectProgram(program.id)}
                      />
                    </TableCell>
                    <TableCell sx={{ wordBreak: 'break-word', whiteSpace: 'normal', maxWidth: 200 }}>{program.title}</TableCell>
                    <TableCell>
                      <div className="flex items-center ">
                        {getStatusIcon(program.status)}
                        <span
                          className={
                            program.status === "Publié"
                              ? ""
                              : program.status === "Brouillon"
                                ? ""
                                : program.status === "Fermé"
                                  ? ""
                                  : ""
                          }
                        >
                          {program.status}
                        </span>
                      </div>
                    </TableCell>

                    <TableCell>{program.nbAccepted}</TableCell>
                    <TableCell>{program.nbCandidatures}</TableCell>
                    <TableCell>{program.nbRejected}</TableCell>
                    <TableCell>
                      <div className="flex items-center space-x-2">
                        <span className="p-1 rounded-full bg-gray-200">
                          <CalendarIcon className="h-5 w-5 text-gray-600" />
                        </span>
                        {formatDate(program.createdAt)}
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className="flex items-center space-x-2">
                        <span className="p-1 rounded-full bg-gray-200">
                          <CalendarIcon className="h-5 w-5 text-gray-600" />
                        </span>
                        {formatDate(program.expiresIn)}
                      </div>
                    </TableCell>
                    <TableCell>
                      <Link
                        to={`/unite_de_gestion-dashboard/Appels-à-candidatures/${program.id}`}
                        className="no-underline bg-[#0976BC] hover:bg-[#065A94] transition-colors duration-300 text-white px-2 py-1 rounded-full text-xs flex items-center justify-center"
                        style={{ minWidth: '50px', whiteSpace: 'nowrap' }} 
                      >
                        Voir détails
                      </Link>
                    </TableCell>

                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={totalItems}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
};

export default ListeDesAppelsACandidatures;
