import React from "react";

const Page5 = ({ parapheCanvas, ParaphCoordinateur }) => {
  return (
    <div
      className="p-8 bg-white text-black"
      style={{
        width: "210mm",
        minHeight: "300mm", // Increased height
        margin: "0 auto",
        padding: "10px", // Reduced margin
        textAlign: "justify",
        boxSizing: "border-box",
        position: "relative",
      }}
    >
      <p className="mb-2 leading-[1.4]">
        qui seraient causés au personnel ou à ses biens et à ceux des
        partenaires, lors de la mise en œuvre du projet.
      </p>

      <p className="mb-2 leading-[1.4]">
        Les parties dégagent l’UCP-PADMPME de toute responsabilité liée à
        d’éventuelles réclamations ou poursuites résultant d’une infraction à
        des lois et/ou règlements commise par les employés du Bénéficiaire ou
        par les personnes à sa charge ou d’une violation des droits des tiers.
      </p>

      <p className="mb-2 leading-[1.4]">
        En conséquence de ce qui précède, aucune demande d’indemnité ne sera
        admise pour ces motifs.
      </p>

      <p className="mb-2 leading-[1.4]">
        Pour la bonne exécution de la convention et le succès du projet, les
        parties se réfèrent aux lignes maitresses telles qu’édictées dans le
        manuel d’exécution de la COPA et dans le manuel de suivi-évaluation
        (disponibles pour consultation auprès de l’UCP-PADMPME).
      </p>

      <h4 className="text-md font-bold mb-3">
        Article 8 : Rôles et Responsabilités
      </h4>

      <p className="mb-2 leading-[1.4] font-semibold">
        Le Bénéficiaire du Projet doit :
      </p>
      <div className="space-y-3 text-[15px] leading-5">
        <p className="before:content-['•'] before:text-black before:mr-2">
          Permettre au Consortium, à l'équipe de l’UCP-PADMPME et aux
          fournisseurs de services autorisés ou à leurs représentants d'accéder
          en temps opportun aux sites du projet (siège social et/ou
          d’exploitation) du Bénéficiaire et aux dossiers pertinents aux fins du
          suivi et de l'évaluation ;
        </p>
        <p className="before:content-['•'] before:text-black before:mr-2">
          Informer le Consortium de toute circonstance susceptible d’affecter ou
          de retarder la mise en œuvre du projet ;
        </p>
        <p className="before:content-['•'] before:text-black before:mr-2">
          Informer le Consortium de tout changement juridique, financier,
          technique, d’organisation ou de contrôle des partenaires, ainsi que de
          toute modification de la dénomination, du siège ou du gérant de
          l’entreprise ;
        </p>
        <p className="before:content-['•'] before:text-black before:mr-2">
          Informer le Consortium de tout changement d’adresse et ce, dans les 24
          heures dudit changement ;
        </p>
        <p className="before:content-['•'] before:text-black before:mr-2">
          Fournir, dans le cadre des audits, contrôles ou suivi-évaluations,
          tous documents nécessaires, notamment les pièces justificatives les
          plus importantes et les exemplaires signés de tout contrat conclu dans
          le cadre de la mise en œuvre du projet ;
        </p>
        <p className="before:content-['•'] before:text-black before:mr-2">
          Apporter la contrepartie financière prévue par les dispositions de
          l’article 5 de la présente convention ;
        </p>
        <p className="before:content-['•'] before:text-black before:mr-2">
          Présenter la facture pro forma des fournisseurs et les demandes de
          paiement s’y rapportant ;
        </p>
        <p className="before:content-['•'] before:text-black before:mr-2">
          Rendre compte au Consortium, sur une base mensuelle, des activités
          menées en conformité avec le plan de travail défini, par la
          transmission d'un rapport d'activités écrit ;
        </p>
        <p className="before:content-['•'] before:text-black before:mr-2">
          Ouvrir un sous-compte dédié dans lequel sera domiciliée la subvention
          auprès d’une banque agréée par l’UCP-PADMPME ;
        </p>
        <p className="before:content-['•'] before:text-black before:mr-2">
          Autoriser l’UCP-PADMPME à obtenir, directement auprès d’une banque
          agréée par l’UCP-PADMPME, les informations financières (relevé de
          compte) du sous-compte dédié dans lequel sera domiciliée la
          subvention ;
        </p>
        <p className="before:content-['•'] before:text-black before:mr-2">
          Fournir les pièces justificatives et documents probants (notamment les
          rapports financiers) nécessaires permettant d’attester l’usage des
          fonds obtenus à titre de financement.
        </p>
      </div>

      <p className="mt-3 leading-[1.4] font-semibold">
        Le Consortium a l’obligation de s’assurer :
      </p>
      <div className=" space-y-3">
        <p className="before:content-['•'] before:text-black before:mr-2">
          Que la subvention est mise à la disposition du Bénéficiaire et ce,
          dans le compte dédié COPA ouvert par ce dernier dans une institution
          financière (établissement bancaire, institution de microfinance ou
          autre).
        </p>
      </div>

      <div
        style={{
          position: "absolute",
          bottom: "10px",
          left: "50%",
          transform: "translateX(-50%)",
        }}
      >
        <p className="font-semibold">Page 5 sur 9</p>
      </div>
      {parapheCanvas && (
        <div
          style={{
            position: "absolute",
            bottom: "10px",
            left: "10px",
          }}
        >
          <img
            src={parapheCanvas}
            alt="Aperçu de la signature"
            style={{
              width: "70px",
              height: "60px",
              marginLeft: "20px",
              marginBottom: "-10px",
            }}
          />
        </div>
      )}
      {ParaphCoordinateur && (
        <div
          style={{
            position: "absolute",
            bottom: "10px",
            right: "10px",
            transform: "translateX(-50%)",
          }}
        >
          <img
            src={ParaphCoordinateur}
            alt="Aperçu de la signature"
            style={{
              width: "70px",
              height: "60px",
              marginLeft: "20px",
            }}
          />
        </div>
      )}
    </div>
  );
};

export default Page5;
