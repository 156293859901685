import React, { useEffect, useState } from 'react'
import { Model } from "survey-core";
import { Survey } from "survey-react-ui";
import "survey-core/defaultV2.min.css";
import "survey-core/i18n/french";
import { PlainLight } from "survey-core/themes";
import { useNavigate, useParams } from "react-router-dom";
import { getSurveyResponseById } from "../../../../services/surveyResponse.service"
import { CircularProgress } from '@mui/material';
import axios from 'axios';
import axiosInstance from '../../../../helpers/axiosIntercepter';

const DetailsFormulaireJuridique = () => {

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(false);
  const [surveyModel, setSurveyModel] = useState(null);
  const [surveyModel2, setSurveyModel2] = useState(null);
  const { id } = useParams();
  const navigate = useNavigate()

  const isUrlExpired = (url) => {
    const urlParams = new URLSearchParams(url.split('?')[1]);

    const amzDate = urlParams.get('X-Amz-Date');
    const amzExpires = urlParams.get('X-Amz-Expires');

    if (amzDate && amzExpires) {

      const expirationDate = new Date(
        amzDate.slice(0, 4) + '-' + // Year
        amzDate.slice(4, 6) + '-' + // Month
        amzDate.slice(6, 8) + 'T' + // Day
        amzDate.slice(9, 11) + ':' + // Hour
        amzDate.slice(11, 13) + ':' + // Minute
        amzDate.slice(13, 15) + 'Z' // Second
      );

      const expiresInMs = parseInt(amzExpires, 10) * 1000;
      const expirationTime = expirationDate.getTime() + expiresInMs;

      return Date.now() > expirationTime;
    }

    return false; 
  }

  const fetchUpdatedUrl = async (fileName) => {

    try {
      const response = await axiosInstance.get(`/upload/url?fileName=${fileName}&strategy=minio&visibility=private&expirationTime=180`);
      return response.data;

    } catch (error) {
      console.log("error in my update expired url", error)
    }
  };

  const updatedFilesDate = async (questions) => {

    const updatedObject = { ...questions };

    for (const [key, value] of Object.entries(updatedObject)) {

      if (Array.isArray(value) && value[0]?.content) {
        const file = value[0];
        const isExpired = isUrlExpired(file.content); 
   
        if (isExpired) {
    
          const newUrl = await fetchUpdatedUrl(file.name); 
   
          updatedObject[key] = [{ ...file, content: newUrl?.url }]; 
        }
      }
    }

    return updatedObject;
  };




  const fetchSurveyResponse = async () => {
    setLoading(true);
    try {
      const data = await getSurveyResponseById(id);
      setData(data);
      if (data) {
        const survey2 = new Model(data.surveyResponse.program.form.fields)
        survey2.data = data.surveyResponse.responses
        survey2.mode = "display"
        survey2.applyTheme(PlainLight);
        survey2.locale = "fr";
        setSurveyModel2(survey2)
     const formJuridique = data?.surveyResponse?.juridiqueForm;
        if (formJuridique) {
          const parsedJson = JSON.parse(formJuridique?.fildes)
          const survey = new Model(parsedJson)
          const res = await updatedFilesDate(data?.surveyResponse?.juridiqueFormResponses)
          survey.data = res
          survey.mode = "display"
          survey.applyTheme(PlainLight);
          survey.locale = "fr";
          setSurveyModel(survey)
        }
      }
    } catch (error) {
      console.error("Error fetching survey responses:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSurveyResponse();
  }, []);


  return (
    <>
      {loading ? <div className="text-center p-4"><CircularProgress /></div> : null}
      <div>
        <div className="mx-auto p-6 bg-card text-foreground bg-slate-50">
          <h5>Détails de candidature</h5>
          <span className="text-gray-950 ">Statut :</span>{" "}
          <div
            className={`inline-flex items-center justify-center px-[12px] py-[5px] rounded-full text-xs font-medium min-w-[90px]  ${data?.surveyResponse?.status === "SOUMIS"
              ? "text-green-800 bg-green-100 border border-green-900"
              : data?.surveyResponse?.status === "Brouillon"
                ? "text-yellow-800 bg-yellow-100 border border-yellow-900"
                : data?.surveyResponse?.status === "Rejeté"
                  ? "text-red-800 bg-red-100 border border-red-900"
                  : "text-[#05CD99] bg-green-100 border border-[#05CD99]"
              }`}
          >
            {data?.surveyResponse?.status || "N/A"}
          </div>
          <div className="text-gray-700 text-base">
            <span className="text-gray-950">Nom et prénom :</span>{" "}
            {`${data?.userData?.firstName} ${data?.userData?.lastName}` || "N/A"}
          </div>

          <div className="text-gray-700 text-base">
            <span className="text-gray-950 ">Adresse E-mail :</span>{" "}
            {data?.userData?.email || "N/A"}
          </div>
          <div className="text-gray-700 text-base">
            <span className="text-gray-950 ">
              Titre de l'appel à candidature:
            </span>{" "}
            {data?.surveyResponse?.program?.title || "N/A"}
          </div>
          <div className="text-gray-700 text-base">
            <span className="text-gray-950 ">
              Description du projet :
            </span>{" "}       
            <div dangerouslySetInnerHTML={{__html: data?.surveyResponse?.program?.description || "N/A"}}/>
          </div>
        </div>


        <div className="mx-auto pl-6 bg-card text-foreground bg-slate-50">
          <h5>Commentaires du coach: </h5>
          <div>
            {data?.surveyResponse?.coachNote}
          </div>
        </div>







        <div className="mx-auto p-6 bg-card text-foreground bg-slate-50">
          <h5>Candidature détails: </h5>
          <div>
            {surveyModel2 && <Survey model={surveyModel2} />}
          </div>
        </div>
      </div>
      {surveyModel &&
        (<div className="mx-auto p-6 bg-card text-foreground bg-slate-50">
          <h5>Justificatif: Documents Juridiques</h5>
          <Survey model={surveyModel} />
        </div>)}
      <div className='flex justify-start items-start p-4'>
        <button
          onClick={() => navigate(-1)}
          className="bg-gray-200 text-gray-700 px-4 py-2 rounded-lg hover:bg-gray-300 ml-2"
        >
          Retour à la page d'accueil
        </button>
      </div>
    </>
  )
}

export default DetailsFormulaireJuridique
