import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { SurveyCreatorComponent, SurveyCreator } from "survey-creator-react";
import "survey-core/defaultV2.min.css";
import "survey-creator-core/survey-creator-core.min.css";
import "survey-creator-core/i18n/french";
import "survey-core/survey.i18n";
import { Serializer } from "survey-core";
import { Bounce, toast, ToastContainer } from 'react-toastify';
import { getFormById, updateForm } from '../../../../services/form.service';
import { CircularProgress } from '@mui/material';

const UpdateSurveyForm = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [creator, setCreator] = useState(null);
  const [loading, setLoading] = useState(null);

  useEffect(() => {
    getFormToBeUpdated();
  }, []);

  Serializer.addProperty("itemvalue", {
    name: "score:number",
  });

  const getFormToBeUpdated = async () => {
    try {
      setLoading(true);
      const response = await getFormById(id);
      const optionsDuCréateur = {
        showLogicTab: false,
        showTranslationTab: false,
        showJSONEditorTab: false,
        isAutoSave: true,
      };

      const jsonParDéfaut = JSON.parse(response?.fields);

      const créateur = new SurveyCreator(optionsDuCréateur);
      créateur.text = JSON.stringify(jsonParDéfaut);
      créateur.locale = "fr";

      // Set the saveSurveyFunc after the creator is initialized
      créateur.saveSurveyFunc = (saveNo, callback) => {
        window.localStorage.setItem("json-sondage", créateur.text);
        callback(saveNo, true);
      };

      setCreator(créateur);
    } catch (error) {
      console.log("error fetching the form by id", error);
    } finally {
      setLoading(false)
    }
  };

  const updateExistingForm = async () => {
    try {
      const jsonSondage = creator.JSON;

      const donnéesFormulaire = {
        fields : JSON.stringify(jsonSondage)
      };

      const réponse = await updateForm(id, donnéesFormulaire);
      if (réponse) {
        toast.success('Formulaire est modifié avec succès.', {
          position: "bottom-right",
          autoClose: 2000,
          theme: "colored",
          transition: Bounce,
        });
        setTimeout(() => {
          navigate(-1);
        }, 2000);
      }

    } catch (error) {
      console.error("Échec de la sauvegarde du sondage:", error);
    }
  };

  if(loading){
    return (
      <div className='flex justify-center items-center p-4'>
        <CircularProgress/>
      </div>
    )
  }

  return (
    <div>
      {creator && <SurveyCreatorComponent creator={creator} />}
      {creator && (
        <div className="flex space-x-4 mt-4 flex-row-reverse gap-8">
          <button
            className="bg-[#0976BC] text-white rounded-[8px] py-3 px-9"
            onClick={updateExistingForm}
          >
            Sauvegarder
          </button>
          <button
            className="bg-gray-500 text-white rounded-[8px] py-3 px-9"
            onClick={() => navigate(-1)}
          >
            Annuler
          </button>
        </div>
      )}
      <ToastContainer />
    </div>
  );
};

export default UpdateSurveyForm;
