import React from "react";

const Page6 = ({ parapheCanvas, ParaphCoordinateur }) => {
  return (
    <div
      className="p-8 bg-white text-black"
      style={{
        width: "210mm",
        minHeight: "300mm",
        margin: "0 auto",
        padding: "10px", // Reduced margin
        textAlign: "justify",
        boxSizing: "border-box",
        position: "relative",
      }}
    >
      <div className="mb-1 ">
        <p className="before:content-['•'] before:text-black before:mr-2 mb-1">
          Qu’en coordination avec d’autres partenaires éventuels, la mise en
          œuvre du financement des subventions répond aux objectifs du PADMPME ;
        </p>
        <p className="before:content-['•'] before:text-black before:mr-2 mb-1">
          Que l’évolution de l’entreprise du Bénéficiaire est évaluée et que
          l’UCP-PADMPME est informée des actions correctives éventuelles à
          prendre ;
        </p>
        <p className="before:content-['•'] before:text-black before:mr-2 mb-1">
          Que l’éligibilité de la dépense engagée par le Bénéficiaire ne fait
          l’objet d’aucune contestation dans le cas où cette dépense ne
          présenterait pas de lien évident avec les activités décrites ci-dessus
          comme devant faire l’objet de financement.
        </p>
      </div>

      <h4 className="text-md font-bold mb-2">
        Article 9 : Présentation des rapports
      </h4>
      <p className="mb-2 leading-[1.4]">
        En acceptant la présente convention, le Bénéficiaire s’engage à fournir
        au Consortium les rapports suivants, selon les fréquences et modalités
        énoncées ci-dessous :
      </p>

      <div className="mb-2 ">
        <p className="before:content-['•'] before:text-black before:mr-2 mb-2">
          <strong>
            Mensuellement (à compter du démarrage de l’accompagnement) :
          </strong>
        </p>
        <div className="space-y-2 mt-2 leading-[1.4] pl-6">
          <p className="before:content-['•'] before:text-black before:mr-2 mb-1">
            Rapport d’activité du Bénéficiaire, qui devra notamment inclure, (a)
            au titre de la production, les quantités et les coûts des matières
            premières achetées mensuellement, les quantités produites
            mensuellement (par type de produit), l’évolution des dettes
            fournisseurs ainsi que l’évolution des stocks et autres encours de
            production, (b) au titre des activités commerciales, les quantités
            vendues mensuellement (par type de produit) et les montants des
            ventes correspondantes ainsi que l’évolution des créances clients ;
          </p>
          <p className="before:content-['•'] before:text-black before:mr-2 mb-1">
            Rapport financier du Bénéficiaire qui devra notamment inclure
            l’évolution du chiffre d’affaires, des coûts de production (prix de
            revient) et de la marge brute ;
          </p>
        </div>
        <p className="before:content-['•'] before:text-black before:mr-2 mb-2">
          <strong>
            Chaque semestre (jusqu’au terme du COPA), un rapport sur la
            situation financière du Bénéficiaire, incluant notamment :
          </strong>
        </p>
        <div className=" mt-2 leading-[1.4] pl-6">
          <p className="before:content-['•'] before:text-black before:mr-2 mb-1">
            Un compte de résultats et un bilan financier de l’entreprise/du
            projet ;
          </p>
          <p className="before:content-['•'] before:text-black before:mr-2 mb-1">
            L’évolution du compte de trésorerie du Bénéficiaire ;
          </p>
          <p className="before:content-['•'] before:text-black before:mr-2 mb-1">
            L’évolution de l’endettement et du coût de la dette (intérêts) du
            Bénéficiaire.
          </p>
        </div>
      </div>

      <h4 className="text-md font-bold mb-2">
        Article 10 : Suspension des paiements
      </h4>
      <p className="mb-2 leading-[1.4]">
        Le Consortium peut demander la suspension du paiement de la subvention
        si :
      </p>
      <div className="mb-2 leading-[1.4]">
        <p className="before:content-['1.'] before:font-bold before:mr-2 mb-1">
          Le montant sollicité pour paiement par le Bénéficiaire n’a pas de
          rapport avec ses activités ;
        </p>
        <p className="before:content-['2.'] before:font-bold before:mr-2 mb-1">
          Les pièces justificatives appropriées n’ont pas été produites ;
        </p>
        <p className="before:content-['3.'] before:font-bold before:mr-2 mb-1">
          Des doutes existent sur l’éligibilité des dépenses et qu’il est
          nécessaire d’effectuer des contrôles supplémentaires ;
        </p>
        <p className="before:content-['4.'] before:font-bold before:mr-2 mb-1">
          Il est nécessaire de vérifier si le Bénéficiaire ou ses partenaires
          ont manqué à l’une des obligations substantielles qui leur incombent
          en vertu de la présente convention.
        </p>
      </div>

      <h4 className="text-md font-bold mb-2">
        Article 11 : Suspension du projet
      </h4>
      <p className="mb-2 leading-[1.4]">
        Le Bénéficiaire de la subvention peut suspendre la mise en œuvre du
        projet si des circonstances exceptionnelles, notamment relevant de la
        force majeure, rendent cette mise en œuvre difficile ou dangereuse. Il
        en informe alors le Consortium sans délai, en indiquant la nature, la
        durée probable et les effets prévisibles de la suspension. Il s’efforce
        de limiter la période de suspension ainsi que tout dommage éventuel et
        reprend la mise en œuvre aussitôt que les conditions le permettent, en
        informant le Consortium.
      </p>

      <div
        style={{
          position: "absolute",
          bottom: "10px",
          left: "50%",
          transform: "translateX(-50%)",
        }}
      >
        <p className="font-semibold">Page 6 sur 9</p>
      </div>
      {parapheCanvas && (
        <div
          style={{
            position: "absolute",
            bottom: "10px",
            left: "10px",
          }}
        >
          <img
            src={parapheCanvas}
            alt="Aperçu de la signature"
            style={{
              width: "70px",
              height: "60px",
              marginLeft: "20px",
              marginBottom: "-10px",
            }}
          />
        </div>
      )}
      {ParaphCoordinateur && (
        <div
          style={{
            position: "absolute",
            bottom: "10px",
            right: "10px",
            transform: "translateX(-50%)",
          }}
        >
          <img
            src={ParaphCoordinateur}
            alt="Aperçu de la signature"
            style={{
              width: "70px",
              height: "60px",
              marginLeft: "20px",
            }}
          />
        </div>
      )}
    </div>
  );
};

export default Page6;
