import React, { useState, useEffect, useCallback } from "react";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  CircularProgress,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { MagnifyingGlassIcon } from "@heroicons/react/24/solid";
import debounce from "lodash.debounce";
import { assignEvaluator } from "../../../services/evaluation.service";
import { getUsersByGroupId } from "../../../services/user.service";
import SuccessModalEvaluator from "./SuccessModalEvaluator";

const AssignEvaluator = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [evaluators, setEvaluators] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalCount, setTotalCount] = useState(0);
  const [isAssigning, setIsAssigning] = useState(false);
  const [assigningEvaluatorId, setAssigningEvaluatorId] = useState(null);
  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const [selectedEvaluator, setSelectedEvaluator] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const groupId = "1914c4c7-5a81-46a2-9109-2ab470792bca";

  const getQueryParams = (query) => {
    return new URLSearchParams(query).getAll("ids");
  };

  const selectedIds = getQueryParams(location.search);

  const fetchEvaluators = useCallback(async () => {
    setLoading(true);
    try {
      const response = await getUsersByGroupId(groupId, page + 1, rowsPerPage, searchTerm);
      setEvaluators(response?.docs || []);
      setTotalCount(response?.totalDocs || 0); // Ensure this comes from API response
    } catch (error) {
      console.error("Error fetching evaluators:", error);
    } finally {
      setLoading(false);
    }
  }, [page, rowsPerPage, groupId, searchTerm]);

  useEffect(() => {
    fetchEvaluators();
  }, [fetchEvaluators]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset page to 0 on rows per page change
  };

  const handleAssignEvaluator = async (evaluator) => {
    if (!evaluator || selectedIds.length === 0) return;

    setIsAssigning(true);
    setAssigningEvaluatorId(evaluator.id);

    try {
      for (const surveyResponseId of selectedIds) {
        await assignEvaluator(surveyResponseId, evaluator.id);
      }
      setSelectedEvaluator(evaluator);
      setSuccessModalOpen(true);
    } catch (error) {
      console.error("Error assigning evaluator:", error);
      alert("Failed to assign evaluator. Please try again.");
    } finally {
      setIsAssigning(false);
      setAssigningEvaluatorId(null);
    }
  };

  const handleCloseSuccessModal = () => {
    setSuccessModalOpen(false);
    setSelectedEvaluator(null);
    navigate("../liste-condidatures", { replace: true });
  };

  const debouncedSearch = useCallback(
    debounce((value) => {
      setSearchTerm(value);
      setPage(0);
    }, 300),
    []
  );

  const handleSearchChange = (event) => {
    const { value } = event.target;
    debouncedSearch(value);
  };

  return (
    <div className="mx-auto p-6 bg-card text-foreground bg-slate-50 h-full">
      <h1 className="text-3xl font-bold mb-0">Assigner un évaluateur</h1>
      <p className="text-gray-500 text-sm mb-2">
        Voici la liste des évaluateurs disponibles pour assignation
      </p>

      <div className="border rounded-lg p-3 mt-4 bg-white">
        <div className="flex justify-between items-center mb-3">
          <div className="relative w-1/4">
            <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
              <MagnifyingGlassIcon className="h-5 w-5 text-gray-500" />
            </div>
            <input
              type="search"
              id="default-search"
              className="py-[12px] ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg focus:outline-none w-full"
              placeholder="Rechercher par nom, email,..."
              onChange={handleSearchChange}
              required
            />
          </div>
        </div>

        <TableContainer className="relative overflow-x-auto rounded-lg border border-gray-300">
          {loading ? (
            <div className="flex justify-center my-4">
              <CircularProgress />
            </div>
          ) : (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Nom de l'évaluateur</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Ville</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {evaluators?.length > 0 ? (
                  evaluators?.map((evaluator) => (
                    <TableRow key={evaluator?.id}>
                      <TableCell>
                        {evaluator?.firstName} {evaluator?.lastName}
                      </TableCell>
                      <TableCell>{evaluator?.email}</TableCell>
                      <TableCell>{evaluator?.attributes?.ville}</TableCell>
                      <TableCell>
                        <Button
                          variant="contained"
                          color="primary"
                          className="no-underline bg-[#0976BC] hover:bg-[#065A94] transition-colors duration-300 capitalize text-white font-normal shadow-none px-[12px] py-[6px] rounded-full text-xs"
                          onClick={() => handleAssignEvaluator(evaluator)}
                          disabled={isAssigning && assigningEvaluatorId === evaluator.id}
                        >
                          {isAssigning && assigningEvaluatorId === evaluator.id ? (
                            <span>Assigning...</span>
                          ) : (
                            <span>Assigner</span>
                          )}
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={4} align="center">
                      Aucun évaluateur disponible
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          )}
        </TableContainer>

        <TablePagination
          component="div"
          count={totalCount}
          page={page}
          rowsPerPage={rowsPerPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[10, 25, 50]}
          labelDisplayedRows={({ from, to, count }) =>
            `${from}-${to} sur ${count !== -1 ? count : `plus de ${to}`}`
          }
          labelRowsPerPage="Lignes par page"
        />
      </div>

      <div className="mt-4">
        <h2 className="text-xl font-semibold">Candidatures sélectionnées</h2>
        <ul className="list-disc pl-5 mt-2">
          {selectedIds.map((id) => (
            <li key={id}>Candidature ID : {id}</li>
          ))}
        </ul>
      </div>

      {successModalOpen && (
        <SuccessModalEvaluator
          open={successModalOpen}
          onClose={handleCloseSuccessModal}
          coach={selectedEvaluator}
          message="Evaluators successfully assigned."
        />
      )}
    </div>
  );
};

export default AssignEvaluator;
