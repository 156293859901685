import axiosInstance from "../helpers/axiosIntercepter";

export const createActualite = async (formData) => {
  try {
    const response = await axiosInstance.post("/news-articles", formData);
    return response.data;
  } catch (error) {
    console.error("Error creating article", error);
    throw error;
  }
};

export const getAllActualites = async (
  page = 1,
  per_page = 10,
  status = ""
) => {
  try {
    const response = await axiosInstance.get("/news-articles/private", {
      params: {
        page,
        per_page,
        status,
      },
    });

    return {
      data: response?.data,
      totalDocs: response?.data?.totalDocs,
      totalPages: response?.data?.totalPages,
      currentPage: response?.data?.page,
      perPage: response?.data?.per_page,
      hasNextPage: response?.data?.hasNextPage,
      hasPreviousPage: response?.data?.hasPreviousPage,
    };
  } catch (error) {
    console.error("Error fetching all actualites:", error);
    throw new Error("Failed to fetch actualites. Please try again later.");
  }
};

export const getAllPublicActualites = async (
  page = 1,
  per_page = 10,
  searchTerm = ""
) => {
  try {
    const response = await axiosInstance.get("/news-articles", {
      params: {
        page,
        per_page,
        searchTerm,
      },
    });

    return {
      data: response?.data,
      totalDocs: response?.data?.totalDocs,
      totalPages: response?.data?.totalPages,
      currentPage: response?.data?.page,
      perPage: response?.data?.per_page,
      hasNextPage: response?.data?.hasNextPage,
      hasPreviousPage: response?.data?.hasPreviousPage,
    };
  } catch (error) {
    console.error("Error fetching actualites:", error);
    throw new Error("Failed to fetch actualites. Please try again later.");
  }
};

export const deleteActualite = async (id) => {
  try {
    const response = await axiosInstance.delete(`/news-articles/${id}`);
    return response.data;
  } catch (error) {
    console.error(`Error deleting actualite with ID: ${id}`, error);
    throw new Error("Failed to delete the actualité. Please try again later.");
  }
};

export const updateActualite = async (id, formData) => {
  try {
    const response = await axiosInstance.patch(
      `/news-articles/${id}`,
      formData
    );
    return response.data;
  } catch (error) {
    console.error(`Error updating actualite with ID: ${id}`, error);
    throw new Error("Failed to update the actualité. Please try again later.");
  }
};

export const updateActualiteStatus = async (id, formData) => {
  try {
    const response = await axiosInstance.patch(
      `/news-articles/${id}/status`,
      formData
    );
    return response.data;
  } catch (error) {
    console.error(`Error updating actualite status with ID: ${id}`, error);
    throw new Error(
      "Failed to update the actualité status. Please try again later."
    );
  }
};


export const updatePinnedActualite = async (id, formData) => {
  try {
    const response = await axiosInstance.patch(
      `/news-articles/${id}/pin`,
      formData
    );
    return response.data;
  } catch (error) {
    console.error(`Error updating pinned actualite with ID: ${id}`, error);
    throw new Error(
      "Failed to update the pinned actualité. Please try again later."
    );
  }
};

export const getActualiteById = async (id) => {
  try {
    const response = await axiosInstance.get(`/news-articles/private/${id}`);
    return response.data;
  } catch (error) {
    console.error(`Error fetching actualite with ID: ${id}`, error);
    throw error;
  }
};

export const getPublicActualiteById = async (id) => {
  try {
    const response = await axiosInstance.get(`/news-articles/${id}`);
    return response.data;
  } catch (error) {
    console.error(`Error fetching actualite with ID: ${id}`, error);
    throw error;
  }
};
