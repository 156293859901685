import React, { useState } from 'react';
import Banner from './Banner';
import CandidatureOffers from './CandidatureOffers';

const AppelsCandidature = () => {
  const [searchQuery, setSearchQuery] = useState('');

  return (
    <div className='flex flex-col'>
      <Banner setSearchQuery={setSearchQuery} /> 
      <CandidatureOffers searchQuery={searchQuery} /> 
    </div>
  );
};

export default AppelsCandidature;
