import React from "react";

const Page2 = ({
  entreprise,
  address,
  num_rccm,
  num_ident_national,
  num_impot,
  laureat_nom,
  qualite,
  parapheCanvas,
  ParaphCoordinateur,
}) => {
  return (
    <div
      className="p-8 bg-white text-black"
      style={{
        width: "210mm",
        minHeight: "300mm", // Increased height
        margin: "0 auto",
        padding: "10px", // Reduced margin
        textAlign: "justify",
        boxSizing: "border-box",
        position: "relative",
      }}
    >
      <h2 className="font-bold text-xl mb-6">
        La présente convention est conclue entre :
      </h2>

      <p className="mb-4 leading-[1.4]">
        <strong className="uppercase">
          L'UNITÉ DE COORDINATION DU PROGRAMME D’APPUI AUX MICRO PETITES ET
          MOYENNES ENTREPRISES, PADMPME,
        </strong>{" "}
        ayant son siège au 9-11, Croisement des avenues KISANGANI et Le MARINEL,
        2ème étage, Immeuble SOFIDE (en face du Collège BOBOTO), Code postal
        01209 | KINSHASA/GOMBE | République Démocratique du Congo, ici
        représentée par Monsieur{" "}
        <strong>MANGALA NGONGO Alexis, Coordonnateur National</strong>, agissant
        au nom et pour le compte du Ministère de l’Entrepreneuriat, Petites et
        Moyennes Entreprises,
      </p>
      <p className="mb-3 font-semibold">Ci-après dénommée « UCP-PADMPME »,</p>

      <p className="mb-3">
        <strong>Et</strong>
      </p>

      <p className="mb-4 leading-[1.4]">
        L’Entreprise {entreprise} dont le siège social est situé à {address}{" "}
        numéro RCCM {num_rccm}, numéro d’identification nationale{" "}
        {num_ident_national}. et numéro impôt {num_impot} ci-après représenté(e)
        par Monsieur/Madame {laureat_nom}. lauréat(e) au Concours des Plans
        d’Affaires, en sa qualité de {qualite}. de l'entreprise, <br />{" "}
        <strong>Ci-après dénommée « le Bénéficiaire »</strong>,
      </p>

      <p className="mb-4 font-semibold">
        Conjointement toutes ci-après dénommées « les Parties » ou séparément «
        la Partie »
      </p>

      <h3 className="text-lg font-bold mb-4">
        IL EST PREALABLEMENT EXPOSE QUE :
      </h3>

      <div className="space-y-4 text-[15px] leading-5">
        <p className="before:content-['•'] before:text-black before:mr-2">
          Le Gouvernement de la République Démocratique du Congo a sollicité et
          obtenu un Don de la Banque mondiale de 100 millions de dollars US pour
          financer le Projet d’Appui au Développement des Micro, Petites et
          Moyennes Entreprises, PADMPME, en sigle, placé sous le pilotage du
          Ministère de l’Entrepreneuriat, Petites et Moyennes Entreprises, à
          travers l’Unité de Coordination du Projet, UCP-PADMPME, en sigle ;
        </p>
        <p className="before:content-['•'] before:text-black before:mr-2">
          Par délégation de Maîtrise d’Ouvrage, l’UCP-PADMPME, qui assure la
          coordination du Projet, pour le compte du Gouvernement de la
          République Démocratique du Congo, a confié au Consortium « Deloitte,
          I&F Entrepreneuriat et Kivu Entrepreneurs » ci-après dénommé « le
          consortium », la mission de partenaire de mise en œuvre chargé, comme
          Opérateur de proximité :
        </p>
        <div className="pl-6 space-y-2">
          <p className="before:content-['(I)'] before:text-black before:mr-2">
            - De la sélection des plans d’affaires des entreprises Bénéficiaires
            des subventions accordées dans le cadre du Concours des plans
            d’affaires, « COPA » organisé dans les zones d’intervention du
            Projet, à savoir : Goma, Kinshasa, Lubumbashi et Matadi, ainsi que
          </p>
          <p className="before:content-['(II)'] before:text-black before:mr-2">
            - De la gestion des subventions accordées dans ce cadre.
          </p>
        </div>
        <p className="before:content-['•'] before:text-black before:mr-2">
          L’objectif global de financement des subventions est de soutenir la
          croissance des micros, petites et moyennes entreprises en vue
          d’assurer l’augmentation des opportunités d’emploi et
          d’entrepreneuriat pour les femmes, les jeunes et les PME à potentiel
          de croissance ;
        </p>
        <p className="before:content-['•'] before:text-black before:mr-2">
          Les fonds d’investissement destinés au financement par lesdites
          subventions s’inscrivent dans le cadre de la mise en œuvre de la
          composante 1.2 du Projet d’Appui au Développement des Micro, Petites
          et Moyennes Affaires, lesdits fonds constituant un mécanisme
          d’investissement avec contrepartie, suivant la conception du projet du
          bénéficiaire pour la sous-composante 1.2.
        </p>
      </div>

      <div
        style={{
          position: "absolute",
          bottom: "10px",
          left: "50%",
          transform: "translateX(-50%)",
        }}
      >
        <p className="font-semibold">Page 2 sur 9</p>
      </div>
      {parapheCanvas && (
        <div
          style={{
            position: "absolute",
            bottom: "10px",
            left: "10px",
          }}
        >
          <img
            src={parapheCanvas}
            alt="Aperçu de la signature"
            style={{
              width: "70px",
              height: "60px",
              marginLeft: "20px",
              marginBottom: "-10px",
            }}
          />
        </div>
      )}
      {ParaphCoordinateur && (
        <div
        style={{
          position: "absolute",
          bottom: "10px",
          right: "10px",
          transform: "translateX(-50%)",
        }}
      >
          <img
            src={ParaphCoordinateur}
            alt="Aperçu de la signature"
            style={{
              width: "70px",
              height: "60px",
              marginLeft: "20px",
            }}
          />
        </div>
      )}
    </div>
  );
};

export default Page2;
