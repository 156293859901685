// import processus from "../../assets/precessus-icon.png";
// import evaluation from "../../assets/avalution-icon.png";
import nosValeursImg from "../../assets/nosValeursImg.jpg";
// import imag from "../../assets/USA.png";

const Value = () => {
  // const WorkflowContent = [
  //   {
  //     id: 1,
  //     title: "3 Appels à candidatures",
  //     description:
  //       "Une cohorte PME et 2 cohortes Nouvelles Entreprises .",
  //     icon: processus,
  //   },
  //   {
  //     id: 2,
  //     title: "Un processus transparent",
  //     description:
  //       "Des processus d’éligibilité et de sélection définis consensuellement avec les parties prenantes de l’écosystème entrepreneurial sur l’ensemble des villes du projet .",
  //     icon: evaluation,
  //   },
  //   {
  //     id: 3,
  //     title: "Un parcours d’accompagnement complet",
  //     description:
  //       "L’accès à des formations qualitatives visant à renforcer les capacités des entrepreneurs et à améliorer leur performance.",
  //     icon: imag,
  //   },
  // ];
  return (
    // <div>
    //   <h2 className="text-center mt-5 mb-4 text-3xl font-roboto-slab px-[20%]">
    //     Nos valeurs
    //   </h2>
    //   <h3 className="text-sm text-center font-normal  text-gray-700 mb-6 px-[20%]">
    //     Le Projet TRANSFORME est régi par des valeurs contenues dans le Code
    //     d'éthique et de bonne conduite de son personnel. Ce code recommande le
    //     service au profit des bénéficiaires cibles et l'honnêteté dans les
    //     relations. Les principales valeurs attendues d'un agent du Projet COPA
    //     TRANSFORME RDC sont :
    //   </h3>
    //   <div className="grid grid-cols-1 lg:grid-cols-3 gap-6 justify-items-stretch">
    //     {WorkflowContent.map((item) => (
    //       <ProcessCard
    //         key={item.id}
    //         title={item.title}
    //         description={item.description}
    //         icon={item.icon}
    //       />
    //     ))}
    //   </div>
    // </div>

    <div className="flex flex-col lg:flex-row gap-6 mt-5 mb-4 w-[100%] mx-auto items-start">
      <div className=" rounded-lg w-full lg:w-[40%] h-[100%] ">
        <img
          src={nosValeursImg}
          alt="nosValeursImg"
          className="rounded-lg p-auto w-full "
        />
      </div>

      <div className="w-full lg:w-[60%]">
        <h1 className=" mb-2 font-roboto-slab text-3xl">Nos valeurs</h1>
        <p className="text-gray-700 text-left mb-4 text-sm">
          Le Projet TRANSFORME est régi par des valeurs contenues dans le Code
          d'éthique et de bonne conduite de son personnel. Ce code recommande le
          service au profit des bénéficiaires cibles et l'honnêteté dans les
          relations. Les principales valeurs attendues d'un agent du Projet COPA
          TRANSFORME RDC sont :
        </p>
        <ul className="list-disc pl-6 space-y-1 pr-8 text-sm">
          <li>
            Connaître et respecter la loi, les règlements et le Code d’éthique
            et de bonne conduite
          </li>
          <li>Respecter les partenaires et les collègues de travail</li>
          <li>Faire preuve de professionnalisme</li>
          <li>Cultiver la morale et l’éthique professionnelle</li>
          <li>Agir avec honnêteté, intégrité et loyauté</li>
          <li>Déclarer les conflits d’intérêt</li>
          <li>
            Devoir de pratique professionnelle envers le Projet COPA TRANSFORME
            RDC
          </li>
          <li>Faire preuve d’objectivité</li>
          <li>Faire preuve d’indépendance</li>
          <li>Préserver la confidentialité des renseignements</li>
          <li>Traiter équitablement les concurrents et les partenaires</li>
        </ul>
        <p className="text-gray-700 text-left mb-4 text-sm">
          L'accès aux bénéfices du Projet est strictement gratuit. Nul agent du
          Projet ne peut monnayer des services pour accorder des privilèges à
          des tiers.
          <br />
          Ces dispositions sont de stricte applaication.
        </p>
      </div>
    </div>
  );
};

export default Value;

// const ProcessCard = ({ title, id, description, icon }) => {
//   return (
//     <div
//       className="flex flex-col justify-between items-center bg-[#F9F9F9] rounded-md p-6 w-full h-full border-[0.75px] border-[#e1e0e0] flex-grow"
//       key={id}
//     >
//       <img src={icon} width={65} height={65} alt="" className="" />

//       <h1 className="font-semibold text-[#2F2F2F] text-base text-center my-4 w-full">
//         {title}
//       </h1>

//       <p className="text-[#808080] text-center text-sm leading-relaxed flex-grow">
//         {description}
//       </p>
//     </div>
//   );
// };
