import React, { useEffect, useState } from "react";
import { Pie } from "react-chartjs-2";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { laureatStatistics } from "../../services/statistics.service";
import { CircularProgress, FormControl, MenuItem, Select } from "@mui/material";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import { getAllProgramsFilters } from "../../services/program.service";
import { useAuth } from "../../contexts/AuthContext";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const EvaluateurDashboard = () => {
  const [statistics, setStatistics] = useState(null);
  const [loading, setLoading] = useState(true);
  const [programs, setPrograms] = useState([]);
  const [selectedProgram, setSelectedProgram] = useState({});
  const { userId } = useAuth();

  useEffect(() => {
    const fetchProgramFilters = async () => {
      try {
        const programFilters = await getAllProgramsFilters();
        programFilters?.unshift({
          id: undefined,
          title: "Appels à candidature",
        });
        setPrograms(programFilters);
      } catch (error) {
        console.log("error in fetching program filters list", error);
      }
    };

    fetchProgramFilters();
  }, [userId]);

  useEffect(() => {
    const fetchStatistics = async (programId = null) => {
      try {
        const data = await laureatStatistics(userId, null, programId);
        setStatistics(data);
      } catch (error) {
        console.error("Error fetching statistics:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchStatistics(selectedProgram?.id);
  }, [userId, selectedProgram]);

  const pieData = {
    labels: ["(%) Soumis", "(%) En cours", "(%) Sélectionné", "(%) Rejeté"],
    datasets: [
      {
        data: [
          statistics?.percentageSoumis,
          statistics?.percentageEnCoursEvaluation,
          statistics?.percentageSelectionne,
          statistics?.percentageRejected,
        ],
        backgroundColor: [
          "rgba(2, 173, 2, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(0, 90, 0, 0.2)",
          "rgba(255, 99, 132, 0.2)",
        ],
        borderColor: [
          "rgba(2, 173, 2, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(0, 90, 0.5, 1)",
          "rgba(255, 99, 132, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  if (loading) {
    return <CircularProgress />;
  }

  if (!statistics) {
    return <p>Failed to load statistics</p>;
  }

  const handleProgramChange = (event) => {
    const selectedValue = JSON.parse(event.target.value);
    setSelectedProgram(selectedValue);
  };

  return (
    <div className="mx-auto p-6 bg-card text-foreground  bg-slate-50 h-full">
      <h2 className="text-3xl font-bold mb-0">Tableau de bord</h2>
      <p className="text-gray-500 text-sm mb-2">
        Suivez et gérez les informations et les activités des candidats.
      </p>
      <div className="pb-2 flex justify-end">
        <FormControl
          variant="outlined"
          sx={{ minWidth: 150, maxWidth: "auto" }}
        >
          <Select
            value={selectedProgram.title || ""}
            onChange={handleProgramChange}
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
            IconComponent={() => (
              <ChevronDownIcon className="w-7 h-7 text-white mr-3" />
            )}
            renderValue={(selected) => (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <i className="bi bi-filter text-xl mx-2"></i>
                <span style={{ flex: 1, textAlign: "center" }}>
                  {selectedProgram?.title || "Appels à candidature"}
                </span>
              </div>
            )}
            sx={{
              backgroundColor: "#0976BC",
              color: "white",
              borderRadius: "30px",
              "& .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
              "& .MuiSelect-select": {
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                borderRadius: "30px",
                paddingRight: "2.5rem",
              },
              "& .MuiSelect-icon": {
                display: "none",
              },
            }}
          >
            {programs.map((program) => (
              <MenuItem
                key={program.id}
                value={JSON.stringify({ id: program.id, title: program.title })}
              >
                {program.title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mt-8">
        <div className="bg-card px-4 py-3 rounded-lg shadow-md border border-slate-400 transition duration-300 hover:scale-105 bg-white">
          <h2 className="text-base font-normal text-gray-700 flex items-center gap-3">
            Candidatures soumis
            <InformationCircleIcon className="w-5 h-5" />
          </h2>
          <p className="text-2xl font-bold text-[#0976BC]">
            {statistics?.totalSoumis}
          </p>
          <hr />
          <select className="mt-2 bg-input text-muted-foreground rounded">
            <option>Ce mois</option>
          </select>
        </div>
        <div className="bg-card p-4 rounded-lg shadow-md border border-slate-400 transition duration-300 hover:scale-105 bg-white">
          <h2 className="text-base font-normal text-gray-700 flex items-center gap-3">
            En cours d’évaluation
            <InformationCircleIcon className="w-5 h-5" />
          </h2>
          <p className="text-2xl font-bold text-[#0976BC]">
            {statistics?.totalEnCoursEvaluation}
          </p>
          <hr />
          <select className="mt-2 bg-input text-muted-foreground rounded">
            <option>Ce mois</option>
          </select>
        </div>

        <div className="bg-card p-4 rounded-lg shadow-md border border-slate-400 transition duration-300 hover:scale-105 bg-white">
          <h2 className="text-base font-normal text-gray-700 flex items-center gap-3">
            Candidatures sélectionné
            <InformationCircleIcon className="w-5 h-5" />
          </h2>
          <p className="text-2xl font-bold text-[#0976BC]">
            {statistics?.totalSelectionne}
          </p>
          <hr />
          <select className="mt-2 bg-input text-muted-foreground rounded">
            <option>Ce mois</option>
          </select>
        </div>
        <div className="bg-card p-4 rounded-lg shadow-md border border-slate-400 transition duration-300 hover:scale-105 bg-white">
          <h2 className="text-base font-normal text-gray-700 flex items-center gap-3">
            Candidatures Rejeté
            <InformationCircleIcon className="w-5 h-5" />
          </h2>
          <p className="text-2xl font-bold text-[#0976BC]">
            {statistics?.totalRejected}
          </p>
          <hr />
          <select className="mt-2 bg-input text-muted-foreground rounded">
            <option>Ce mois</option>
          </select>
        </div>
      </div>
      <div className="mt-8">
        <h3 className="text-xl font-bold mb-2">Répartition des Candidatures</h3>
        <div className=" flex justify-center bg-white p-6 rounded-lg shadow-md border border-slate-400">
          <Pie data={pieData} className="w-[500px] h-[500px] " />
        </div>
      </div>
    </div>
  );
};

export default EvaluateurDashboard;
