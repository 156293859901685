import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Survey } from "survey-react-ui";
import { Model } from "survey-core";
import { PlainLight } from "survey-core/themes";
import { Bounce, toast, ToastContainer } from "react-toastify";
import { uploadFile } from "../../../../services/uploadFile.service";
import axiosInstance from "../../../../helpers/axiosIntercepter";

const GetFormJuridique = () => {
  const { surveyResponseId } = useParams();
  const [formData, setFormData] = useState(null);
  const [surveyModel, setSurveyModel] = useState(null);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchFormJuridique = async () => {
      try {
        const response = await axiosInstance.get(
          `/jurdique-form/get-one-formulaire-juridique/${surveyResponseId}`
        );

        const form = response.data;
        setFormData(form);

        if (form?.fildes) {
          const parsedSurvey = JSON.parse(form.fildes);
          const survey = new Model(parsedSurvey);
          survey.getAllQuestions().forEach((question) => {
            if (question.getType() === "file") {
              question.storeDataAsText = false;
              question.allowImagesPreview = true;
              question.waitForUpload = true;
              question.allowMultiple = true;
            }
          });
          survey.applyTheme(PlainLight);
          survey.locale = "fr";

          survey.onUploadFiles.add(async (_, options) => {
            const uploadedUrls = [];
            for (const file of options.files) {
              const urls = await uploadFile(
                file,
                "juridique/" + surveyResponseId ,
                18000,
                "private"
              );

              uploadedUrls.push(
                Object.assign({}, file, {
                  name: urls[0].name,
                  fullUrl: urls[0].url,
                  type: file.type,
                  size: file.size,
                  lastModified: file.lastModified,
                  lastModifiedDate: file.lastModifiedDate,
                  webkitRelativePath: file.webkitRelativePath,
                })
              );
            }
            options.files = uploadedUrls;

            options.callback(
              options.files.map((file) => {
                return {
                  file: file,
                  content: file.fullUrl,
                };
              })
            );
          });

          survey.onComplete.add(async (sender) => {
            const surveyData = sender.data;
            try {
              const uploadUrl = `/jurdique-form/upload-juridique/${surveyResponseId}`;
              await axiosInstance.post(uploadUrl, { files: surveyData });

              toast.success(
                "Vos documents juridiques ont été envoyées avec succès!",
                {
                  position: "bottom-right",
                  autoClose: 2000,
                  theme: "colored",
                  transition: Bounce,
                }
              );

              setTimeout(() => {
                navigate("/laureat-dashboard");
              }, 2000);
            } catch (error) {
              console.error("Error uploading survey data", error);
              // alert("Erreur lors du téléchargement des données d'enquête. Veuillez réessayer.");
              toast.error(
                "Erreur lors du téléchargement des données d'enquête. Veuillez réessayer.",
                {
                  position: "bottom-right",
                  autoClose: 2000,
                  theme: "colored",
                  transition: Bounce,
                }
              );

              setTimeout(() => {
                navigate("/laureat-dashboard");
              }, 2000);
            }
          });

          setSurveyModel(survey);
        } else {
          setError("No survey data found");
        }
      } catch (error) {
        setError("Error fetching form data");
        console.error(error);
      }
    };

    if (surveyResponseId) {
      fetchFormJuridique();
    }
  }, [surveyResponseId]);



  return (
    <div>
      {error && <p>{error}</p>}
      {formData ? (
        <div>
          {surveyModel ? (
            <Survey model={surveyModel} />
          ) : (
            <p>Loading survey...</p>
          )}
        </div>
      ) : (
        <p>Loading form data...</p>
      )}
      <ToastContainer />
    </div>
  );
};

export default GetFormJuridique;
