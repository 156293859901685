import React, { useContext, useEffect } from "react";
import { useNavigate, Outlet } from "react-router-dom";
import { AuthContext } from "../contexts/AuthContext";
// import { decodeToken } from "../helpers/decodeToken";

function PrivateRoute() {
  const { isAuthenticated, userRole } = useContext(AuthContext);
  const navigate = useNavigate();
  // const location = useLocation();
  const token = localStorage.getItem("access-token");

  useEffect(() => {
    if (!token) {
      navigate("/");
      return;
    }

    if (isAuthenticated && token) {
      // const decodedToken = decodeToken(token);


      if (userRole === "laureat") {
        const programId = localStorage.getItem("programId");
        if (programId) {
          navigate("/laureat-dashboard/fill-form", { replace: true });
        } else {
          navigate("/laureat-dashboard", { replace: true });
        }
      } else if (userRole === "coach") {
        navigate("/coach-dashboard", { replace: true });
      } else if (userRole === "unite_de_gestion") {
        navigate("/unite_de_gestion-dashboard", { replace: true });
      } else if (userRole === "unite_de_coordination_de_projet") {
        navigate("/unite_de_coordination_de_projet-dashboard", { replace: true });
      } else if (userRole === "consortium") {
          navigate("/consortium-dashboard", { replace: true });
      } else if (userRole === "coordinateur") {
        navigate("/coordinateur-dashboard", { replace: true });
      } else if (userRole === "evaluateur") {
        navigate("/evaluateur-dashboard", { replace: true });
      }
       else {
        navigate("/auth/login", { replace: true });
      }
    }
  }, [isAuthenticated, token, navigate]);

  return <Outlet />;
}

export default PrivateRoute;
