import React, { useEffect, useRef, useState } from "react";
import Page1 from "./sub-components/Page1";
import Page2 from "./sub-components/Page2";
import Page3 from "./sub-components/Page3";
import Page4 from "./sub-components/Page4";
import Page5 from "./sub-components/Page5";
import Page6 from "./sub-components/Page6";
import Page7 from "./sub-components/Page7";
import Page8 from "./sub-components/Page8";
import Page9 from "./sub-components/Page9";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { getSurveyResponseById } from "../../services/surveyResponse.service";

const choices_qualite = [
  { value: "Item 4", text: "Associé / propriétaire ou actionnaire" },
  { value: "Item 5", text: "Gérant" },
];

const Convention = ({
  survey_response_id,
  formData,
  signature,
  parapheCanvas,
}) => {
  const conventionRefs = useRef([]);
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [useResponse, setUserResponse] = useState(null);
  const [missingInfo, setMissingInfo] = useState(null);
  const [prapheSignature, setPrapheSignature] = useState(null);
  const [parapheCoordinateur, setParapheCoordinateur] = useState(null);
  const [signatureUrlLaureat, setSignatureUrlLaureat] = useState(null);
  const [signatureUrlCordinateur, setSignatureUrlCordinateur] = useState(null);
  const [montantSubvention, setMontantSubvention] = useState("");

  useEffect(() => {
    if (!survey_response_id) return;

    getSurveyResponseById(survey_response_id).then((data) => {
      if (data?.surveyResponse) {
        setUserResponse(data.surveyResponse.responses || {});
        setMontantSubvention(
          data.surveyResponse?.cadrageDocsResponses?.montant_subvention || ""
        );
        setMissingInfo(data.surveyResponse?.missingInfo);
        setPrapheSignature(data.surveyResponse?.parapheUrl);
        setSignatureUrlLaureat(data.surveyResponse?.laureatSignatureUrl);
        setSignatureUrlCordinateur(
          data.surveyResponse?.cordinateurSignatureUrl
        );
        setParapheCoordinateur(data.surveyResponse?.cordinateurPrapheUrl)
      }
    });
  }, [survey_response_id]);

  const getValue = (fetchedValue, formValue, defaultValue) =>
    fetchedValue || formValue || defaultValue;

  const ensureHttps = (url) => {
    if (!url) return null;
    if (!url?.startsWith("https://")) {
      return `https://${url}`;
    }
    return url;
  };

  const exportToPdf = async () => {
    setLoading(true);
    setProgress(0);

    const pdf = new jsPDF("p", "mm", "a4");
    const pageWidth = pdf.internal.pageSize.getWidth();
    const pageHeight = pdf.internal.pageSize.getHeight();

    for (let i = 0; i < conventionRefs.current.length; i++) {
      const page = conventionRefs.current[i];
      if (page) {
        try {
          const canvas = await html2canvas(page, {
            scale: 2, // Higher scale for better quality
            useCORS: true,
            backgroundColor: "white",
            width: page.offsetWidth,
            height: page.offsetHeight,
          });

          const imgData = canvas.toDataURL("image/png");

          // Calculate image dimensions to fit A4 page
          const imgWidth = pageWidth - 20; // Add padding
          const imgHeight = (canvas.height * imgWidth) / canvas.width;

          pdf.addImage(imgData, "PNG", 10, 10, imgWidth, imgHeight);

          if (i < conventionRefs.current.length - 1) {
            pdf.addPage();
          }

          setProgress(
            Math.round(((i + 1) / conventionRefs.current.length) * 100)
          );
        } catch (error) {
          console.error(`Error rendering page ${i + 1}:`, error);
        }
      }
    }

    pdf.save("Convention.pdf");
    setLoading(false);
  };

  return (
    <div style={styles.container}>
      {loading && (
        <div style={styles.loader}>
          <p style={styles.loaderText}>Exportation... {progress}%</p>
        </div>
      )}
      <div style={styles.pagesContainer}>
        {[
          <Page1
            key={1}
            entreprise={getValue(
              useResponse?.entreprise_participante,
              formData?.entreprise_participante,
              "………………………"
            )}
            annee={new Date().getFullYear()}
            code_pme={getValue(
              formData?.cd_pme,
              missingInfo?.cd_pme,
              "CODE PME"
            )}
            code_ville={getValue(
              formData?.cd_ville,
              missingInfo?.cd_ville,
              "CODE VILLE"
            )}
            num_convention={getValue(
              formData?.nb_dyn,
              missingInfo?.nb_dyn,
              "…………"
            )}
            parapheCanvas={getValue(
              parapheCanvas,
              ensureHttps(prapheSignature),
              ""
            )}
            ParaphCoordinateur={getValue(
              parapheCanvas,
              ensureHttps(parapheCoordinateur),
              ""
            )}
          />,
          <Page2
            key={2}
            entreprise={getValue(
              useResponse?.entreprise_participante,
              formData?.entreprise_participante,
              "……………………"
            )}
            qualite={
              choices_qualite.find(
                (elm) => elm.value === useResponse?.qualite_entreprise
              )?.text ?? "……………………"
            }
            num_rccm={getValue(
              useResponse?.numero_rccm,
              formData?.num_rccm,
              "……………………"
            )}
            num_ident_national={getValue(
              formData?.num_ident_national,
              missingInfo?.num_ident_national,
              "………………."
            )}
            num_impot={getValue(
              formData?.grant_amount,
              missingInfo?.grant_amount,
              "……………"
            )}
            laureat_nom={getValue(
              `${useResponse?.nom_du_candidat || "N/A"} ${
                useResponse?.prenom_du_candidat || "N/A"
              }`,
              formData?.laureat_nom,
              "N/A"
            )}
            address={getValue(
              useResponse?.adresse_de_entreprise,
              formData?.address,
              "………………………………"
            )}
            parapheCanvas={getValue(
              parapheCanvas,
              ensureHttps(prapheSignature),
              ""
            )}
            ParaphCoordinateur={getValue(
              parapheCanvas,
              ensureHttps(parapheCoordinateur),
              ""
            )}
          />,
          <Page3
            key={3}
            entreprise={getValue(
              useResponse?.entreprise_participante,
              formData?.entreprise_participante,
              "………………………."
            )}
            parapheCanvas={getValue(
              parapheCanvas,
              ensureHttps(prapheSignature),
              ""
            )}
            ParaphCoordinateur={getValue(
              parapheCanvas,
              ensureHttps(parapheCoordinateur),
              ""
            )}
            date_disposition={getValue(
              formData?.disposition_date,
              missingInfo?.disposition_date,
              "xx/xx/2021"
            )}
            date_signature={getValue(
              formData?.date_signature,
              missingInfo?.date_signature,
              "xx/xx/2021"
            )}
            montant_subvention={getValue(
              montantSubvention,
              formData?.montant_subvention,
              "……………………………………………………………………….."
            )}
          />,
          <Page4
            key={4}
            parapheCanvas={getValue(
              parapheCanvas,
              ensureHttps(prapheSignature),
              ""
            )}
            ParaphCoordinateur={getValue(
              parapheCanvas,
              ensureHttps(parapheCoordinateur),
              ""
            )}
          />,
          <Page5
            key={5}
            parapheCanvas={getValue(
              parapheCanvas,
              ensureHttps(prapheSignature),
              ""
            )}
            ParaphCoordinateur={getValue(
              parapheCanvas,
              ensureHttps(parapheCoordinateur),
              ""
            )}
          />,
          <Page6
            key={6}
            parapheCanvas={getValue(
              parapheCanvas,
              ensureHttps(prapheSignature),
              ""
            )}
            ParaphCoordinateur={getValue(
              parapheCanvas,
              ensureHttps(parapheCoordinateur),
              ""
            )}
          />,
          <Page7
            key={7}
            parapheCanvas={getValue(
              parapheCanvas,
              ensureHttps(prapheSignature),
              ""
            )}
            ParaphCoordinateur={getValue(
              parapheCanvas,
              ensureHttps(parapheCoordinateur),
              ""
            )}
          />,
          <Page8
            key={8}
            parapheCanvas={getValue(
              parapheCanvas,
              ensureHttps(prapheSignature),
              ""
            )}
            ParaphCoordinateur={getValue(
              parapheCanvas,
              ensureHttps(parapheCoordinateur),
              ""
            )}
          />,
          <Page9
            key={9}
            name={getValue(
              `${useResponse?.nom_du_candidat || ""} ${
                useResponse?.prenom_du_candidat || ""
              }`,
              formData?.name,
              "…………………………………"
            )}
            fait_at={getValue(
              formData?.ucp_address,
              missingInfo?.ucp_address,
              "……………………"
            )}
            signature={getValue(
              signature,
              ensureHttps(signatureUrlLaureat),
              ""
            )}
            date_signature={getValue(
              formData?.date_signature,
              missingInfo?.date_signature,
              "xx/xx/2021"
            )}
            parapheCanvas={getValue(
              parapheCanvas,
              ensureHttps(prapheSignature),
              ""
            )}
            ParaphCoordinateur={getValue(
              parapheCanvas,
              ensureHttps(parapheCoordinateur),
              ""
            )}
            signatureUrlCordinateur={signatureUrlCordinateur}
          />,
        ].map((PageComponent, index) => (
          <div key={index} ref={(el) => (conventionRefs.current[index] = el)}>
            {PageComponent}
          </div>
        ))}
      </div>
      <button onClick={exportToPdf} style={styles.button}>
        Exporter en PDF
      </button>
    </div>
  );
};

export default Convention;

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "100vh",
    width: "100%",
    position: "relative",
    backgroundColor: "#f0f0f0",
    padding: "20px",
  },
  pagesContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "20px",
  },
  loader: {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(0, 0, 0, 0.7)",
    padding: "20px 40px",
    borderRadius: "8px",
    zIndex: 1000,
  },
  loaderText: {
    color: "#fff",
    fontSize: "24px",
    fontWeight: "bold",
    textAlign: "center",
  },
  button: {
    marginTop: "20px",
    padding: "10px 20px",
    fontSize: "16px",
    cursor: "pointer",
    border: "none",
    backgroundColor: "#007BFF",
    color: "#fff",
    borderRadius: "4px",
  },
};
