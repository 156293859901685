import React, { useEffect, useState, useCallback, useMemo } from "react";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Checkbox,
  MenuItem,
  Menu,
  CircularProgress,
  Switch,
  Drawer,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Select,
  FormControl,
} from "@mui/material";
import { BsFileEarmarkArrowDown, BsFilter, BsPlusSquare } from "react-icons/bs";
import { Outlet, useNavigate } from "react-router-dom";
import InviteCoachDialog from "./InviteCoachDialog";
import { CalendarIcon } from "@heroicons/react/24/outline";
import {
  deleteUser,
  exportUsersPdf,
  getUsersByGroupId,
  listAllGroups,
  toggleUserStatus,
} from "../../../services/user.service";
import {
  ChevronDownIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/solid";
import debounce from "lodash/debounce";
import { forgetPasswordService } from "../../../services/auth.service";
import SuccessModal from "./SuccessModal";
import { Bounce, toast, ToastContainer } from "react-toastify";
import detailsButton from "../../../assets/viewDetailsButton.png";
import actionButton from "../../../assets/actionButton.png";
import closeSidebarDetailsIcon from "../../../assets/closeSidebarDetailsIcon.png";
import viewIcon from "../../../assets/viewIcon.png";
import deleteIcon from "../../../assets/supprimerIcon.png";
import updateIcon from "../../../assets/updateIcon.png";
import deleteIconUser from "../../../assets/deleteIconActualite.png";
import * as XLSX from "xlsx";

const ListeDesCoachs = () => {
  const [filter, setFilter] = useState("");
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selected, setSelected] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEle, setAnchorEle] = useState(null);
  const [selectedCoach, setSelectedCoach] = useState(null);
  const [groups, setGroups] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [coaches, setCoaches] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingExport, setLoadingExport] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [totalRecords, setTotalRecords] = useState(0);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [loadingStatus, setLoadingStatus] = useState({});
  const [toggleStatusLoading, setToggleStatusLoading] = useState({});
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);

  const navigate = useNavigate();

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
    setPage(0);
  };

  const toggleSideBar = (coach) => {
    setSelectedUser(coach);
    setIsSidebarOpen(true);
  };

  const handleSidebarClose = () => {
    setIsSidebarOpen(false);
  };

  const handleCloseSuccessModal = () => {
    setShowSuccessModal(false);
  };

  const handleActionClick = (event, coach) => {
    setAnchorEle(event.currentTarget);
    setSelectedCoach(coach);
  };

  const handleActionMenuClose = () => {
    setAnchorEle(null);
    setSelectedCoach(null);
  };

  const handleOpenDeleteDialog = (coach) => {
    setSelectedCoach(coach);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const confirmDelete = async () => {
    try {
      if (selectedCoach) {
        await deleteUser(selectedCoach.id);
        setOpenDialog(false);
        setSelectedCoach(null);

        await fetchUsersByGroup();

        toast.success("L'utilisateur a été supprimé avec succès.", {
          position: "bottom-right",
          autoClose: 2000,
          theme: "colored",
          transition: Bounce,
        });
      }
    } catch (error) {
      toast.error("Erreur lors de la suppression de l'utilisateur.", {
        position: "bottom-right",
        autoClose: 2000,
        theme: "colored",
        transition: Bounce,
      });
    }
  };

  const handleEdit = () => {
    navigate(
      `/unite_de_gestion-dashboard/liste-utilisateurs/update/${selectedCoach?.id}`
    );
  };

  useEffect(() => {
    const fetchGroups = async () => {
      try {
        const groupList = await listAllGroups(1, 25);

        const filteredGroups = groupList.filter((group) => {
          const groupName = group.name.toLowerCase();
          return (
            // groupName === "coachs" ||
            groupName === "evaluateurs" ||
            groupName === "laureats" ||
            groupName === "unites de gestions" ||
            groupName === "coachs" ||
            groupName === "unites de coordination de projet" ||
            groupName === "coordinateurs"
          );
        });

        setGroups(filteredGroups);

        const coachGroup = filteredGroups.find(
          (group) => group.name.toLowerCase() === "laureats"
        );
        if (coachGroup) {
          setSelectedGroup(coachGroup);
        }
      } catch (error) {
        console.error("Error fetching groups:", error);
      }
    };

    fetchGroups();
  }, []);

  const formatDate = (dateString) => {
    const date = new Date(parseInt(dateString));
    return date.toLocaleDateString("fr-FR", {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });
  };

  useEffect(() => {
    if (!selectedGroup) return;

    fetchUsersByGroup();
  }, [selectedGroup, page, rowsPerPage, filter]);

  const debouncedSearch = useCallback(
    debounce((term) => {
      fetchUsersByGroup(term);
    }, 500),
    [selectedGroup, page, rowsPerPage]
  );

  const handleSearchInputChange = (e) => {
    const term = e.target.value;
    setSearchTerm(term);
    debouncedSearch(term);
  };

  const filteredGroups = useMemo(() => {
    return groups.filter((group) => group.name.toLowerCase() !== "admins");
  }, [groups]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (shouldRefresh = false) => {
    setOpen(false);
    if (shouldRefresh) {
      fetchUsersByGroup();
    }
  };

  const handleChangePage = (event, newPage) => {
    setSearchTerm("");
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      setSelected(coaches?.map((coach) => coach?.id));
    } else {
      setSelected([]);
    }
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleFilterClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleGroupSelect = useCallback((groupId) => {
    setSelectedGroup(groupId);
    handleMenuClose();
  }, []);

  const fetchUsersByGroup = async (searchTerm = "") => {
    setLoading(true);
    try {
      const response = await getUsersByGroupId(
        selectedGroup.id,
        page + 1,
        rowsPerPage,
        searchTerm,
        filter
      );
      setCoaches(response?.docs || []);
      setTotalRecords(response?.totalDocs || 0);
    } catch (error) {
      console.error("Error fetching users:", error);
    } finally {
      setLoading(false);
    }
  };

  const relancer = async (e, email, coachId) => {
    e.preventDefault();

    if (!email || !coachId) {
      console.error("Missing email or coach ID");
      return;
    }

    try {
      setLoadingStatus((prevStatus) => ({
        ...prevStatus,
        [coachId]: true,
      }));

      await forgetPasswordService({ email });
      setShowSuccessModal(true);
    } catch (error) {
      console.error("Error in relancer function:", error);
    } finally {
      setLoadingStatus((prevStatus) => ({
        ...prevStatus,
        [coachId]: false,
      }));
    }
  };

  const handleToggleStatus = async (coachId, isEnabled) => {
    try {
      setToggleStatusLoading((prevState) => ({
        ...prevState,
        [coachId]: true,
      }));

      const result = await toggleUserStatus(coachId);

      toast.success(
        isEnabled
          ? "L'utilisateur est activé avec succès!"
          : "L'utilisateur est désactivé avec succès!",
        {
          position: "bottom-right",
          autoClose: 2000,
          theme: "colored",
          transition: Bounce,
        }
      );

      await fetchUsersByGroup();
    } catch (error) {
      console.error("Error toggling status:", error);
      toast.error("Erreur lors du changement de statut", {
        position: "bottom-right",
        autoClose: 2000,
        theme: "colored",
        transition: Bounce,
      });
    } finally {
      setToggleStatusLoading((prevState) => ({
        ...prevState,
        [coachId]: false,
      }));
    }
  };


  const handleExportClick = async () => {
    const queryParams = {
      group_id: selectedGroup?.id,  
      is_email_verified: filter,      
    };
  

  
    if (!queryParams.group_id || queryParams.is_email_verified === undefined) {
      toast.error("Please select a valid group and filter option before exporting.", {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: false,
      });
      return;
    }
  
    
    setLoadingExport(true);  
  
    try {
      await exportUsersPdf(queryParams);
  
      toast.success(
        "L'exportation de la liste des utilisateurs a été réalisée avec succès !",
        {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
        }
      );
    } catch (error) {
      console.error("Erreur lors de l'exportation de la liste des utilisateurs.", error);
      toast.error(
        "Erreur lors de l'exportation de la liste des candidatures.",
        {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
        }
      );
    } finally {
      setLoadingExport(false);
    }
  };
  
  
  return (
    <div className="mx-auto p-6 bg-card text-foreground  bg-slate-50 h-full">
      <h1 className="text-3xl font-bold mb-0">Liste des utilisateurs</h1>
      <p className="text-gray-500 text-sm mb-2">
        Suivez et gérez les informations et les activités des clients.
      </p>
      <div className="flex justify-end mb-4">
        <Button
          style={{ textTransform: "none" }}
          className="bg-[#0976BC] hover:bg-[#065A94] transition-colors duration-300 text-white px-4 py-2 rounded-md mb-0 flex items-center  font-medium"
          startIcon={<BsPlusSquare />}
          onClick={handleClickOpen}
        >
          Inviter un nouvel utilisateur
        </Button>
      </div>
      <InviteCoachDialog
        open={open}
        onClose={(shouldRefresh) => handleClose(shouldRefresh)}
        groups={filteredGroups}
      />

      <div className="border rounded-lg p-3 bg-white">
        <div className="flex justify-between items-center mb-3">
          <div className="relative w-1/4">
            <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
              <MagnifyingGlassIcon className="h-5 w-5 text-gray-500" />
            </div>
            <input
              type="search"
              id="default-search"
              className="py-[12px] ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg focus:outline-none w-full"
              placeholder="Rechercher par nom, adresse mail,..."
              value={searchTerm}
              onChange={handleSearchInputChange}
            />
          </div>
          <div className="flex items-center space-x-4">
            <div>
              <FormControl variant="outlined" sx={{ width: 200 }}>
                <Select
                  value={filter}
                  onChange={handleFilterChange}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  IconComponent={() => (
                    <ChevronDownIcon className="w-7 h-7 text-gray mr-3" />
                  )}
                  renderValue={(selected) =>
                    selected ? (
                      <span>
                        {selected === "true" ? "Vérifié" : "Non vérifié"}
                      </span>
                    ) : (
                      "Statut de vérification"
                    )
                  }
                  sx={{
                    backgroundColor: "white",
                    color: "#333",
                    borderRadius: "4px",
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "1px solid #dee2e6",
                    },
                    "& .MuiSelect-select": {
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      borderRadius: "30px",
                      paddingRight: "2.5rem",
                    },
                    "& .MuiSelect-icon": {
                      display: "none",
                    },
                  }}
                >
                  <MenuItem value="">Statut de vérification</MenuItem>
                  <MenuItem value="true">Vérifié</MenuItem>
                  <MenuItem value="false">Non vérifié</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div>
              <Button
                style={{ textTransform: "none" }}
                className="flex items-center text-gray-600 px-4 py-2 border border-gray-300 rounded-md"
                onClick={handleFilterClick}
              >
                <BsFilter className="mr-2" />
                {selectedGroup?.name}
              </Button>
              <Menu
                id="filter-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
              >
                {filteredGroups.map((group) => (
                  <MenuItem
                    key={group.id}
                    onClick={() => handleGroupSelect(group)}
                  >
                    {group.name}
                  </MenuItem>
                ))}
              </Menu>
            </div>
            <div>
              <Button
                style={{
                  textTransform: "none",
                  fontFamily: "Arial, sans-serif",
                  fontSize: "13px",
                  fontWeight: "600",
                  position: "relative",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                className={`flex items-center text-gray-600 px-4 py-2 border border-gray-300 rounded-md hover:bg-transparent hover:border-gray-400 hover:bg-slate-50 hover:shadow-sm ${
                  loadingExport ? "cursor-not-allowed opacity-50" : ""
                }`}
                onClick={!loadingExport ? handleExportClick : null} 
                disabled={loadingExport} 
              >
                {loadingExport ? (
                  <CircularProgress size={20} style={{ marginRight: "8px", color: "#000000" }} />
                ) : (
                  <BsFileEarmarkArrowDown className="mr-2" />
                )}
                {loadingExport ? <span>Exportation...</span> : <span>Exporter</span>}
              </Button>
            </div>
          </div>
        </div>

        <TableContainer className="relative overflow-x-auto rounded-lg border border-gray-300">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={selected?.length === coaches?.length}
                    onChange={handleSelectAllClick}
                    inputProps={{ "aria-label": "select all coaches" }}
                  />
                </TableCell>
                <TableCell>Nom d'utilisateur</TableCell>
                <TableCell>Email d'utilisateur</TableCell>
                <TableCell>Date d'invitation</TableCell>
                <TableCell>Rôle</TableCell>
                <TableCell>Statut</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableRow>
                  <TableCell colSpan={7} align="center">
                    <CircularProgress style={{ color: "#87CEEB" }} />
                  </TableCell>
                </TableRow>
              ) : coaches.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={7} align="center">
                    Aucune donnée à afficher
                  </TableCell>
                </TableRow>
              ) : (
                coaches?.map((coach) => {
                  const isItemSelected = isSelected(coach.id);
                  const isLoading = loadingStatus[coach.id] || false;
                  const isTogglingStatus =
                    toggleStatusLoading[coach?.id] || false;

                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event, coach.id)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={coach.id}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          inputProps={{ "aria-labelledby": coach.id }}
                        />
                      </TableCell>
                      <TableCell>
                        {coach.firstName + " " + coach.lastName}
                      </TableCell>
                      <TableCell>{coach?.email}</TableCell>
                      <TableCell className="px-2 py-1">
                        <div className="flex items-center space-x-2">
                          <span className="p-1 rounded-full bg-gray-200">
                            <CalendarIcon className="h-5 w-5 text-gray-600" />
                          </span>
                          <span>{formatDate(coach?.createdTimestamp)}</span>
                        </div>
                      </TableCell>
                      <TableCell>
                        <span className="font-semibold">
                          {selectedGroup?.name}
                        </span>
                      </TableCell>
                      <TableCell>
                        <span className="font-semibold">
                          {coach?.enabled ? "Actif" : "Bloqué"}
                        </span>
                      </TableCell>
                      <TableCell>
                        <div className="flex items-center  ">
                          {!coach?.emailVerified ? (
                            <button
                              className=" w-[60px] h-[25px] no-underline bg-[#0976BC] hover:bg-[#065A94] transition-colors duration-300 text-white px-[6px] py-[4px] rounded-full text-xs my-0"
                              onClick={(e) =>
                                relancer(e, coach?.email, coach.id)
                              }
                              disabled={isLoading}
                            >
                              {isLoading ? "Envoi..." : "Relancer"}
                            </button>
                          ) : (
                            <Switch
                              className="w-[60px] "
                              checked={coach?.enabled}
                              onChange={() =>
                                handleToggleStatus(coach.id, !coach?.enabled)
                              }
                              disabled={isTogglingStatus}
                              color="primary"
                            />
                          )}
                          <IconButton onClick={() => toggleSideBar(coach)}>
                            <img
                              className="w-8 h-8"
                              src={detailsButton}
                              alt="View Details"
                            />
                          </IconButton>
                          <IconButton
                            className="w-12 h-12"
                            onClick={(event) => handleActionClick(event, coach)}
                          >
                            <img src={actionButton} alt="Actions" />
                          </IconButton>
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                })
              )}
            </TableBody>
          </Table>
          <TablePagination
            component="div"
            count={totalRecords}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[10, 25, 50]}
          />
        </TableContainer>

        {/* Action Menu */}
        <Menu
          anchorEl={anchorEle}
          open={Boolean(anchorEle)}
          onClose={handleActionMenuClose}
        >
          {selectedCoach?.emailVerified ? (
            ""
          ) : (
            <MenuItem
              className="flex gap-2 items-center"
              onClick={() => {
                handleActionMenuClose();
                handleOpenDeleteDialog(selectedCoach);
              }}
            >
              <img className="h-5 w-5" src={deleteIcon} alt="supprimer" />
              <p className="mb-0">Supprimer</p>
            </MenuItem>
          )}
          <MenuItem className="flex gap-2 items-center" onClick={handleEdit}>
            <img className="h-5 w-5" src={updateIcon} alt="update" />
            <p className="mb-0">Modifier</p>
          </MenuItem>
        </Menu>
      </div>

      <Drawer
        anchor="right"
        open={isSidebarOpen}
        onClose={handleSidebarClose}
        PaperProps={{
          style: {
            width: "20%",
          },
        }}
      >
        <div className="p-6 bg-white h-full">
          <div className="flex justify-between mb-8 border-b-2">
            <div className="flex items-center gap-2">
              <img src={viewIcon} alt="view icon" className="h-5 w-7" />
              <h2 className="text-2xl font-bold text-[#0976BC] mb-0">
                Détails
              </h2>
            </div>
            <Button onClick={handleSidebarClose}>
              <img
                src={closeSidebarDetailsIcon}
                alt="Close"
                className="w-6 h-6"
              />
            </Button>
          </div>

          <h3 className="text-lg font-semibold text-[#0976BC] mb-4">
            Détails candidature
          </h3>
          {selectedUser ? (
            <>
              <table className="table-fixed w-full border rounded-md mb-6">
                <tbody>
                  <tr className="rounded-t-md">
                    <td className="text-[#0976BC] font-semibold p-3 bg-[#f7fcff] border-b border-r">
                      ID d'utilisateur
                    </td>
                    <td className="p-3 border-b break-words">
                      {" "}
                      {selectedUser?.id || "N/A"}
                    </td>
                  </tr>
                  <tr className="rounded-t-md">
                    <td className="text-[#0976BC] font-semibold p-3 bg-[#f7fcff] border-b border-r">
                      Email d'utilisateur
                    </td>
                    <td className="p-3 border-b break-words">
                      {" "}
                      {selectedUser?.email || "N/A"}
                    </td>
                  </tr>
                  <tr>
                    <td className="text-[#0976BC] font-semibold p-3 bg-[#f7fcff] border-b border-r">
                      Nom d'utilisateur
                    </td>
                    <td className="p-3 border-b break-words">
                      {" "}
                      {selectedUser?.firstName} {selectedUser?.lastName}
                    </td>
                  </tr>

                  <tr className="rounded-t-md">
                    <td className="text-[#0976BC] font-semibold p-3 bg-[#f7fcff] border-b border-r">
                      Rôle d'utilisateur
                    </td>
                    <td className="p-3 border-b break-words">
                      {selectedGroup?.name || "N/A"}
                    </td>
                  </tr>
                  <tr>
                    <td className="text-[#0976BC] font-semibold p-3 bg-[#f7fcff] border-b border-r">
                      Date d'invitation
                    </td>
                    <td className="p-3 border-b break-words">
                      {" "}
                      {selectedUser.createdTimestamp
                        ? formatDate(selectedUser?.createdTimestamp)
                        : "N/A"}
                    </td>
                  </tr>
                  <tr>
                    <td className="text-[#0976BC] font-semibold p-3 bg-[#f7fcff] border-r">
                      Statut
                    </td>
                    <td className="p-3 break-words">
                      {" "}
                      {selectedUser?.enabled ? "Actif" : "Bloqué"}
                    </td>
                  </tr>
                </tbody>
              </table>
            </>
          ) : (
            <p className="text-gray-600">Aucune information disponible</p>
          )}
        </div>
      </Drawer>

      {/* Confirmation Dialog for Delete */}
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className="flex justify-center mt-12">
          <img
            src={deleteIconUser}
            width={80}
            height={80}
            alt="deleteIconUser"
          />
        </div>
        <DialogTitle id="alert-dialog-title" className="text-center">
          {"Êtes-vous sûr de vouloir supprimer cet utilisateur ?"}
        </DialogTitle>
        <DialogContent className="text-center">
          Cette action est irréversible. Une fois supprimé, cet utilisateur ne
          pourra pas être restauré.
        </DialogContent>
        <DialogActions className="m-auto pb-8">
          <Button
            onClick={handleCloseDialog}
            variant="outlined"
            className="border-[#0976BC] text-[#0976BC] hover:bg-blue-100 rounded-full"
            style={{
              padding: "8px 24px",
              borderWidth: "2px",
            }}
          >
            Annuler
          </Button>
          <Button
            onClick={confirmDelete}
            variant="contained"
            className="bg-[#0976BC] hover:bg-[#065A94] text-white rounded-full"
            autoFocus
            style={{
              padding: "8px 24px",
              marginLeft: "16px",
            }}
          >
            Supprimer
          </Button>
        </DialogActions>
      </Dialog>

      <Outlet />
      <SuccessModal open={showSuccessModal} onClose={handleCloseSuccessModal} />
      <ToastContainer />
    </div>
  );
};

export default ListeDesCoachs;
