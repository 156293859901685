import React, { useState } from "react";
import BannerImage from "../../assets/BannerAppels.jpg";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import playVideo from "../../assets/playVideo.png";
import bannerVideocand from "../../assets/video/DidactiqueCopatransforme.mp4";


const Banner = ({ setSearchQuery }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpenModal = () => setIsOpen(true);
  const handleCloseModal = () => setIsOpen(false);
  return (
    <div className="flex justify-center items-center md:w-[80%] w-full mx-auto flex-col h-[40vh] sm:h-[30vh] lg:h-[30vh] xl:h-[45vh] 2xl:h-[40vh] relative pt-3 mb-4">
      <img
        src={BannerImage}
        className="h-[40vh] sm:h-[30vh] lg:h-[30vh] xl:h-[45vh] 2xl:h-[40vh] object-cover absolute rounded-none sm:rounded-[16px]"
        alt="Banner"
      />
      <div className="relative text-white flex flex-col justify-center items-center gap-1 md:gap-3">
        <h1 className="text-center font-roboto-slab ">Appels à candidature</h1>
        <p className="text-center md:w-[60%] w-[90%] m-0">
          Découvrez les appels à candidatures du COPA que nous proposons pour
          vous aider à lancer et développer votre entreprise.
        </p>
        {isOpen && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 z-50">
            <div className="relative w-[80%] h- bg-white rounded-lg shadow-lg overflow-hidden">
              <button
                onClick={handleCloseModal}
                className="absolute top-2 right-2 z-50 text-white bg-red-500 hover:bg-red-600 rounded-full p-2"
              >
                ✖
              </button>
              <video
                src={bannerVideocand}
                controls
                autoPlay
                className="w-full h-full"
              />
            </div>
          </div>
        )}
        <button
          onClick={handleOpenModal}
          className="d-flex items-center justify-center bg-[#FDBF27] text-[#3e3e3e] ps-3 pe-1 py-0 md:px-8 md:py-4 mt-1 md:mt-4 rounded-full shadow-lg transition-transform duration-300 hover:bg-[#f0b92c] hover:shadow-xl gap-2 text-sm "
        >
          Comment candidater au COPA
          <img src={playVideo} alt="playVideo" width={45}  />
        </button>
      </div>

      <div className="absolute bottom-0 translate-y-1/2 w-full flex justify-center">
        <div className="relative md:w-[60%] w-[90%]">
          <MagnifyingGlassIcon className="absolute left-3 top-1/2 transform -translate-y-1/2 h-6 w-6 text-[#0976BC]" />
          <input
            type="text"
            placeholder="Recherche..."
            className="w-full pl-10 pr-4 py-3 rounded-full border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 shadow-sm"
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>
      </div>
    </div>
  );
};

export default Banner;
