import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
} from "@mui/material";
import { CheckCircleIcon } from "@heroicons/react/24/solid";

const SuccessModal = ({ 
    open, 
    onClose, 
    title = "Action réussie",  
    bodyMessage = "L'opération a été effectuée avec succès.", 
    buttonText = "Retour au Dashboard", 
    icon = <CheckCircleIcon style={iconStyle} />
  }) => {
    return (
      <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
        <DialogTitle sx={titleStyle}>
          <Box sx={iconContainerStyle}>
            {icon} 
          </Box>
        </DialogTitle>
        <DialogContent sx={contentStyle}>
          <Typography variant="h6" sx={messageTitleStyle}>
            {title}
          </Typography>
          <Typography variant="body2" sx={messageBodyStyle}>
            {bodyMessage}
          </Typography>
        </DialogContent>
        <DialogActions sx={actionsStyle}>
          <Button onClick={onClose} sx={buttonStyle} variant="outlined">
            {buttonText} 
          </Button>
        </DialogActions>
      </Dialog>
    );
  };


const titleStyle = {
  textAlign: "center",
  color: "#0976BC",
  marginTop: "10px",
  fontSize: "22px",
};

const iconContainerStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "#f0f8ff",
  borderRadius: "50%",
  width: "80px",
  height: "80px",
  margin: "0 auto",
  marginBottom: "10px",
  border: "2px solid #0976BC",
};

const iconStyle = {
  color: "#0976BC",
  fontSize: "40px",
};

const contentStyle = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  textAlign: "center",
  padding: "20px",
};

const messageTitleStyle = {
  marginBottom: "0px",
};

const messageBodyStyle = {
  marginTop: "0px",
  fontSize: "13px",
  paddingLeft: "50px",
  paddingRight: "50px",
};

const actionsStyle = {
  justifyContent: "center",
  gap: "16px",
};

const buttonStyle = {
  color: "#fff",
  borderColor: "#0976BC",
  borderWidth: "1px",
  borderStyle: "solid",
  backgroundColor: "#0976BC",
  borderRadius: "25px",
  padding: "4px 20px",
  textTransform: "inherit",
  marginBottom: "20px",
  marginTop: "20px",
  "&:hover": { backgroundColor: "#fff", color: "#0976BC" },
};

export default SuccessModal;
