import React from "react";

const Page4 = ({ parapheCanvas, ParaphCoordinateur }) => {
  return (
    <div
      className="p-8 bg-white text-black"
      style={{
        width: "210mm",
        minHeight: "300mm",
        margin: "0 auto",
        padding: "10px",
        textAlign: "justify",
        boxSizing: "border-box",
        position: "relative",
      }}
    >
      <p className="mb-1 leading-[1.4]">
        La période de mise en œuvre de la présente convention est susceptible de
        modification suivant les dispositions de l’article 12 ci-dessous.
      </p>

      <h4 className="text-md font-bold mb-1">
        Article 4 : Modalités du financement
      </h4>
      <p className="mb-1 leading-[1.4]">
        Le montant total de la subvention tel que repris à l’article 2 de la
        présente convention représente, sauf dispositions contraires, le montant
        maximal de l’intervention de l’UCP-PADMPME.
      </p>

      <h4 className="text-md font-bold mb-1">
        Article 5 : Mode de paiement de la subvention
      </h4>
      <p className="mb-1 leading-[1.4]">
        Le Banquier du Bénéficiaire effectuera, sur instruction de l’UCP-PADMPME
        et sous le contrôle requis, les décaissements ou paiements directs au
        fournisseur de l'objet de l’investissement et ou de l’équipement
        identifié dans la demande d’investissement validée du Bénéficiaire
        (expression des besoins, propositions techniques et factures pro forma
        de fournisseurs/prestataires, procès-verbal de sélection d’un
        fournisseur/prestataire, facture pro forma négociée et validée) en
        cohérence avec le calendrier des étapes de mise en œuvre de
        l’investissement convenu inclus dans le plan d’affaires repris en
        annexe.
      </p>
      <p className="mb-1 leading-[1.4]">
        <strong>
          A titre de contrepartie de la subvention, le Bénéficiaire accepte
          d’apporter un financement complémentaire de 30% du coût total de son
          projet de développement pour la finalisation du financement des
          opérations : cet apport se fera par le versement effectif en
          numéraires ou par virement sur le compte du Bénéficiaire dédié au COPA
          et pourra provenir des fonds propres de l’entreprise et/ou d’un crédit
          obtenu auprès d’une institution financière (établissement bancaire,
          institution de microfinance ou autre).
        </strong>
      </p>
      <p className="mb-1 leading-[1.4]">
        L’acte ou la preuve de constitution de la contrepartie de la subvention
        sera annexé à la présente convention.
      </p>
      <p className="mb-1 leading-[1.4]">
        <strong>
          Nonobstant les dispositions du premier alinéa du présent article,
          aucun décaissement ne sera effectué au profit du Bénéficiaire tant que
          ce dernier n’aura pas fourni une preuve satisfaisante de l’existence
          dans son compte dédié COPA de son apport en numéraire couvrant le
          solde de 30% du coût total de son projet, objet de la présente
          convention.
        </strong>
      </p>
      <p className="mb-1 leading-[1.4]">
        Sauf dispositions contraires, ce financement est accordé sous forme de
        subvention non remboursable.
      </p>

      <h4 className="text-md font-bold mb-1">
        Article 6 : Respect de la destination
      </h4>
      <p className="mb-1 leading-[1.4]">
        La subvention ne doit être utilisée que pour la mise en œuvre du projet
        sélectionné dans le cadre du COPA et détaillé dans le document joint aux
        Annexes 1 ainsi que dans les amendements ultérieurs convenus par écrit
        entre parties. Pour éviter tout doute, la mise en œuvre du projet COPA
        exige que le Bénéficiaire exécute le plan de travail et les activités y
        afférentes et utilise les ressources de l'investissement conformément au
        budget repris dans le plan d’affaires validé par le Consortium.
      </p>

      <h4 className="text-md font-bold mb-1">
        Article 7 : Obligations générales
      </h4>
      <p className="mb-1 leading-[1.4]">
        Les parties exécutent le projet avec tout le soin, l’efficacité, la
        transparence et la diligence requises selon les principes de la bonne
        gouvernance entrepreneuriale, de la bonne gestion financière et des
        meilleures pratiques en la matière. A cet égard, ils mobilisent toutes
        les ressources nécessaires à la réalisation du projet du bénéficiaire.
      </p>
      <p className="mb-1 leading-[1.4]">
        Sous le contrôle du Consortium, le Bénéficiaire est le seul responsable
        de la réalisation de son plan d’affaires dans le cadre du COPA. Il
        s’assure que tous ses partenaires remplissent leurs obligations. Il est
        également responsable de toutes les violations des obligations résultant
        de la présente convention et commises par son personnel ou ses
        partenaires.
      </p>
      <p className="mb-1 leading-[1.4]">
        Le Bénéficiaire est seul responsable à l’égard des tiers, y compris pour
        les dommages de toute nature.
      </p>

      <div
        style={{
          position: "absolute",
          bottom: "10px",
          left: "50%",
          transform: "translateX(-50%)",
        }}
      >
        <p className="font-semibold">Page 4 sur 9</p>
      </div>
      {parapheCanvas && (
        <div
          style={{
            position: "absolute",
            bottom: "10px",
            left: "10px",
          }}
        >
          <img
            src={parapheCanvas}
            alt="Aperçu de la signature"
            style={{
              width: "70px",
              height: "60px",
              marginLeft: "20px",
              marginBottom: "-10px",
            }}
          />
        </div>
      )}
      {ParaphCoordinateur && (
        <div
          style={{
            position: "absolute",
            bottom: "10px",
            right: "10px",
            transform: "translateX(-50%)",
          }}
        >
          <img
            src={ParaphCoordinateur}
            alt="Aperçu de la signature"
            style={{
              width: "70px",
              height: "60px",
              marginLeft: "20px",
            }}
          />
        </div>
      )}
    </div>
  );
};

export default Page4;
