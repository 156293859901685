import { CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { PlainLight } from "survey-core/themes";
import { Model, Survey } from "survey-react-ui";
import { getSurveyResponseById } from "../../../../services/surveyResponse.service";
import axiosInstance from "../../../../helpers/axiosIntercepter";
import config from "../../../../assets/environments/config.json";

const DetailsDocumentCadrage = () => {
  const { id } = useParams();
  const [data, setData] = useState(null);
  const [surveyModel, setSurveyModel] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const BASE_URL = config.minioBaseUrlPublic;

  const getFileNameFromPath = (path) => {
    return path.substring(path.lastIndexOf("/") + 1);
  };

  const appendBaseUrlToNames = (data, survey) => {
    if (!survey || !data) {
      console.warn("Survey instance or data is missing.");
      return;
    }

    Object.keys(data).forEach((questionKey) => {
      const question = survey.getQuestionByName(questionKey);

      if (!question || question.getType() !== "file") {
        return;
      }

      const response = data[questionKey];

      if (Array.isArray(response)) {
        response.forEach((file) => {
          if (file.name) {
            file.content = `${BASE_URL}/${file.name}`;
            file.name = getFileNameFromPath(file.name);
          }
        });
      } else if (response && response.name) {
        response.content = `${BASE_URL}/${response.name}`;
        response.name = getFileNameFromPath(response.name);
      }
    });
  };

  const isUrlExpired = (url) => {
    const urlParams = new URLSearchParams(url.split("?")[1]);

    const amzDate = urlParams.get("X-Amz-Date");
    const amzExpires = urlParams.get("X-Amz-Expires");

    if (amzDate && amzExpires) {
      const expirationDate = new Date(
        amzDate.slice(0, 4) +
          "-" + // Year
          amzDate.slice(4, 6) +
          "-" + // Month
          amzDate.slice(6, 8) +
          "T" + // Day
          amzDate.slice(9, 11) +
          ":" + // Hour
          amzDate.slice(11, 13) +
          ":" + // Minute
          amzDate.slice(13, 15) +
          "Z" // Second
      );

      const expiresInMs = parseInt(amzExpires, 10) * 1000;
      const expirationTime = expirationDate.getTime() + expiresInMs;

      return Date.now() > expirationTime;
    }

    return false;
  };

  const fetchUpdatedUrl = async (fileName) => {
    try {
      const response = await axiosInstance.get(
        `/upload/url?fileName=${fileName}&strategy=minio&visibility=private&expirationTime=180`
      );
      return response.data;
    } catch (error) {
      console.log("Error in updating expired url", error);
      return null;
    }
  };

  const updatedFilesDate = async (questions) => {
    const updatedObject = { ...questions };

    for (const [key, value] of Object.entries(updatedObject)) {
      if (Array.isArray(value) && value[0]?.content) {
        const file = value[0];
        const isExpired = isUrlExpired(file.content);
        if (isExpired) {
          const newUrl = await fetchUpdatedUrl(file.name);
          if (newUrl?.url) {
            updatedObject[key] = [{ ...file, content: newUrl.url }];
          }
        }
      }
    }

    return updatedObject;
  };

  const onDownloadFile = async (survey, options) => {
    const { file } = options;
    const { content, name } = file;

    if (content && name) {
      try {
        const response = await axiosInstance.get(content, {
          responseType: "blob",
        });

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", name);
        document.body.appendChild(link);
        link.click();
        if (link.parentNode) {
          link.parentNode.removeChild(link);
        }
        window.URL.revokeObjectURL(url);
      } catch (error) {
        console.error("Error downloading file:", error);
        alert("Failed to download the file. Please try again.");
      }
    } else {
      console.error("File content or name is missing.");
      alert("File information is incomplete.");
    }
  };

  useEffect(() => {
    const fetchSurveyResponse = async () => {
      try {
        const responseData = await getSurveyResponseById(id);

        if (responseData) {
          const parsedJson = JSON.parse(
            responseData?.surveyResponse?.documentAnnexe?.fields
          );

          const survey = new Model(parsedJson);
          survey.onDownloadFile.add(onDownloadFile);

          let mainData = responseData?.surveyResponse?.annexeDocsResponses;
          mainData = await updatedFilesDate(mainData);

          survey.data = mainData;

          survey.mode = "display";
          survey.applyTheme(PlainLight);
          survey.locale = "fr";

          setSurveyModel(survey);
        }
        setData(responseData);
      } catch (error) {
        console.error("Error fetching survey response:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchSurveyResponse();
  }, [id]);

  if (loading) {
    return <CircularProgress size={24} />;
  }

  if (!data) {
    return <div>Data not found</div>;
  }

  return (
    <div className="mx-auto p-6 bg-card text-foreground  bg-slate-50 h-full">
      <h1 className="text-3xl font-bold mb-0">Détails de documents annexes</h1>
      <div className="border rounded-lg p-4 mt-3 bg-white ">
        <div className="space-y-3">
          {surveyModel && <Survey model={surveyModel} />}
        </div>
      </div>
      <div className="mt-6 flex justify-between">
        <button
          onClick={() => navigate(-1)}
          className="bg-gray-200 text-gray-700 px-4 py-2 rounded-lg hover:bg-gray-300"
        >
          Retour à la page d'accueil
        </button>
      </div>
    </div>
  );
};

export default DetailsDocumentCadrage;
