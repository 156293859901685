import React, { useCallback, useEffect, useState } from "react";
import { BsPlusSquare } from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";
import viewIcon from "../../../assets/viewIcon.png";
import {
  Button,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  MenuItem,
  Checkbox,
  IconButton,
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog,
  Menu,
} from "@mui/material";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { Bounce, toast, ToastContainer } from "react-toastify";
import {
  deleteFormation,
  getAllFormations,
} from "../../../services/formation.service";
import deleteIconFormation from "../../../assets/deleteIconActualite.png";
import deleteIcon from "../../../assets/supprimerIcon.png";
import updateIcon from "../../../assets/updateIcon.png";
import { MoreVert } from "@mui/icons-material";

const ListeFormations = () => {
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const [formations, setFormations] = useState([]);
  const [totalDocs, setTotalDocs] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedMenuFormation, setSelectedMenuFormation] = useState(null);

  const navigate = useNavigate();

  const fetchFormations = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await getAllFormations(page + 1, limit, searchTerm);
      setFormations(response.data);
      setTotalDocs(response.totalDocs);
    } catch (err) {
      setError(err.message || "An error occurred while fetching formations.");
    } finally {
      setLoading(false);
    }
  }, [page, limit, searchTerm]);

  useEffect(() => {
    fetchFormations();
  }, [fetchFormations]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("fr-FR", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  const handleMenuClick = (event, formation) => {
    setAnchorEl(event.currentTarget);
    setSelectedMenuFormation(formation);
  };

  const handleOpenDeleteDialog = () => {
    setOpenDialog(true);
    handleMenuClose();
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleEdit = () => {
    if (selectedMenuFormation) {
      navigate(
        `/coach-dashboard/formations/update/${selectedMenuFormation?.id}`
      );
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleDelete = async () => {
    try {
      if (selectedMenuFormation) {
        await deleteFormation(selectedMenuFormation?.id);
        setOpenDialog(false);

        fetchFormations();

        toast.success("La formation a été supprimée avec succès.", {
          position: "bottom-right",
          autoClose: 2000,
          theme: "colored",
          transition: Bounce,
        });
      }
    } catch (error) {
      toast.error("Erreur lors de la suppression de la formation.", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        transition: Bounce,
      });
    }
  };

  const handleViewDetailsClick = (formation) => {
    if (formation?.id) {
      navigate(`/coach-dashboard/formation/details/${formation?.id}`);
    }
  };

  return (
    <div className="mx-auto p-6 bg-card text-foreground bg-slate-50 h-full">
      <h1 className="text-3xl font-bold mb-0">Liste des formations</h1>
      <p className="text-gray-500 text-sm mb-2">
        Track and manage training activities.
      </p>
      <div className="flex justify-end mb-2">
        <Link to="create-formation" className="no-underline">
          <Button
            className="bg-[#0976BC] hover:bg-[#065A94] transition-colors duration-300 text-white px-4 py-2 rounded-md mb-0 flex items-center font-medium no-underline"
            startIcon={<BsPlusSquare />}
            style={{ textTransform: "none" }}
          >
            Créer une nouvelle formation
          </Button>
        </Link>
      </div>

      <div className="border rounded-lg p-3 bg-white">
        <div className="flex justify-between items-center mb-3">
          <div className="relative w-1/4">
            <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
              <MagnifyingGlassIcon className="h-5 w-5 text-gray-500" />
            </div>
            <input
              type="search"
              id="default-search"
              className="py-[12px] ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg focus:outline-none w-full"
              placeholder="Rechercher par titre..."
              onChange={handleSearchChange}
            />
          </div>
        </div>

        {loading ? (
          <div className="flex justify-center">
            <CircularProgress />
          </div>
        ) : formations.length > 0 ? (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox />
                  </TableCell>
                  <TableCell>Ref ID</TableCell>
                  <TableCell>Nom de formation</TableCell>
                  <TableCell>Domaine de formation</TableCell>
                  <TableCell>Date de création</TableCell>
                  <TableCell>Action</TableCell>
                  <TableCell>Détails</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {formations.map((formation) => (
                  <TableRow key={formation.id}>
                    <TableCell padding="checkbox">
                      <Checkbox />
                    </TableCell>
                    <TableCell>{formation.id}</TableCell>
                    <TableCell>{formation.title}</TableCell>
                    <TableCell>
                      <div className="flex flex-wrap gap-2">
                        {formation.categories?.map((category, index) => (
                          <span
                            key={`${formation.id}-${index}`}
                            className="text-[#3366CC] text-xs whitespace-nowrap px-[10px] py-[4px] bg-[#699BF736] rounded-full capitalize"
                          >
                            {category}
                          </span>
                        ))}
                      </div>
                    </TableCell>

                    <TableCell>{formatDate(formation.created_at)}</TableCell>
                    <TableCell>
                      <Link
                        to={`/coach-dashboard/formations/${formation?.id}`}
                        className="no-underline bg-[#0976BC] hover:bg-[#065A94] transition-colors duration-300 capitalize text-white font-normal shadow-none px-[12px] py-[6px] rounded-full text-xs"
                        style={{ minWidth: "50px", whiteSpace: "nowrap" }}
                      >
                        Assigner
                      </Link>
                    </TableCell>
                    <TableCell>
                      <div className="flex gap-2">
                        <IconButton
                          style={{
                            backgroundColor: "#fff",
                            borderRadius: "4px",
                            border: "1px solid #0976BC",
                            height: "32px",
                            width: "32px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            padding: "4px",
                            margin: "0",
                            cursor: "pointer",
                            transition: "all 0.2s",
                          }}
                          onClick={() => handleViewDetailsClick(formation)}
                        >
                          <img
                            className="w-6 h-4"
                            src={viewIcon}
                            alt="View Details"
                          />
                        </IconButton>
                        <IconButton
                          aria-controls="simple-menu"
                          aria-haspopup="true"
                          onClick={(event) => handleMenuClick(event, formation)}
                          style={{
                            backgroundColor: "#fff",
                            borderRadius: "4px",
                            border: "1px solid #0976BC",
                            height: "32px",
                            width: "32px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            padding: "8px 12px",
                            margin: "0",
                            cursor: "pointer",
                            transition: "all 0.2s",
                          }}
                        >
                          <MoreVert
                            style={{
                              color: "#0976BC",
                            }}
                          />
                        </IconButton>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>

            <TablePagination
              rowsPerPageOptions={[10, 25, 50]}
              component="div"
              count={totalDocs}
              rowsPerPage={limit}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        ) : (
          <TableRow>
            <TableCell colSpan={8} align="center">
              Aucune donnée affichée
            </TableCell>
          </TableRow>
        )}

        {/* Action Menu */}
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          <MenuItem onClick={handleEdit}>
            <div className="gap-2 flex">
              <img className="h-5 w-5" src={updateIcon} alt="update" />
              <p className="mb-0"> Modifier </p>
            </div>
          </MenuItem>
          <MenuItem onClick={handleOpenDeleteDialog}>
            <div className="gap-2 flex">
              <img className="h-5 w-5" src={deleteIcon} alt="supprimer" />
              <p className="mb-0"> Supprimer</p>
            </div>
          </MenuItem>
        </Menu>

        {/* Confirmation Dialog for Delete */}
        <Dialog
          open={openDialog}
          onClose={handleCloseDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <div className="flex justify-center mt-12">
            <img
              src={deleteIconFormation}
              width={80}
              height={80}
              alt="deleteIconActualite"
            />
          </div>
          <DialogTitle id="alert-dialog-title" className="text-center">
            {"Êtes-vous sûr de vouloir supprimer cet élément ?"}
          </DialogTitle>
          <DialogContent className="text-center">
            Cette action est irréversible. Une fois supprimé, cet élément ne
            pourra pas être restauré.
          </DialogContent>
          <DialogActions className="m-auto pb-8">
            <Button
              onClick={handleCloseDialog}
              variant="outlined"
              className="border-[#0976BC] text-[#0976BC] hover:bg-blue-100 rounded-full"
              style={{
                padding: "8px 24px",
                borderWidth: "2px",
              }}
            >
              Annuler
            </Button>
            <Button
              onClick={handleDelete}
              variant="contained"
              className="bg-[#0976BC] hover:bg-[#065A94] text-white rounded-full"
              autoFocus
              style={{
                padding: "8px 24px",
                marginLeft: "16px",
              }}
            >
              Supprimer
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <ToastContainer />
    </div>
  );
};

export default ListeFormations;
