import axiosInstance from "../helpers/axiosIntercepter";

export const uploadFile = async (
  file,
  subDirectory,
  expirationTime = 18000,
  visibility = "public"
) => {
  const formData = new FormData();
  formData.append("strategy", "minio");
  formData.append("subDirectory", subDirectory);
  formData.append("expirationTime", expirationTime.toString());
  formData.append("files", file);
  formData.append("visibility", visibility);

  try {
    const response = await axiosInstance.post(
      "/upload",
      formData
    );
    return response?.data?.urls;
  } catch (error) {
    console.error("Error uploading file:", error);
    throw new Error("File upload failed. Please try again.");
  }
};



export const getFileUrl = async (
  fileName,
  strategy = "minio",
  visibility = "public",
  expirationTime = 18000 
) => {
  try {
    const response = await axiosInstance.get(
      `/upload/url`, 
      {
        params: {
          fileName: fileName,
          strategy: strategy,
          visibility: visibility,
          expirationTime: expirationTime
        }
      }
    );

    return response?.data;
  } catch (error) {
    console.error("Error getting file URL:", error);
    throw new Error("Failed to retrieve file URL. Please try again.");
  }
};