import React, { useEffect, useState } from "react";
import { Button, CircularProgress } from "@mui/material";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import CreatableSelect from "react-select/creatable";
import { Bounce, toast, ToastContainer } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { uploadFile } from "../../../../services/uploadFile.service";
import {
  updateFormationn,
  getFormationById,
} from "../../../../services/formation.service";
// import PDFIcon from "../../../../assets/pdfIcon.png";
import fileIcon from "../../../../assets/file-icon.png";
import FolderIcon from "../../../../assets/file-icon.png";

const UpdateFormation = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [deletedMedia, setDeletedMedia] = useState([]);
  const { id } = useParams();

  const [initialValues, setInitialValues] = useState({
    title: "",
    description: "",
    categories: [],
    media: [],
    bannerImage: null,
  });

  const domaineOptions = [
    { value: "technologie", label: "Technologie" },
    { value: "developpement-durable", label: "Développement Durable" },
    { value: "gestion", label: "Gestion" },
    { value: "innovation", label: "Innovation" },
    { value: "finance", label: "Finance" },
  ];

  const validationSchema = Yup.object({
    title: Yup.string().required("Le titre est obligatoire"),
    description: Yup.string().required("La description est obligatoire"),
    categories: Yup.array()
      .min(1, "Au moins une catégorie est requise")
      .required("Les catégories sont obligatoires"),
    bannerImage: Yup.mixed().required("L'image de la formation est obligatoire"),
  });

  useEffect(() => {
    const fetchFormationData = async () => {
      try {
        const response = await getFormationById(id);
        setInitialValues({
          title: response.title || "",
          description: response.description || "",
          categories:
            response.categories?.map((c) => ({ value: c, label: c })) || [],
          media: response.media || [],
          bannerImage: response.bannerImage || null,
        });
      } catch (error) {
        console.error("Error fetching formation data:", error);
      }
    };

    fetchFormationData();
  }, [id]);

  const ensureHttps = (url) =>
    !url?.startsWith("https://") ? `https://${url}` : url;

  const handleSubmit = async (values) => {
    setLoading(true);
    try {
      let mediaArray = initialValues.media.filter(
        (file) => !deletedMedia.includes(file.url)
      );

      const newFiles = values.media.filter((file) => !file.url);
      if (newFiles.length > 0) {
        const uploadedMedia = await Promise.all(
          newFiles.map(async (file) => {
            const urls = await uploadFile(file, "formations", 18000, "public");
            return { name: file.name, url: ensureHttps(urls[0].url) };
          })
        );
        mediaArray = [...mediaArray, ...uploadedMedia];
      }

      let bannerImageUrl = initialValues.bannerImage;
      if (values.bannerImage && !values.bannerImage.url) {
        const uploadedBanner = await uploadFile(
          values.bannerImage,
          "formation-banners",
          18000,
          "public"
        );
        bannerImageUrl = ensureHttps(uploadedBanner[0].url);
      }

      const updatedValues = {
        title: values.title,
        description: values.description,
        categories: values.categories.map((cat) => cat.value || cat),
        media: mediaArray,
        bannerImage: bannerImageUrl,
      };

      await updateFormationn(id, updatedValues);

      toast.success("La formation a été mise à jour avec succès.", {
        position: "bottom-right",
        autoClose: 2000,
        theme: "colored",
        transition: Bounce,
      });

      setTimeout(() => navigate(-1), 2000);
    } catch (error) {
      console.error("Error during update:", error);
      toast.error("Erreur lors de la mise à jour de la formation.", {
        position: "bottom-right",
        autoClose: 2000,
        transition: Bounce,
      });
    } finally {
      setLoading(false);
    }
  };

  const renderMedia = (media) => {
    const isFile = media instanceof File;
    const mediaType = isFile
      ? media.type
      : media.url?.split(".").pop()?.toLowerCase();
    const mediaUrl = isFile ? URL.createObjectURL(media) : media.url;

    if (mediaType === "application/pdf" || mediaUrl?.endsWith(".pdf")) {
      return <img className="w-24 h-24" src={fileIcon} alt="pdf Icon" />;
    } else if (mediaType === "video/mp4" || mediaUrl?.endsWith(".mp4")) {
      return (
        <video
          src={mediaUrl}
          controls
          className="w-24 h-24 object-cover rounded"
        />
      );
    } else if (
      ["image/jpeg", "image/png", "image/jpg"].includes(mediaType) ||
      [".jpg", ".jpeg", ".png"].some((ext) => mediaUrl?.endsWith(ext))
    ) {
      return (
        <img
          className="w-16 h-16 object-cover"
          src={mediaUrl}
          alt={media.name || "Image"}
        />
      );
    } else {
      return <img className="w-20 h-20" src={FolderIcon} alt="Unknown File" />;
    }
  };

  return (
    <div className="mx-auto p-6 bg-slate-50 h-full">
      <h1 className="text-3xl font-bold mb-4">Modifier la formation</h1>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ setFieldValue, values }) => (
          <Form className="p-8 bg-white shadow-md rounded-lg">
            <div className="flex gap-4">
              <div className="w-1/2">
                <div className="mb-4">
                  <label className="block text-gray-800 mb-1">Titre</label>
                  <Field
                    name="title"
                    type="text"
                    className="w-full p-3 border rounded-md"
                  />
                  <ErrorMessage
                    name="title"
                    component="div"
                    className="text-red-500 text-sm"
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-gray-800 mb-1">
                    Description
                  </label>
                  <CKEditor
                    editor={ClassicEditor}
                    data={values.description}
                    onChange={(event, editor) =>
                      setFieldValue("description", editor.getData())
                    }
                  />
                  <ErrorMessage
                    name="description"
                    component="div"
                    className="text-red-500 text-sm"
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-gray-800 mb-1">Catégories</label>
                  <CreatableSelect
                    isMulti
                    options={domaineOptions}
                    value={values.categories}
                    onChange={(newValue) =>
                      setFieldValue("categories", newValue)
                    }
                    placeholder="Sélectionnez ou ajoutez des catégories"
                  />
                  <ErrorMessage
                    name="categories"
                    component="div"
                    className="text-red-500 text-sm"
                  />
                </div>
              </div>
              <div className="w-1/2">
                <div className="mb-4">
                  <label className="block text-gray-800 mb-1">
                    Image de la formation
                  </label>
                  <div
                    className="border-dashed border-2 border-gray-300 p-4 rounded-lg"
                    onClick={() =>
                      document?.getElementById("banner-upload").click()
                    }
                  >
                    <div className="flex flex-col items-center">
                      <p className="m-auto">📄 Télécharger une image</p>
                      <p className="text-sm text-gray-500">
                        .jpg, .jpeg, .png...
                      </p>
                    </div>
                    <input
                      id="banner-upload"
                      type="file"
                      className="hidden"
                      onChange={(e) =>
                        setFieldValue("bannerImage", e.target.files[0])
                      }
                    />
                  </div>
                  {values.bannerImage && (
                    <img
                      src={
                        typeof values.bannerImage === "string"
                          ? values.bannerImage
                          : URL.createObjectURL(values.bannerImage)
                      }
                      alt="Banner"
                      className="w-32 h-32 object-cover rounded mt-2"
                    />
                  )}
                  <ErrorMessage
                    name="bannerImage"
                    component="div"
                    className="text-red-500 text-sm"
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-gray-800 mb-1">Média</label>
                  <div
                    className="border-dashed border-2 border-gray-300 p-4 rounded-lg"
                    onClick={() =>
                      document.getElementById("media-upload").click()
                    }
                  >
                    <div className="flex flex-col items-center">
                      <p>📄 Télécharger des fichiers</p>
                    </div>
                    <input
                      id="media-upload"
                      type="file"
                      multiple
                      accept=".jpg,.jpeg,.png,.pdf,.mp4"
                      className="hidden"
                      onChange={(e) => {
                        const files = Array.from(e.target.files);
                        const allowedTypes = [
                          "image/jpeg",
                          "image/png",
                          "application/pdf",
                          "video/mp4",
                        ];

                        const validFiles = files.filter((file) =>
                          allowedTypes.includes(file.type)
                        );

                        if (validFiles.length !== files.length) {
                          toast.error(
                            "Certains fichiers n'ont pas été téléchargés en raison de formats non autorisés."
                          );
                        }

                        setFieldValue("media", [
                          ...values.media,
                          ...validFiles,
                        ]);
                      }}
                    />
                  </div>
                  <div className="flex flex-wrap gap-2 mt-2">
                    {values.media.map((file, index) => (
                      <div key={index} className="relative">
                        {renderMedia(file)}
                        <button
                          type="button"
                          onClick={() =>
                            setFieldValue(
                              "media",
                              values.media.filter((_, i) => i !== index)
                            )
                          }
                          className="absolute top-0 right-0 bg-red-500 text-white rounded-full w-6 h-6 flex items-center justify-center"
                        >
                          &times;
                        </button>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="flex justify-end gap-4">
              <Button
                variant="outlined"
                onClick={() => navigate(-1)}
                className="text-[#0976BC] border-[#0976BC]"
              >
                Annuler
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={loading}
              >
                {loading ? (
                  <CircularProgress size={20} />
                ) : (
                  <span>Valider</span>
                )}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
      <ToastContainer />
    </div>
  );
};

export default UpdateFormation;
