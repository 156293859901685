import { ClockIcon, MapPinIcon } from "@heroicons/react/24/outline";
import { EyeIcon } from "@heroicons/react/24/solid"; 
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const ProgramCard = ({ category, title, description, id, img, ddl }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isHovered, setIsHovered] = useState(false); 

  const imageUrl = img?.startsWith("http") ? img : `https://${img}`;

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString("fr-FR", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="bg-[#FFF] rounded-[15px] px-3 py-3 border-[0.75px] border-[#e8e8e8] flex flex-col lg:flex-row justify-center items-start gap-8 mx-auto shadow-md w-full max-w-5xl mb-2">
      <div
        className="relative w-full lg:w-[30%] rounded-[20px] flex justify-center items-start cursor-pointer"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onClick={openModal} 
      >
        <LazyLoadImage
          alt="Program"
          src={imageUrl}
          className="rounded-[20px] w-full h-60 object-cover"
        />
        {isHovered && (
          <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 rounded-[20px]">
            <EyeIcon className="w-10 h-10 text-white" /> 
          </div>
        )}
      </div>
      <div className="w-full lg:w-[70%]">
        <div className="flex flex-wrap gap-2 mb-1">
          {category?.map((cat, index) => (
            <p
              key={index}
              className="text-[#EB2630] text-xs whitespace-nowrap px-[10px] py-[4px] bg-[#FCDEE0] w-min rounded-full capitalize"
            >
              {cat}
            </p>
          ))}
        </div>
        <h3 className="text-xl lg:text-2xl font-semibold my-1">{title}</h3>
        <p
          className="text-sm lg:text-base text-gray-700 line-clamp-4 m-0"
          dangerouslySetInnerHTML={{ __html: description }}
        ></p>
        <hr className="my-2" />
        <div className="flex flex-wrap items-center text-sm text-gray-600 gap-1">
          <p className="flex items-start gap-[10px] mb-0 w-full">
            <ClockIcon className="w-5 h-5 text-[#0976BC]" />
            <span className="text-xs pe-[10%]">{formatDate(ddl)}</span>
          </p>
          <p className="flex items-start gap-[10px] mb-0">
            <MapPinIcon className="w-6 h-6 text-[#0976BC]" />
            <span className="text-xs pe-[10%]">
              Kinshasa, Mbuji-Mayi, Kananga, Goma, Bunia, Bukavu, Matadi,
              Mbanza-Ngungu, Kimpese, Kisantu, Kansangulu, Boma et Muanda
            </span>
          </p>
        </div>
        <div className="flex justify-end items-end mt-2 lg:mt-0">
          <button className="bg-[#0976BC] text-white no-underline rounded-full px-3 py-1 hover:bg-[#065A94] transition-colors duration-300">
            <Link
              to={`/appels-a-candidature/details/${id}`}
              className="no-underline text-white text-sm"
            >
              Découvrir
            </Link>
          </button>
        </div>
      </div>

      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-75">
          <div className="relative">
            <button
              className="absolute top-2 right-2 bg-white text-black rounded-full p-1"
              onClick={closeModal}
            >
              ✕
            </button>
            <LazyLoadImage
              alt="Program"
              src={imageUrl}
              className="w-auto max-h-screen rounded-lg object-cover"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default ProgramCard;
