import React, { useEffect, useState } from "react";
import {
  CircularProgress,
  TextField,
  MenuItem,
  Select,
  FormControl,
} from "@mui/material";
import { Bounce, ToastContainer, toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import {
  getProgramById,
  updateProgram,
  updateProgramStatus,
} from "../../../../services/program.service";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import CreatableSelect from "react-select/creatable";

const DetailsAppelACandidature = () => {
  const [programById, setProgramById] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [updatedProgram, setUpdatedProgram] = useState({});
  const [originalStatus, setOriginalStatus] = useState("");
  const [imageFile, setImageFile] = useState(null);
  const [imagePreviewUrl, setImagePreviewUrl] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [categorySuggestions, setCategorySuggestions] = useState([
    { value: "technology", label: "Technologie" },
    { value: "business", label: "Business" },
    { value: "education", label: "Éducation" },
    { value: "health", label: "Santé" },
  ]);

  const { id } = useParams();
  const navigate = useNavigate();

  const imageUrl = programById?.image?.url?.startsWith("http")
    ? programById?.image?.url
    : `https://${programById?.image?.url}`;

  useEffect(() => {
    const getProgram = async () => {
      try {
        const data = await getProgramById(id);
        setProgramById(data);

        const transformedCategories = data.categories
          ? data.categories.map((cat) => ({ value: cat, label: cat }))
          : [];

        setUpdatedProgram({
          ...data,
          categories: transformedCategories,
        });
        setOriginalStatus(data?.status);
      } catch (error) {
        console.error("Error fetching program:", error);
      }
    };

    if (id) {
      getProgram();
    }
  }, [id]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUpdatedProgram((prevProgram) => ({
      ...prevProgram,
      [name]: value,
    }));
  };

  const handleCKEditorChange = (name, editorData) => {
    setUpdatedProgram((prevProgram) => ({
      ...prevProgram,
      [name]: editorData,
    }));
  };

  const handleCategoryChange = (newValue) => {
    setUpdatedProgram((prevProgram) => ({
      ...prevProgram,
      categories: newValue,
    }));
    setCategorySuggestions((prevSuggestions) => [
      ...prevSuggestions,
      ...newValue.filter(
        (item) =>
          !prevSuggestions.some((suggestion) => suggestion.value === item.value)
      ),
    ]);
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setImageFile(file);

    const reader = new FileReader();
    reader.onloadend = () => {
      setImagePreviewUrl(reader.result);
    };
    if (file) {
      reader.readAsDataURL(file);
    } else {
      setImagePreviewUrl(null);
    }
  };

  const handleUpdateProgram = async () => {
    const missingFields = [];
    if (!updatedProgram.title) missingFields.push("Titre");
    if (!updatedProgram.expiresIn)
      missingFields.push("Date limite de soumission");
    if (!updatedProgram.description) missingFields.push("Description");
    if (!updatedProgram.exigence) missingFields.push("Exigence");

    if (missingFields.length > 0) {
      toast.error(
        `Veuillez remplir les champs obligatoires suivants : ${missingFields.join(
          ", "
        )}.`,
        {
          position: "bottom-right",
          autoClose: 3000,
          theme: "colored",
          transition: Bounce,
        }
      );
      return;
    }
    setIsLoading(true);

    try {
      const formData = new FormData();
      formData.append("title", updatedProgram.title);
      formData.append("expiresIn", updatedProgram.expiresIn);
      formData.append("description", updatedProgram.description);
      formData.append("exigence", updatedProgram.exigence);
      formData.append("status", updatedProgram.status);

      const categories = updatedProgram.categories
        .map((cat) => (typeof cat === "object" ? cat.value : cat))
        .join(",");
      formData.append("categories", categories);

      if (imageFile) {
        formData.append("image", imageFile);
      }
      // Update status if changed
      if (updatedProgram.status !== originalStatus) {
        await updateProgramStatus(id, updatedProgram.status);
      }

      // Send the update request
      await updateProgram(id, formData);

      toast.success("L'appel à candidature mis à jour avec succès!", {
        position: "bottom-right",
        autoClose: 2000,
        theme: "colored",
        transition: Bounce,
      });

      setIsEditing(false);
      const updatedData = await getProgramById(id);
      setProgramById(updatedData);
      setImagePreviewUrl(null);

      setTimeout(() => {
        navigate("/unite_de_gestion-dashboard/Appels-à-candidatures");
      }, 2000);
    } catch (error) {
      console.error("Error updating program:", error);
      toast.error("Erreur lors de la mise à jour de l'appel à candidature.", {
        position: "bottom-right",
        autoClose: 2000,
        theme: "colored",
        transition: Bounce,
      });
    }
  };

  if (!programById) {
    return (
      <div className="flex justify-center items-center h-full">
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className="mx-auto p-6 bg-card text-foreground  bg-slate-50 h-full">
      <div className="flex justify-between">
        <h1 className="text-3xl font-bold mb-2">
          Détails appel à candidatures
        </h1>
        <div>
          {isEditing ? (
            <button
              onClick={handleUpdateProgram}
              variant="contained"
              className="bg-[#1976d2] text-white px-4 py-2 mb-2 rounded-lg hover:bg-[#1565c0]"
              disabled={isLoading}
            >
              {isLoading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                "Sauvegarder"
              )}
            </button>
          ) : (
            <button
              onClick={() => setIsEditing(true)}
              variant="contained"
              color="primary"
              className="bg-gray-200 text-gray-700 px-4 py-2 mb-2 rounded-lg hover:bg-gray-300"
            >
              Modifier
            </button>
          )}
        </div>
      </div>
      <div className="border rounded-lg p-4 mt-3 bg-white ">
        <div className="space-y-3 table-back">
          <div className="mt-4 border border-slate-600 rounded-md text-sm overflow-hidden">
            <div className="flex items-center py-3  pe-4 bg-slate-50">
              <span className="w-[30%] pl-3 ">
                <strong>Titre :</strong>
              </span>
              {isEditing ? (
                <TextField
                  name="title"
                  value={updatedProgram.title || ""}
                  onChange={handleInputChange}
                  fullWidth
                />
              ) : (
                programById.title
              )}
            </div>

            <div className="flex  items-center py-3  pe-4 bg-slate-200">
              <span className="w-[30%] pl-3">
                <strong>Date limite de soumission :</strong>
              </span>
              {/* <div className='w-[100%]'> */}
              {isEditing ? (
                <div className="w-[100%]">
                  <TextField
                    name="expiresIn"
                    type="date"
                    value={
                      updatedProgram.expiresIn
                        ? new Date(updatedProgram.expiresIn)
                            .toISOString()
                            .slice(0, 10)
                        : ""
                    }
                    onChange={handleInputChange}
                    fullWidth
                  />
                </div>
              ) : (
                new Date(programById.expiresIn).toLocaleDateString("fr-FR")
              )}
              {/* </div> */}
            </div>

            {/* Replace Description field with CKEditor */}
            <div className="flex  items-center py-3  pe-4 bg-slate-50">
              <span className="w-[30%] pl-3">
                <strong>Description :</strong>
              </span>
              {isEditing ? (
                <CKEditor
                  style={{ width: "100%" }}
                  editor={ClassicEditor}
                  data={updatedProgram.description || ""}
                  onChange={(event, editor) =>
                    handleCKEditorChange("description", editor.getData())
                  }
                />
              ) : (
                <div
                  dangerouslySetInnerHTML={{ __html: programById.description }}
                />
              )}
            </div>

            {/* Replace Exigence field with CKEditor */}
            <div className="flex  items-center py-3  pe-4 bg-slate-200">
              <span className="w-[30%] pl-3">
                <strong>Exigences :</strong>
              </span>
              {isEditing ? (
                <CKEditor
                  style={{ width: "100%" }}
                  editor={ClassicEditor}
                  data={updatedProgram.exigence || ""}
                  onChange={(event, editor) =>
                    handleCKEditorChange("exigence", editor.getData())
                  }
                />
              ) : (
                <div
                  dangerouslySetInnerHTML={{ __html: programById.exigence }}
                />
              )}
            </div>

            <div className="flex items-center py-3  pe-4 bg-slate-50">
              <span className="w-[30%] pl-3">
                <strong>Status :</strong>
              </span>
              {isEditing ? (
                <FormControl fullWidth>
                  <Select
                    name="status"
                    value={updatedProgram.status || ""}
                    onChange={handleInputChange}
                  >
                    <MenuItem value="Publié">publié</MenuItem>
                    <MenuItem value="Brouillon">brouillon</MenuItem>
                    <MenuItem value="Fermé">fermé</MenuItem>
                  </Select>
                </FormControl>
              ) : (
                programById.status
              )}
            </div>

            {/* Category Select */}
            <div className="flex items-center py-3  pe-4 bg-slate-200">
              <span className="w-[30%] pl-3">
                <strong>Catégorie :</strong>
              </span>
              {isEditing ? (
                <CreatableSelect
                  isMulti
                  options={categorySuggestions}
                  value={updatedProgram.categories}
                  onChange={handleCategoryChange}
                  placeholder="Choisissez ou ajoutez des catégories"
                />
              ) : programById.categories &&
                programById.categories.length > 0 ? (
                programById.categories.join(", ")
              ) : (
                ""
              )}
            </div>

            {programById.image && programById.image.url && !imagePreviewUrl && (
              <div className="flex  items-center py-3  pe-4 bg-slate-50">
                <span className="w-[30%] pl-3">
                  <strong>Image :</strong>
                </span>
                <img
                  src={imageUrl}
                  alt={programById.image.name || "Program Image"}
                  className="max-w-xs rounded-lg shadow-lg"
                />
              </div>
            )}

            {isEditing && (
              <div className="flex  items-center py-3  pe-4 bg-slate-50">
                <span className="w-[30%] pl-3">
                  <strong>Changer l'image :</strong>
                </span>
                <input type="file" onChange={handleImageChange} />
              </div>
            )}

            {imagePreviewUrl && (
              <div className="flex  items-center py-3  pe-4">
                <span className="w-[30%] pl-3">
                  <strong>Image Preview:</strong>
                </span>
                <img
                  src={imagePreviewUrl}
                  alt="Preview"
                  className="max-w-xs rounded-lg shadow-lg"
                />
              </div>
            )}
          </div>
          <div className="mt-4 flex items-center gap-4">
            <div className="mt-6 flex justify-between">
              <button
                onClick={() => navigate(-1)}
                className="bg-gray-200 text-gray-700 px-4 py-2 rounded-lg hover:bg-gray-300"
              >
                Retour à la page d'accueil
              </button>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default DetailsAppelACandidature;
