import React from "react";
import { Button, CircularProgress } from "@mui/material";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import * as Yup from "yup";
import { uploadFile } from "../../../../services/uploadFile.service";
import { Bounce, toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import CreatableSelect from "react-select/creatable";
import { createFormation } from "../../../../services/formation.service";
import fileIcon from "../../../../assets/file-icon.png";
import "../../../Unite-De-Gestion-Dashboard/ListeDesActualites/CreateActualite/EditorStyles.css";

const CreateFormation = () => {
  const navigate = useNavigate();

  const initialValues = {
    title: "",
    description: "",
    category: [],
    media: [],
    trainingCategory: ["Général"],
    bannerImage: null,
  };

  const domaineOptions = [
    { value: "technologie", label: "Technologie" },
    { value: "developpement-durable", label: "Développement Durable" },
    { value: "gestion", label: "Gestion" },
    { value: "innovation", label: "Innovation" },
    { value: "finance", label: "Finance" },
  ];

  const validationSchema = Yup.object({
    title: Yup.string().required("Le titre est requis"),
    description: Yup.string().required("La description est requise"),
    media: Yup.array().min(1, "Le média est requis"),
    category: Yup.array()
      .min(1, "Au moins un domaine est requis")
      .required("Le domaine est requis"),
    bannerImage: Yup.mixed().required("L'image de la formation est requise"),
  });

  const ensureHttps = (url) =>
    url && !url.startsWith("https://") ? `https://${url}` : url;

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      let mediaArray = [];
      const uploadPromises = values.media.map(async (file) => {
        const urls = await uploadFile(file, "formation-media", 18000, "public");
        return { name: file.name, url: ensureHttps(urls[0].url) };
      });
      mediaArray = await Promise.all(uploadPromises);

      const bannerUpload = await uploadFile(
        values.bannerImage,
        "formation-banner",
        18000,
        "public"
      );
      const bannerImageUrl = ensureHttps(bannerUpload[0].url);

      const formationData = {
        title: values.title,
        description: values.description,
        categories: values.category.map((c) => c.value),
        domaines: values.trainingCategory,
        media: mediaArray,
        bannerImage: bannerImageUrl,
      };

      await createFormation(formationData);
      toast.success("La formation a été créée avec succès!", {
        position: "bottom-right",
        autoClose: 2000,
        theme: "colored",
        transition: Bounce,
      });
      resetForm();
      setTimeout(() => navigate(-1), 2000);
    } catch (error) {
      toast.error("Erreur lors de la création de la formation.", {
        position: "bottom-right",
        autoClose: 2000,
        theme: "colored",
        transition: Bounce,
      });
    } finally {
      setSubmitting(false);
    }
  };

  const handleTableHeaderStyling = (editor) => {
    editor.model.change((writer) => {
      const tables = editor.model.document.getRoot().getChildren();

      for (const table of tables) {
        const headerRow = table.getChild(0);
        if (headerRow) {
          for (const cell of headerRow.getChildren()) {
            writer.setAttribute("backgroundColor", "#0074d9", cell);
            writer.setAttribute("color", "#ffffff", cell);
          }
        }
      }
    });
  };

  return (
    <div className="mx-auto p-6 bg-card text-foreground bg-slate-50 h-full">
      <h1 className="text-3xl font-bold mb-0">Créer une nouvelle formation</h1>
      <p className="text-gray-500 text-sm mb-4">
        Track and manage customer information and activities.
      </p>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ setFieldValue, isSubmitting, values }) => (
          <Form className="p-8 bg-white shadow-md rounded-lg mt-2">
            <div className="flex gap-4">
              {/* Left Column */}
              <div className="w-[50%]">
                <div className="mb-3">
                  <label className="block text-gray-800 text-base mb-1">
                    Titre de la formation
                  </label>
                  <Field
                    type="text"
                    name="title"
                    className="w-full p-3 border border-gray-400 rounded-md"
                  />
                  <ErrorMessage
                    name="title"
                    component="div"
                    className="text-red-500 text-sm mt-1"
                  />
                </div>
                <div className="mb-3">
                  <label className="block text-gray-800 text-base mb-1">
                    Description de la formation
                  </label>
                  <CKEditor
                    editor={ClassicEditor}
                    data={values.description}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      setFieldValue("description", data);
                    }}
                    onReady={(editor) => handleTableHeaderStyling(editor)}
                  />
                  <ErrorMessage
                    name="description"
                    component="div"
                    className="text-red-500 text-sm mt-1"
                  />
                </div>
                <div className="mb-3">
                  <label className="block text-gray-800 text-base mb-1">
                    Domaine de la formation
                  </label>
                  <CreatableSelect
                    options={domaineOptions}
                    isMulti
                    value={values.category}
                    onChange={(newValue) => setFieldValue("category", newValue)}
                    placeholder="Entrer le domaine de la formation"
                  />
                  <ErrorMessage
                    name="category"
                    component="div"
                    className="text-red-500 text-sm mt-1"
                  />
                </div>
              </div>

              {/* Right Column */}
              <div className="w-[50%]">
                <div className="mb-3">
                  <label className="block text-gray-800 text-base mb-1">
                    Image de la formation
                  </label>
                  <div
                    className="border-dashed border-2 border-gray-300 p-4 text-center rounded-lg"
                    onClick={() =>
                      document.getElementById("banner-upload").click()
                    }
                  >
                    <p>📄 Télécharger une image</p>
                    <p className="text-sm text-gray-500">
                      .jpg, .jpeg, .png...
                    </p>
                    <Button variant="outlined" color="primary" className="mt-2">
                      Parcourir le fichier
                    </Button>
                    <input
                      id="banner-upload"
                      type="file"
                      accept="image/*"
                      onChange={(e) => {
                        const file = e.currentTarget.files[0];
                        setFieldValue("bannerImage", file);
                      }}
                      className="hidden"
                    />
                  </div>
                  {values.bannerImage && (
                    <div className="mt-2">
                      <img
                        src={URL.createObjectURL(values.bannerImage)}
                        alt="Banner Preview"
                        className="w-32 h-32 object-cover rounded"
                      />
                    </div>
                  )}
                  <ErrorMessage
                    name="bannerImage"
                    component="div"
                    className="text-red-500 text-sm mt-1"
                  />
                </div>
                <label className="block text-gray-800 text-base mb-1 mt-4">
                  Média
                </label>
                <div
                  className="border-dashed border-2 border-gray-300 p-4 text-center rounded-lg"
                  onClick={() =>
                    document.getElementById("media-upload").click()
                  }
                >
                  <p>📄 Télécharger des fichiers</p>
                  <Button variant="outlined" color="primary" className="mt-2">
                    Parcourir le fichier
                  </Button>
                  <input
                    id="media-upload"
                    type="file"
                    multiple
                    onChange={(e) => {
                      const filesArray = Array.from(e.currentTarget.files);
                      setFieldValue("media", [...values.media, ...filesArray]);
                    }}
                    className="hidden"
                  />
                </div>

                <div className="flex flex-wrap gap-2 mt-2">
                  {values.media &&
                    values.media.map((file, index) => (
                      <div key={index} className="relative">
                        {file?.type?.startsWith("image") ? (
                          <img
                            src={URL.createObjectURL(file)}
                            alt={file.name}
                            className="w-24 h-24 object-cover rounded"
                          />
                        ) : file.type.startsWith("video") ? (
                          <video
                            src={URL.createObjectURL(file)}
                            controls
                            className="w-24 h-24 object-cover rounded"
                          />
                        ) : (
                          <img
                            src={fileIcon}
                            alt={file.name}
                            className="w-24 h-24 object-cover rounded"
                          />
                        )}
                        <button
                          type="button"
                          onClick={() =>
                            setFieldValue(
                              "media",
                              values.media.filter((_, i) => i !== index)
                            )
                          }
                          className="absolute top-0 right-0 bg-red-500 text-white rounded-full w-6 p-1"
                        >
                          &times;
                        </button>
                      </div>
                    ))}
                </div>
                <ErrorMessage
                  name="media"
                  component="div"
                  className="text-red-500 text-sm mt-1"
                />
              </div>
            </div>
            <div className="d-flex justify-content-end mt-4 gap-4">
              <Button
                variant="outlined"
                onClick={() => navigate(-1)}
                className="text-[#0976BC] border-[#0976BC] hover:bg-[#f0f0f0] transition-colors duration-300 shadow-none rounded-full px-4 py-2"
                style={{
                  textTransform: "none",
                  color: "#0976BC",
                  borderColor: "#0976BC",
                }}
              >
                Annuler
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className="bg-[#0976BC] hover:bg-[#065A94] transition-colors duration-300 text-white shadow-none rounded-full px-4 py-2 flex items-center justify-center"
                style={{ textTransform: "none" }}
                disabled={isSubmitting}
              >
                {isSubmitting ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  <span>Valider</span>
                )}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
      <ToastContainer />
    </div>
  );
};

export default CreateFormation;
