import { createContext, useState } from "react";

export const ActiveMenuContext = createContext(null)

export default function ActiveMenuContextProvider ({children}) {
    const [activeMenu, setActiveMenu] = useState(true)
    return (
        <ActiveMenuContext.Provider value={{activeMenu, setActiveMenu}}>
            {children}
        </ActiveMenuContext.Provider>
    )
}