import React, { useState, useEffect, useCallback } from "react";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Checkbox,
  FormControl,
  Select,
  MenuItem,
  CircularProgress,
} from "@mui/material";
import {
  MagnifyingGlassIcon,
  ChevronDownIcon,
  CalendarIcon,
} from "@heroicons/react/24/solid";
import { BsFileEarmarkArrowDown } from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";
import debounce from "lodash.debounce";
import {
  getAllSurveyResponses,
  getAllSurveyResponsesEvaluator,
  getAllSurveyResponsesExportPdf,
} from "../../../services/surveyResponse.service";
import GetStatusLaureat from "../../../helpers/getStatusLaureat";
import axiosInstance from "../../../helpers/axiosIntercepter";
import assignCoachIcon from "../../../assets/assignCoachIcon.png";
import { toast, ToastContainer } from "react-toastify";

const ListeCandidatures = () => {
  const [loadingExport, setLoadingExport] = useState(false);
  const [page, setPage] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [status, setStatus] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selected, setSelected] = useState([]);
  const [candidatures, setCandidatures] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [totalCountEvaluator, setTotalCountEvaluator] = useState(0);
  const [loadingEvaluator, setLoadingEvaluator] = useState(false);
  const [pageEvaluator, setPageEvaluator] = useState(0);
  const [searchTermEvaluator, setSearchTermEvaluator] = useState("");
  const [selectedEvaluator, setSelectedEvaluator] = useState([]);
  const [rowsPerPageEvaluator, setRowsPerPageEvaluator] = useState(10);
  const [candidaturesEvaluator, setCandidaturesEvaluator] = useState([]);
  const [statusEvaluator, setStatusEvaluator] = useState("");
  const navigate = useNavigate();

  const statusOptions = [
    { value: "", label: "Tous les statuts" },
    { value: "Retenue", label: "Retenue" },
    { value: "Sélectionné", label: "Sélectionné" },
    { value: "SOUMIS", label: "Soumis" },
    { value: "Brouillon", label: "Brouillon" },
    { value: "Rejeté", label: "Rejeté" },
  ];

  // evaluator
  const fetchCandidaturesEvaluateur = useCallback(async () => {
    setLoadingEvaluator(true);
    try {
      const response = await getAllSurveyResponsesEvaluator({
        page: pageEvaluator + 1,
        limit: rowsPerPageEvaluator,
        searchTerm: searchTermEvaluator,
        status: statusEvaluator,
      });
      setCandidaturesEvaluator(response.data);
      setTotalCountEvaluator(response.totalDocs);
    } catch (error) {
      console.error("Error fetching candidatures:", error);
    } finally {
      setLoadingEvaluator(false);
    }
  }, [
    pageEvaluator,
    rowsPerPageEvaluator,
    searchTermEvaluator,
    statusEvaluator,
  ]);

  useEffect(() => {
    fetchCandidaturesEvaluateur();
  }, [fetchCandidaturesEvaluateur]);

  const handleStatusChangeEvaluator = (event) => {
    setStatusEvaluator(event.target.value);
    setPageEvaluator(0);
  };

  const handleChangePageEvaluator = (event, newPage) => {
    setPageEvaluator(newPage);
  };

  const handleChangeRowsPerPageEvaluator = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPageEvaluator(newRowsPerPage);
    setPageEvaluator(0);
  };

  const debouncedSearchEvaluator = useCallback(
    debounce((value) => {
      setSearchTermEvaluator(value);
      setPageEvaluator(0);
    }, 300),
    []
  );

  const handleSearchChangeEvaluator = (event) =>
    debouncedSearchEvaluator(event.target.value);

  const handleClickEvaluator = (event, id, candidature) => {
    const evaluatorIds = candidature?.evaluationActions?.map(
      (elem) => elem?.evaluatorKeyCloakId
    );
    console.log("Evaluator IDs:", evaluatorIds);

    if (evaluatorIds && evaluatorIds.some((id) => id)) {
      console.log(`Candidature ${id} is already assigned to an evaluator.`);
      return;
    }

    const selectedIndex = selectedEvaluator.indexOf(id);
    const newSelected = [...selectedEvaluator];

    if (selectedIndex === -1) {
      newSelected.push(id);
    } else {
      newSelected.splice(selectedIndex, 1);
    }

    setSelectedEvaluator(newSelected);
  };

  const isSelectedEvaluator = (id) => selectedEvaluator.indexOf(id) !== -1;

  //coach
  const fetchCandidatures = useCallback(async () => {
    setLoading(true);
    try {
      const response = await getAllSurveyResponses({
        page: page + 1,
        limit: rowsPerPage,
        searchTerm,
        status,
      });
      setCandidatures(response.data);
      setTotalCount(response.totalDocs);
    } catch (error) {
      console.error("Error fetching candidatures:", error);
    } finally {
      setLoading(false);
    }
  }, [page, rowsPerPage, searchTerm, status]);

  useEffect(() => {
    fetchCandidatures();
  }, [fetchCandidatures]);

  const handleStatusChange = (event) => {
    setStatus(event.target.value);
    setPage(0);
  };

  const handleChangePage = (event, newPage) => setPage(newPage);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // const handleSelectAllClick = (event) => {
  //   setSelected(
  //     event.target.checked
  //       ? candidatures.map((candidature) => candidature.id)
  //       : []
  //   );
  // };

  const handleClick = (event, id, candidature) => {
    if (candidature?.keycloakCoachId !== null) return;

    const selectedIndex = selected.indexOf(id);
    const newSelected = [...selected];

    if (selectedIndex === -1) {
      newSelected.push(id);
    } else {
      newSelected.splice(selectedIndex, 1);
    }
    setSelected(newSelected);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const debouncedSearch = useCallback(
    debounce((value) => {
      setSearchTerm(value);
      setPage(0);
    }, 300),
    []
  );

  const handleSearchChange = (event) => debouncedSearch(event.target.value);

  const handleExportClick = async () => {
    const queryParams = {
      status: status,
    };
    setLoadingExport(true);
    try {
      await getAllSurveyResponsesExportPdf(queryParams);
      toast.success(
        "L'exportation de la liste des candidatures a été réalisée avec succès !",
        {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
        }
      );
    } catch (error) {
      console.error(
        "Erreur lors de l'exportation de la liste des candidatures.",
        error
      );
      toast.error(
        "Erreur lors de l'exportation de la liste des candidatures.",
        {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
        }
      );
    } finally {
      setLoadingExport(false);
    }
  };

  const handleAssignCoachClick = () => {
    if (selected.length > 0) {
      const queryParams = selected.map((id) => `ids=${id}`).join("&");
      navigate(`assign-coach?${queryParams}`);
    }
  };

  const handleAssignEvaluatorClick = () => {
    if (selectedEvaluator.length > 0) {
      const queryParams = selectedEvaluator.map((id) => `ids=${id}`).join("&");
      navigate(`assign-evaluator?${queryParams}`);
    }
  };

  return (
    <>
      <div className="mx-auto p-6 bg-card text-foreground  bg-slate-50 h-[50%]">
        <h1 className="text-3xl font-bold mb-0">Liste des candidatures </h1>
        <p className="text-gray-500 text-sm mb-4">
          Suivez le statut des candidatures.
        </p>
        <div className="flex justify-end mb-4">
          <Button
            style={{
              textTransform: "none",
              backgroundColor:
                selectedEvaluator.length > 0 ? "#0976BC" : "#ccc",
              color: selectedEvaluator.length > 0 ? "white" : "#666",
              cursor: selectedEvaluator.length > 0 ? "pointer" : "not-allowed",
            }}
            className={`px-4 py-2 gap-2 rounded-md mb-0 flex items-center font-medium ${
              selectedEvaluator.length > 0
                ? "hover:bg-[#065A94] transition-colors duration-300"
                : ""
            }`}
            disabled={selectedEvaluator.length === 0}
            onClick={handleAssignEvaluatorClick}
          >
            <img
              src={assignCoachIcon}
              alt="assignCoachIcon"
              width={20}
              height={20}
            />
            Assigner un évaluateur
          </Button>
        </div>

        <div className="border rounded-lg p-3 bg-white">
          <div className="flex justify-between items-center mb-3">
            <div className="relative w-1/4">
              <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <MagnifyingGlassIcon className="h-5 w-5 text-gray-500" />
              </div>
              <input
                type="search"
                className="py-[12px] ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg focus:outline-none w-full"
                placeholder="Rechercher par nom, mail..."
                onChange={handleSearchChangeEvaluator}
              />
            </div>
            <div className="flex gap-[16px] ">
              <FormControl
                variant="outlined"
                sx={{ width: 160, fontFamily: "Arial, sans-serif" }}
              >
                <Select
                  value={statusEvaluator}
                  onChange={handleStatusChangeEvaluator}
                  displayEmpty
                  style={{ "height ": "45px" }}
                  IconComponent={() => (
                    <ChevronDownIcon className="w-7 text-gray mr-3" />
                  )}
                  renderValue={(selected) => {
                    const selectedOption = statusOptions.find(
                      (option) => option.value === selected
                    );
                    return selectedOption ? selectedOption.label : "Status";
                  }}
                  sx={{
                    backgroundColor: "white",
                    color: "#333",
                    borderRadius: "4px",
                    fontFamily: "Arial, sans-serif",
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "1px solid #dee2e6",
                    },
                    "& .MuiSelect-select": {
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      borderRadius: "30px",
                      paddingRight: "2.5rem",
                    },
                    "& .MuiSelect-icon": {
                      display: "none",
                    },
                  }}
                >
                  {statusOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <Button
                style={{
                  textTransform: "none",
                  fontFamily: "Arial, sans-serif",
                  fontSize: "13px",
                  fontWeight: "600",
                  position: "relative",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                className={`flex items-center text-gray-600 px-4 py-2 border border-gray-300 rounded-md hover:bg-transparent hover:border-gray-400 hover:bg-slate-50 hover:shadow-sm ${
                  loadingExport ? "cursor-not-allowed opacity-50" : ""
                }`}
                onClick={!loadingExport ? handleExportClick : null}
                disabled={loadingExport}
              >
                {loadingExport ? (
                  <CircularProgress
                    size={20}
                    style={{ marginRight: "8px", color: "#000000" }}
                  />
                ) : (
                  <BsFileEarmarkArrowDown className="mr-2" />
                )}
                {loadingExport ? (
                  <span>Exportation...</span>
                ) : (
                  <span>Exporter</span>
                )}
              </Button>
            </div>
          </div>
          <TableContainer
            className="relative overflow-y-auto rounded-lg border border-gray-300"
            style={{ height: "490px" }}
          >
            <Table>
              <TableHead>
                <TableRow hover>
                  <TableCell padding="checkbox">
                    <Checkbox
                    // checked={
                    //   selected.length > 0 &&
                    //   selected.length === candidatures.length
                    // }
                    // onChange={handleSelectAllClick}
                    />
                  </TableCell>
                  <TableCell>Nom du candidat</TableCell>
                  <TableCell>Adresse mail</TableCell>
                  <TableCell>Appel à candidature</TableCell>
                  <TableCell>Date de soumission</TableCell>
                  <TableCell>État</TableCell>
                  <TableCell>Statut</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loadingEvaluator ? (
                  <TableRow>
                    <TableCell colSpan={7} align="center">
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                ) : candidaturesEvaluator.length > 0 ? (
                  candidaturesEvaluator.map((candidature, index) => (
                    <TableRow
                      hover
                      onClick={(event) =>
                        handleClickEvaluator(event, candidature.id, candidature)
                      }
                      role="checkbox"
                      aria-checked={isSelectedEvaluator(candidature.id)}
                      tabIndex={-1}
                      key={candidature.id}
                      selected={isSelectedEvaluator(candidature.id)}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          disabled={
                            candidature.status !== "SOUMIS" ||
                            candidature?.evaluationActions?.some(
                              (elem) => elem?.evaluatorKeyCloakId
                            )
                          }
                          checked={isSelectedEvaluator(candidature.id)}
                          onChange={(event) =>
                            handleClickEvaluator(
                              event,
                              candidature.id,
                              candidature
                            )
                          }
                          style={{
                            color:
                              candidature.status !== "SOUMIS" ||
                              candidature?.evaluationActions?.some(
                                (elem) => elem?.evaluatorKeyCloakId
                              )
                                ? "#ccc"
                                : "",
                          }}
                        />
                      </TableCell>
                      <TableCell>
                        {candidature?.user?.firstName}{" "}
                        {candidature?.user?.lastName}
                      </TableCell>
                      <TableCell>{candidature?.user?.email}</TableCell>
                      <TableCell>{candidature.program?.title}</TableCell>
                      <TableCell>
                        <div className="flex items-center space-x-2">
                          <span className="p-1 rounded-full bg-gray-200">
                            <CalendarIcon className="h-5 w-5 text-gray-600" />
                          </span>
                          <span>
                            {new Date(
                              candidature?.updatedAt
                            ).toLocaleDateString("fr-FR")}
                          </span>
                        </div>
                      </TableCell>
                      <TableCell>
                        {candidature.percentage !== undefined ? (
                          <span>{candidature?.percentage?.toFixed(2)}%</span>
                        ) : (
                          <span>0%</span>
                        )}
                      </TableCell>

                      <TableCell>
                        <GetStatusLaureat status={candidature.status} />
                      </TableCell>
                      <TableCell>
                        <Link
                          to={`/unite_de_gestion-dashboard/liste-condidatures/details/${candidature.id}`}
                          className="no-underline bg-[#0976BC] hover:bg-[#065A94] transition-colors duration-300 text-white px-2 py-1 rounded-full text-xs flex items-center justify-center"
                          style={{ minWidth: "50px", whiteSpace: "nowrap" }}
                        >
                          Voir détails
                        </Link>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={7} align="center">
                      Aucune donnée affichée
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={totalCountEvaluator}
            page={pageEvaluator}
            onPageChange={handleChangePageEvaluator}
            rowsPerPage={rowsPerPageEvaluator}
            onRowsPerPageChange={handleChangeRowsPerPageEvaluator}
            rowsPerPageOptions={[10, 25, 50]}
          />
        </div>
        <ToastContainer />
      </div>

      <div className="mx-auto p-6 bg-card text-foreground  bg-slate-50 h-[50%]">
        <div className="flex justify-end mb-4">
          <Button
            style={{
              textTransform: "none",
              backgroundColor: selected.length > 0 ? "#0976BC" : "#ccc",
              color: selected.length > 0 ? "white" : "#666",
              cursor: selected.length > 0 ? "pointer" : "not-allowed",
            }}
            className={`px-4 py-2 gap-2 rounded-md mb-0 flex items-center font-medium ${
              selected.length > 0
                ? "hover:bg-[#065A94] transition-colors duration-300"
                : ""
            }`}
            disabled={selected.length === 0}
            onClick={handleAssignCoachClick}
          >
            <img
              src={assignCoachIcon}
              alt="assignCoachIcon"
              width={20}
              height={20}
            />
            Assigner un coach
          </Button>
        </div>
        <div className="border rounded-lg p-3 bg-white">
          <div className="flex justify-between items-center mb-3">
            <div className="relative w-1/4">
              <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <MagnifyingGlassIcon className="h-5 w-5 text-gray-500" />
              </div>
              <input
                type="search"
                className="py-[12px] ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg focus:outline-none w-full"
                placeholder="Rechercher par nom, mail..."
                onChange={handleSearchChange}
              />
            </div>
            <div className="flex gap-[16px] ">
              <FormControl
                variant="outlined"
                sx={{ width: 160, fontFamily: "Arial, sans-serif" }}
              >
                <Select
                  value={status}
                  onChange={handleStatusChange}
                  displayEmpty
                  style={{ "height ": "45px" }}
                  IconComponent={() => (
                    <ChevronDownIcon className="w-7 text-gray mr-3" />
                  )}
                  renderValue={(selected) => {
                    const selectedOption = statusOptions.find(
                      (option) => option.value === selected
                    );
                    return selectedOption ? selectedOption.label : "Status";
                  }}
                  sx={{
                    backgroundColor: "white",
                    color: "#333",
                    borderRadius: "4px",
                    fontFamily: "Arial, sans-serif",
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "1px solid #dee2e6",
                    },
                    "& .MuiSelect-select": {
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      borderRadius: "30px",
                      paddingRight: "2.5rem",
                    },
                    "& .MuiSelect-icon": {
                      display: "none",
                    },
                  }}
                >
                  {statusOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
          <TableContainer
            className="relative overflow-y-auto rounded-lg border border-gray-300"
            style={{ height: "490px" }}
          >
            <Table>
              <TableHead>
                <TableRow hover>
                  <TableCell padding="checkbox">
                    <Checkbox
                    // checked={
                    //   selected.length > 0 &&
                    //   selected.length === candidatures.length
                    // }
                    // onChange={handleSelectAllClick}
                    />
                  </TableCell>
                  <TableCell>Nom du candidat</TableCell>
                  <TableCell>Adresse mail</TableCell>
                  <TableCell>Appel à candidature</TableCell>
                  <TableCell>Date de soumission</TableCell>
                  <TableCell>État</TableCell>
                  <TableCell>Statut</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loading ? (
                  <TableRow>
                    <TableCell colSpan={7} align="center">
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                ) : candidatures.length > 0 ? (
                  candidatures.map((candidature, index) => (
                    <TableRow
                      hover
                      onClick={(event) =>
                        handleClick(event, candidature.id, candidature)
                      }
                      role="checkbox"
                      aria-checked={isSelected(candidature.id)}
                      tabIndex={-1}
                      key={candidature.id}
                      selected={isSelected(candidature.id)}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          disabled={
                            candidature.status !== "Sélectionné" ||
                            !!candidature?.keycloakCoachId
                          }
                          checked={
                            candidature.status === "Sélectionné" &&
                            isSelected(candidature.id)
                          }
                          onChange={(event) =>
                            handleClick(event, candidature.id, candidature)
                          }
                          style={{
                            color:
                              candidature.status !== "Sélectionné" ||
                              !!candidature?.keycloakCoachId
                                ? "#ccc"
                                : "",
                          }}
                        />
                      </TableCell>
                      <TableCell>
                        {candidature?.user?.firstName}{" "}
                        {candidature?.user?.lastName}
                      </TableCell>
                      <TableCell>{candidature?.user?.email}</TableCell>
                      <TableCell>{candidature.program?.title}</TableCell>
                      <TableCell>
                        <div className="flex items-center space-x-2">
                          <span className="p-1 rounded-full bg-gray-200">
                            <CalendarIcon className="h-5 w-5 text-gray-600" />
                          </span>
                          <span>
                            {new Date(
                              candidature?.updatedAt
                            ).toLocaleDateString("fr-FR")}
                          </span>
                        </div>
                      </TableCell>
                      <TableCell>
                        {candidature.percentage !== undefined ? (
                          <span>{candidature?.percentage?.toFixed(2)}%</span>
                        ) : (
                          <span>0%</span>
                        )}
                      </TableCell>

                      <TableCell>
                        <GetStatusLaureat status={candidature.status} />
                      </TableCell>
                      <TableCell>
                        <Link
                          to={`/unite_de_gestion-dashboard/liste-condidatures/details/${candidature.id}`}
                          className="no-underline bg-[#0976BC] hover:bg-[#065A94] transition-colors duration-300 text-white px-2 py-1 rounded-full text-xs flex items-center justify-center"
                          style={{ minWidth: "50px", whiteSpace: "nowrap" }}
                        >
                          Voir détails
                        </Link>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={7} align="center">
                      Aucune donnée affichée
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={totalCount}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[10, 25, 50]}
          />
        </div>
        <ToastContainer />
      </div>
    </>
  );
};

export default ListeCandidatures;
