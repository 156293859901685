import { Formik, Form } from "formik";
import React, { useState } from "react";
import InputField from "../common/InputField";
import Button from "../common/Button";
import * as Yup from "yup";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { verifyCaptcha } from "../../services/captcha.service";
import { sendContactEmail } from "../../services/contact.service";
import SuccessModal from "../common/SuccessModal";
const ContactForm = () => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [loading, setLoading] = useState(false);
  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const [hasError, setHasError] = useState(false);

  const handleCloseSuccessModal = () => {
    setSuccessModalOpen(false);
    setHasError(false);
  };
  const initialValues = {
    name: "",
    email: "",
    address: "",
    subject: "",
    message: "",
  };

  const validationSchema = Yup.object({
    name: Yup.string().required("Nom et prénom requis"),
    email: Yup.string()
      .email("Adresse e-mail invalide")
      .required("Adresse e-mail requise"),
    address: Yup.string().required("Ville requise"),
    subject: Yup.string().required("Sujet requis"),
    message: Yup.string().required("Message requis"),
  });

  const onSubmit = async (values, { resetForm }) => {
    setLoading(true);


    try {
      if (!executeRecaptcha) {
        setLoading(false);
        return;
      }
      const recaptchaToken = await executeRecaptcha();
      const verifiedCaptcha = await verifyCaptcha(recaptchaToken);
      if (verifiedCaptcha) {
        await sendContactEmail(values);
        setSuccessModalOpen(true);
        resetForm();
      } else {
        setHasError(true);
        setSuccessModalOpen(true);
      }
    } catch (error) {
      console.error("Error submitting contact form:", error);
      setHasError(true);
      setSuccessModalOpen(true);
    } finally {
      resetForm();
      setLoading(false);
    }
  };

  return (
    <div className="">
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        validateOnBlur
      >
        <div className="w-[90%] p-[20px] md:p-[35px] md:w-[70%] xl:w-[60%] mx-auto xl:p-[55px] rounded-2xl bg-white drop-shadow-lg border border-gray-400">
          <Form className="flex flex-col gap-3">
            <div className="lg:flex gap-2 grow">
              <InputField
                type="text"
                name="name"
                label="Nom et prénom *"
                placeholder="Votre nom et prénom"
              />
              <InputField
                type="email"
                name="email"
                label="Adresse e-mail *"
                placeholder="exemple@votremail.com"
              />
            </div>
            <div className="lg:flex gap-2 grow">
              <InputField
                type="text"
                name="address"
                label="Ville *"
                placeholder="Ville"
              />
              <InputField
                type="text"
                name="subject"
                label="Sujet *"
                placeholder="Informez-nous sur le sujet"
              />
            </div>
            <div>
              <InputField
                as="textarea"
                name="message"
                label="Message *"
                placeholder="Comment pouvons-nous vous aider ?"
                rows={5}
              />
            </div>
            <div className="mt-4 mb-2 flex justify-center">
              {loading ? (
                <div className="loader">Envoi en cours...</div>
              ) : (
                <Button
                  type="submit"
                  className="bg-[#0976BC] text-white no-underline rounded-full px-4 py-2 hover:bg-[#065A94] transition-colors duration-300"
                >
                  Envoyer le message
                </Button>
              )}
            </div>
          </Form>
        </div>
      </Formik>
      {successModalOpen && (
        <SuccessModal
          open={successModalOpen}
          onClose={handleCloseSuccessModal}
          title={hasError ? "Erreur d'envoi" : "Message envoyé avec succès"}
          bodyMessage={
            hasError
              ? "Une erreur est survenue. Merci de réessayer dans quelques instants."
              : "Merci, votre message a été envoyé avec succès. Nous reviendrons vers vous très prochainement."
          }
          buttonText="Retour au formulaire de contact"
        />
      )}
    </div>
  );
};

export default ContactForm;
