import React from "react";
import { Button, MenuItem, TextField, CircularProgress } from "@mui/material";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import * as Yup from "yup";
import { createActualite } from "../../../../services/actualites.service";
import { uploadFile } from "../../../../services/uploadFile.service";
import { Bounce, toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { categorySuggestions } from "./CategorySuggestion";
import CreatableSelect from "react-select/creatable";
import "./EditorStyles.css"; // Custom CSS for additional styling

const CreateActualite = () => {
  const initialValues = {
    title: "",
    description: "",
    category: [],
    media: [],
    status: "Brouillon",
  };

  const validationSchema = Yup.object({
    title: Yup.string().required("Le titre est requis"),
    description: Yup.string().required("La description est requise"),
    // category: Yup.array().min(1, "Choisissez au moins une catégorie"),
    media: Yup.array().min(1, "Le média est requis"),
    status: Yup.string()
      .oneOf(["publié", "brouillon", "fermé"], "Choisissez un statut valide")
      .required("Le statut est requis"),
  });

  const navigate = useNavigate();

  const ensureHttps = (url) =>
    url && !url.startsWith("https://") ? `https://${url}` : url;

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      let mediaArray = [];
      const uploadPromises = values.media.map(async (file) => {
        const urls = await uploadFile(file, "news-articles", 18000, "public");
        return { name: file.name, url: ensureHttps(urls[0].url) };
      });
      mediaArray = await Promise.all(uploadPromises);

      const actualiteData = {
        title: values.title,
        description: values.description,
        categories: values.category.map((c) => c.value),
        media: mediaArray,
        status: values.status,
      };

      await createActualite(actualiteData);
      toast.success("L'actualité a été créée avec succès!", {
        position: "bottom-right",
        autoClose: 2000,
        theme: "colored",
        transition: Bounce,
      });
      resetForm();
      setTimeout(() => navigate(-1), 2000);
    } catch (error) {
      toast.error("Erreur lors de la création de l'actualité.", {
        position: "bottom-right",
        autoClose: 2000,
        theme: "colored",
        transition: Bounce,
      });
    } finally {
      setSubmitting(false);
    }
  };

  const handleTableHeaderStyling = (editor) => {
    editor.model.change((writer) => {
      // Get all table elements in the editor content
      const tables = editor.model.document.getRoot().getChildren();

      for (const table of tables) {
        // Check if the table has a header row
        const headerRow = table.getChild(0); // Usually the first row is header
        if (headerRow) {
          // Apply blue background color to all cells in the header row
          for (const cell of headerRow.getChildren()) {
            writer.setAttribute("backgroundColor", "#0074d9", cell);
            writer.setAttribute("color", "#ffffff", cell); // Set text color to white
          }
        }
      }
    });
  };

  return (
    <div className="mx-auto p-6 bg-card text-foreground  bg-slate-50 h-full">
      <h1 className="text-3xl font-bold mb-0">Créer une nouvelle actualité</h1>
      <p className="text-gray-500 text-sm mb-4">
        Track and manage customer information and activities.
      </p>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ setFieldValue, isSubmitting, values }) => (
          <Form className="p-8 bg-white shadow-md rounded-lg mt-2">
            <div className="row">
              <div className="col-md-6 mb-3">
                <label className="block text-gray-800 text-base mb-1">
                  Titre de l'article
                </label>
                <Field
                  type="text"
                  name="title"
                  className="w-full p-3 border border-gray-400 rounded-md"
                />
                <ErrorMessage
                  name="title"
                  component="div"
                  className="text-red-500 text-sm mt-1"
                />
              </div>

              <div className="col-md-6 mb-3">
                <label className="block text-gray-800 text-base mb-1">
                  Statut
                </label>
                <Field
                  as={TextField}
                  select
                  name="status"
                  fullWidth
                  variant="outlined"
                  value={values.status}
                  onChange={(e) => setFieldValue("status", e.target.value)}
                  InputProps={{ style: { lineHeight: "40px" } }}
                >
                  <MenuItem value="publié">Publié</MenuItem>
                  <MenuItem value="brouillon">Brouillon</MenuItem>
                  <MenuItem value="fermé">Fermé</MenuItem>
                </Field>
                <ErrorMessage
                  name="status"
                  component="div"
                  className="text-red-500 text-sm mt-1"
                />
              </div>

              <div className="col-md-6 mb-3">
                <label className="block text-gray-800 text-base mb-1">
                  Description/ Objectif
                </label>
                <CKEditor
                  editor={ClassicEditor}
                  data={values.description}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setFieldValue("description", data);
                  }}
                  onReady={(editor) => handleTableHeaderStyling(editor)}
                  config={{
                    language: {
                      ui: "fr", 
                      content: "fr", 
                    },
                    toolbar: [
                      "heading",
                      "|",
                      "bold",
                      "italic",
                      "underline",
                      "link",
                      "bulletedList",
                      "numberedList",
                      "blockQuote",
                      "|",
                      "insertTable",
                      "tableColumn",
                      "tableRow",
                      "mergeTableCells",
                      "|",
                      "undo",
                      "redo",
                      "|",
                      "alignment",
                    ],
                    table: {
                      contentToolbar: [
                        "tableColumn",
                        "tableRow",
                        "mergeTableCells",
                        "tableProperties",
                        "tableCellProperties",
                      ],
                      tableProperties: {
                        borderColor: "#d3d3d3",
                        borderStyle: "solid",
                        borderWidth: "1px",
                        alignment: "center",
                        width: "100%",
                        height: "auto",
                        backgroundColor: "#f9f9f9",
                      },
                      tableCellProperties: {
                        borderColor: "#d3d3d3",
                        borderStyle: "solid",
                        borderWidth: "1px",
                        padding: "5px",
                        verticalAlignment: "middle",
                        horizontalAlignment: "center",
                      },
                    },
                  }}
                />
                <ErrorMessage
                  name="description"
                  component="div"
                  className="text-red-500 text-sm mt-1"
                />
              </div>

              <div className="col-md-6 mb-3">
                <label className="block text-gray-800 text-base mb-1">
                  Média
                </label>
                <div
                  className="border-dashed border-2 border-gray-300 p-4 text-center rounded-lg"
                  onClick={() =>
                    document.getElementById("media-upload").click()
                  }
                >
                  <p>📄 Télécharger des fichiers</p>
                  <p className="text-sm text-gray-500">
                    .jpeg, .png, .mp4... formats, up to 50MB
                  </p>
                  <Button variant="outlined" color="primary" className="mt-2">
                    parcourir le fichier
                  </Button>
                  <input
                    id="media-upload"
                    type="file"
                    multiple
                    accept=".jpg, .jpeg, .png, .mp4, .pdf, .doc, .docx"
                    onChange={(e) => {
                      const filesArray = Array.from(e.currentTarget.files);
                      setFieldValue("media", [...values.media, ...filesArray]);
                    }}
                    className="hidden"
                  />
                </div>
                <div className="flex flex-wrap gap-2 mt-2">
                  {values.media &&
                    values.media.map((file, index) => (
                      <div key={index} className="relative">
                        {file?.type?.startsWith("image") ? (
                          <img
                            src={URL.createObjectURL(file)}
                            alt={file.name}
                            className="w-24 h-24 object-cover rounded"
                          />
                        ) : file.type.startsWith("video") ? (
                          <video
                            src={URL.createObjectURL(file)}
                            controls
                            className="w-24 h-24 object-cover rounded"
                          />
                        ) : (
                          <p>{file.name}</p>
                        )}
                        <button
                          type="button"
                          onClick={() =>
                            setFieldValue(
                              "media",
                              values.media.filter((_, i) => i !== index)
                            )
                          }
                          className="absolute top-0 right-0 bg-red-500 text-white rounded-full w-6 p-1"
                        >
                          &times;
                        </button>
                      </div>
                    ))}
                </div>
                <ErrorMessage
                  name="media"
                  component="div"
                  className="text-red-500 text-sm mt-1"
                />
              </div>

              <div className="col-md-6 mb-3">
                <label className="block text-gray-800 text-base mb-1">
                  Catégories
                </label>
                <CreatableSelect
                  isMulti
                  options={categorySuggestions}
                  value={values.category}
                  onChange={(newValue) => setFieldValue("category", newValue)}
                  placeholder="Choisissez ou ajoutez des catégories"
                />
                <ErrorMessage
                  name="category"
                  component="div"
                  className="text-red-500 text-sm mt-1"
                />
              </div>
            </div>

            <div className="d-flex justify-content-end mt-4 gap-4">
              <Button
                variant="outlined"
                onClick={() => navigate(-1)}
                className="text-[#0976BC] border-[#0976BC] hover:bg-[#f0f0f0] transition-colors duration-300 shadow-none rounded-full px-4 py-2"
                style={{
                  textTransform: "none",
                  color: "#0976BC",
                  borderColor: "#0976BC",
                }}
              >
                Annuler
              </Button>

              <Button
                type="submit"
                variant="contained"
                color="primary"
                className="bg-[#0976BC] hover:bg-[#065A94] transition-colors duration-300 text-white shadow-none rounded-full px-4 py-2 flex items-center justify-center"
                style={{ textTransform: "none" }}
                disabled={isSubmitting}
              >
                {isSubmitting ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  <span>Publier</span>
                )}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
      <ToastContainer />
    </div>
  );
};

export default CreateActualite;
