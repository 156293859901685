import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Checkbox,
  FormControl,
  Select,
  MenuItem,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import {
  MagnifyingGlassIcon,
  ChevronDownIcon,
} from "@heroicons/react/24/solid";
import { CalendarIcon } from "@heroicons/react/24/outline";
import { getAllSurveyResponsesCoordinateur } from "../../../services/surveyResponse.service";
import assignCoachIcon from "../../../assets/assignCoachIcon.png";
import { useNavigate } from "react-router-dom";
import SignDocs from "../../Laureat-Dashboard/SignDocs";
import DocsSigné from "../../Laureat-Dashboard/DocsSigné";
import SignatureCoordinateur from "./SignatureCoordinateur/SignatureCoordinateur";
import { getStatusDetails } from "../../../helpers/getStatusColor";
import { getAllProgramsFilters } from "../../../services/program.service";

const ListeDesCandidaturesCoordinateur = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selected, setSelected] = useState([]);
  const [candidatures, setCandidatures] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [responseId, setResponseId] = useState();
  const [openDocsSigné, setOpenDocsSigné] = useState(false);
  const [openSignDocs, setOpenSignDocs] = useState(false);
  const [coachAssignmentStatus, setCoachAssignmentStatus] = useState("");
  const [openSignatureDialog, setOpenSignatureDialog] = useState(false);
  const [programFilters, setProgramFilters] = useState([]);
  const [selectedProgramFilter, setSelectedProgramFilter] = useState({});
  const [conventionFilter, setConventionFilter] = useState("");

  const navigate = useNavigate();

  const fetchCandidatures = useCallback(async () => {
    setLoading(true);
    try {
      const queryParams = {
        page: page + 1,
        limit: rowsPerPage,
        searchTerm,
        coachAssignmentStatus,
        status: "Retenue",
        programId: selectedProgramFilter?.id,
        conventionCordinateurStatus: conventionFilter,
      };

      const response = await getAllSurveyResponsesCoordinateur(queryParams);

      const filteredCandidatures = (response.data || []).filter(
        (candidature) =>
          candidature.laureatSignatureUrl &&
          candidature.laureatSignatureUrl.trim() !== ""
      );

      setCandidatures(filteredCandidatures);
      setTotalCount(filteredCandidatures.length);
    } catch (error) {
      console.error("Error fetching candidatures:", error);
    } finally {
      setLoading(false);
    }
  }, [
    page,
    rowsPerPage,
    searchTerm,
    coachAssignmentStatus,
    selectedProgramFilter,
    conventionFilter,
  ]);

  useEffect(() => {
    fetchCandidatures();
  }, [fetchCandidatures]);

  const conventionOptions = [
    { value: "", label: "Convention" },
    { value: "SIGNÉ", label: "Signé" },
    { value: "NON SIGNÉ", label: "Non signé" },
  ];

  useEffect(() => {
    const fetchProgramFilters = async () => {
      try {
        const filters = await getAllProgramsFilters();
        filters.unshift({ id: undefined, title: "Appels à candidature" });
        setProgramFilters(filters);
      } catch (error) {
        console.error("Error fetching program filters:", error);
      }
    };

    fetchProgramFilters();
  }, []);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("fr-FR", {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleCheckboxChange = (id) => {
    setSelected((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((itemId) => itemId !== id)
        : [...prevSelected, id]
    );
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const allIds = candidatures.map((candidature) => candidature.id);
      setSelected(allIds);
    } else {
      setSelected([]);
    }
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setPage(0);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const handleDetailsClick = (id) => {
    navigate(`/coordinateur-dashboard/candidatures/${id}`);
  };

  const handleSignerClick = () => {
    setOpenSignatureDialog(true);
  };

  const handleDialogClose = () => {
    setOpenSignatureDialog(false);
  };

  return (
    <div className="mx-auto p-6 bg-card text-foreground bg-slate-50 h-full">
      <h1 className="text-3xl font-bold mb-0">Liste des candidatures</h1>
      <p className="text-gray-500 text-sm mb-2">
        Suivez et gérez les informations et les activités des clients.
      </p>

      <div className="pb-4 flex justify-end">
        <FormControl variant="outlined" sx={{ width: 270 }}>
          <Select
            value={JSON.stringify(selectedProgramFilter)}
            onChange={(event) => {
              setSelectedProgramFilter(JSON.parse(event.target.value));
              setPage(0);
            }}
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
            IconComponent={() => (
              <ChevronDownIcon className="w-7 h-7 text-white mr-3" />
            )}
            renderValue={(selected) => (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <i className="bi bi-filter text-xl mx-2"></i>
                <span style={{ flex: 1, textAlign: "center" }}>
                  {selectedProgramFilter?.title || "Appels à candidature"}
                </span>
              </div>
            )}
            sx={{
              backgroundColor: "#0976BC",
              color: "white",
              borderRadius: "30px",
              "& .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
              "& .MuiSelect-select": {
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                borderRadius: "30px",
                paddingRight: "2.5rem",
              },
              "& .MuiSelect-icon": {
                display: "none",
              },
            }}
          >
            {programFilters.map((filter) => (
              <MenuItem key={filter.id} value={JSON.stringify(filter)}>
                {filter.title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>

      <div className="border rounded-lg p-3 bg-white">
        <div className="flex justify-between items-center mb-3">
          <div className="relative w-1/4">
            <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
              <MagnifyingGlassIcon className="h-5 w-5 text-gray-500" />
            </div>
            <input
              type="search"
              id="default-search"
              className="py-[12px] ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg focus:outline-none w-full"
              placeholder="Rechercher par nom du candidat..."
              onChange={handleSearchChange}
            />
          </div>

          <div className="flex justify-end gap-4">
            <FormControl variant="outlined" sx={{ width: 180 }}>
              <Select
                value={conventionFilter}
                onChange={(event) => {
                  setConventionFilter(event.target.value);
                  setPage(0); 
                }}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                IconComponent={() => (
                  <ChevronDownIcon className="w-7 h-7 text-gray mr-3" />
                )}
                renderValue={(selected) => {
                  const selectedOption = conventionOptions.find(
                    (option) => option.value === selected
                  );
                  return (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        width: "100%",
                      }}
                    >
                      <i className="bi bi-filter text-xl mx-1"></i>
                      <span style={{ flex: 1, textAlign: "center" }}>
                        {selectedOption ? selectedOption.label : "Convention"}
                      </span>
                    </div>
                  );
                }}
                sx={{
                  backgroundColor: "white",
                  color: "#333",
                  borderRadius: "4px",
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "1px solid #dee2e6",
                  },
                  "& .MuiSelect-select": {
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    borderRadius: "30px",
                    paddingRight: "2.5rem",
                  },
                  "& .MuiSelect-icon": {
                    display: "none",
                  },
                }}
              >
                {conventionOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <Button
              style={{
                textTransform: "none",
                backgroundColor: selected.length > 0 ? "#0976BC" : "#ccc",
                color: selected.length > 0 ? "white" : "#666",
                cursor: selected.length > 0 ? "pointer" : "not-allowed",
              }}
              className={`px-4 py-2 gap-2 rounded-md mb-0 flex items-center font-medium ${
                selected.length > 0
                  ? "hover:bg-[#065A94] transition-colors duration-300"
                  : ""
              }`}
              disabled={selected.length === 0}
              onClick={handleSignerClick}
            >
              <img
                src={assignCoachIcon}
                alt="assignCoachIcon"
                width={20}
                height={20}
              />
              Signer
            </Button>
          </div>
        </div>
        <TableContainer className="relative overflow-x-auto rounded-lg border border-gray-300">
          <Table>
            <TableHead>
              <TableRow hover>
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={
                      selected.length > 0 &&
                      selected.length === candidatures.length
                    }
                    onChange={handleSelectAllClick}
                    inputProps={{ "aria-label": "select all candidatures" }}
                    disabled={candidatures.some(
                      (candidature) => !!candidature?.cordinateurSignatureUrl
                    )}
                  />
                </TableCell>
                <TableCell>Ref ID</TableCell>
                <TableCell>Nom du candidat</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Appel à candidature</TableCell>
                <TableCell>Date de signature</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Convention</TableCell>
                <TableCell>Voir détails</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {loading ? (
                <TableRow>
                  <TableCell colSpan={8} align="center">
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              ) : candidatures.length > 0 ? (
                candidatures.map((candidature) => {
                  const isChecked = selected.includes(candidature?.id);
                  return (
                    <TableRow key={candidature.id}>
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isChecked}
                          onChange={() => handleCheckboxChange(candidature.id)}
                          inputProps={{ "aria-labelledby": candidature.id }}
                          disabled={!!candidature?.cordinateurSignatureUrl}
                        />
                      </TableCell>
                      <TableCell>{candidature?.id}</TableCell>
                      <TableCell>
                        {candidature.user?.firstName}{" "}
                        {candidature.user?.lastName}
                      </TableCell>
                      <TableCell>{candidature?.user?.email}</TableCell>
                      <TableCell>{candidature?.program?.title}</TableCell>
                      <TableCell>
                        <div className="flex items-center space-x-2">
                          <span className="p-1 rounded-full bg-gray-200">
                            <CalendarIcon className="h-5 w-5 text-gray-600" />
                          </span>
                          <span>{formatDate(candidature?.signedAt)}</span>
                        </div>
                      </TableCell>
                      <TableCell>
                        {getStatusDetails(candidature.status)}
                      </TableCell>
                      {/* <TableCell>{candidature?.conventionStatus}</TableCell> */}
                      <TableCell>
                        {candidature?.cordinateurSignatureUrl ? (
                          <span
                            style={{
                              color: "green",
                              cursor: "pointer",
                              textDecoration: "underline",
                            }}
                            className="flex"
                            onClick={() => {
                              setOpenDocsSigné(true);
                              setResponseId(candidature?.id);
                            }}
                          >
                            Convention <br /> signée
                          </span>
                        ) : (
                          <span
                            style={{
                              color: "red",
                              cursor: "pointer",
                              textDecoration: "underline",
                            }}
                            className=" flex p-auto"
                            onClick={() => {
                              setOpenDocsSigné(true);
                              setResponseId(candidature?.id);
                            }}
                          >
                            Convention <br /> non signée
                          </span>
                        )}
                      </TableCell>

                      {/* <TableCell>
                        <Button
                          className="no-underline bg-[#0976BC] hover:bg-[#065A94] transition-colors duration-300 capitalize text-white font-normal shadow-none px-[12px] py-[6px] rounded-full text-xs"
                          style={{ minWidth: "50px", whiteSpace: "nowrap" }}
                        >
                          Signer
                        </Button>
                      </TableCell> */}
                      <TableCell>
                        <span
                          className="text-[#FF0000] underline text-sm font-medium cursor-pointer"
                          onClick={() => handleDetailsClick(candidature?.id)}
                          style={{ minWidth: "50px", whiteSpace: "nowrap" }}
                        >
                          Détails ↗
                        </span>
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan={9} align="center">
                    Aucune donnée affichée
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          count={totalCount}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[10, 25, 50]}
        />
      </div>

      {openSignDocs && <SignDocs onClose={() => setOpenSignDocs(false)} />}
      {openDocsSigné && (
        <DocsSigné
          survey_response_id={responseId}
          onClose={() => setOpenDocsSigné(false)}
        />
      )}
      <Dialog
        open={openSignatureDialog}
        onClose={handleDialogClose}
        maxWidth="sm"
      >
        <DialogTitle>Signature</DialogTitle>
        <DialogContent>
          <SignatureCoordinateur
            setSelected={setSelected}
            selectedIds={selected}
            handleDialogClose={handleDialogClose}
            fetchCandidatures={fetchCandidatures}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ListeDesCandidaturesCoordinateur;
