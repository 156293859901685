import {
  MagnifyingGlassIcon,
} from "@heroicons/react/24/solid";
import {
  Checkbox,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import React, { useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AuthContext } from "../../../../contexts/AuthContext";
import { format } from "date-fns";
import { previewFormJuridique } from "../../../../helpers/previewFormJuridique";
import { ToastContainer, toast, Bounce } from "react-toastify";
import {
  getAllCadrageForms,
} from "../../../../services/form.service";
import { assignDocumentCadrage } from "../../../../services/cadrage.service";
import { ModalPreviewFormCadrage } from "./ModalPreviewFormulaireCadrage";
import debounce from "lodash.debounce";

const ListeDesFormulairesCadrage = () => {
  const navigate = useNavigate();
  const { userId } = useContext(AuthContext);
  const { id } = useParams();
  const [assigningId, setAssigningId] = useState(null);

  const [searchQuery, setSearchQuery] = useState("");
  const [page, setPage] = useState(null); 
  const [rowsPerPage, setRowsPerPage] = useState(10); 
  const [totalRows, setTotalRows] = useState(null);
  const [selected, setSelected] = useState([]); 

  const debouncedSearch = debounce((query) => {
    setSearchQuery(query);
    setPage(0); 
  }, 500);

  const handleSearchChange = (e) => {
    debouncedSearch(e.target.value);
  };

  const fetchCadrageForms = async () => {
    try {
      const data = await getAllCadrageForms({
        searchTerm: searchQuery, 
        page: page + 1, 
        per_page: rowsPerPage, 
      });
      setTotalRows(data?.totalDocs || 0); 
      return data?.data || []; 
    } catch (error) {
      console.error("Error fetching cadrage forms:", error);
      return [];
    }
  };

  const { data, isLoading } = useQuery({
    queryKey: ["cadrageForms", searchQuery, page, rowsPerPage], 
    queryFn: fetchCadrageForms,
    keepPreviousData: true, 
  });

  const handleChangePage = (_, newPage) => {
    setPage(newPage); 
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10)); 
    setPage(0); 
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = data.map((row) => row.id);
      setSelected(newSelected);
    } else {
      setSelected([]);
    }
  };

  const handleRowClick = (id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const UploadCadragedocs = async (formId) => {
    setAssigningId(formId);
    try {
      await assignDocumentCadrage(formId, [Number(id)]); 

      toast.success("Formulaire assigné avec succès", {
        position: "bottom-right",
        autoClose: 2000,
        theme: "colored",
        transition: Bounce,
      });

      navigate(
        `/coach-dashboard/candidatures/formulaires-cadrage/${id}/upload/${formId}`
      ); // Redirect
    } catch (error) {
      console.error("Error assigning document de cadrage", error);
      toast.error("Erreur lors de l'assignation du formulaire", {
        position: "bottom-right",
        autoClose: 2000,
      });
    } finally {
      setAssigningId(null);
    }
  };

  return (
    <div className="mx-auto p-6 bg-card text-foreground bg-slate-50 h-full">
      <h1 className="text-3xl font-bold mb-0">Liste des formulaires Cadrage</h1>
      <p className="text-gray-500 text-sm mb-2">
        Assigner des documents des cadrages
      </p>
      <div className="border rounded-lg p-3 bg-white">
        <div className="flex items-center justify-between mb-4">
          <div className="relative">
            <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
              <MagnifyingGlassIcon className="h-5 w-5 text-gray-500" />
            </div>
            <input
              type="search"
              id="default-search"
              className="py-[12px] ps-12 text-sm text-gray-900 border border-gray-300 rounded-lg focus:outline-none min-w-80"
              placeholder="Rechercher par date, Réf ID, Document,..."
              onChange={handleSearchChange}
            />
          </div>
        </div>

        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox
                      indeterminate={
                        selected.length > 0 && selected.length < data?.length
                      }
                      checked={
                        data?.length > 0 && selected.length === data?.length
                      }
                      onChange={handleSelectAllClick}
                    />
                  </TableCell>
                  <TableCell>ID Formulaire</TableCell>
                  <TableCell>Titre formulaire</TableCell>
                  <TableCell>Date de création</TableCell>
                  <TableCell>Visualiser formulaire</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading ? (
                  <TableRow>
                    <TableCell colSpan={6} align="center">
                      <CircularProgress size={24} />
                    </TableCell>
                  </TableRow>
                ) : (
                  data?.map((row) => (
                    <TableRow key={row.id} selected={isSelected(row.id)}>
                      <TableCell padding="checkbox">
                        <Checkbox checked={isSelected(row.id)} />
                      </TableCell>
                      <TableCell>{row.id}</TableCell>
                      <TableCell>{previewFormJuridique(row.fields)}</TableCell>
                      <TableCell>
                        {format(new Date(row.createdAt), "dd/MM/yyyy HH:mm:ss")}
                      </TableCell>
                      <TableCell>
                        <ModalPreviewFormCadrage formString={row.fields} />
                      </TableCell>
                      <TableCell>
                        <button
                          onClick={() => UploadCadragedocs(row.id)}
                          className="bg-[#0976BC] text-white rounded-full px-3 py-[5px] hover:bg-[#065A94] transition duration-300 text-xs"
                          disabled={assigningId === row.id}
                        >
                          {assigningId === row.id ? (
                            <CircularProgress size={16} color="inherit" />
                          ) : (
                            "Importer"
                          )}
                        </button>
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10]}
            component="div"
            count={totalRows || 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </div>
      <ToastContainer />
    </div>
  );
};

export default ListeDesFormulairesCadrage;
