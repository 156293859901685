import CheckCircleIcon from "../assets/check_circle.svg";
import TimeProgressIcon from "../assets/lets-icons_time-progress-duotone.svg";
import CancelIcon from "../assets/cancel.svg";
import { Link } from "react-router-dom";
import ConventionModalPreview from "../pages/coach/ListeCandidatures/ConventionPreviewModal/ConventionModalPreview";
import { remindSignConvention } from "../services/convention.service";
import { Bounce, toast, ToastContainer } from "react-toastify";

export const getStatusDetails = (status) => {
  let color, StatusIcon;

  switch (status) {
    case "Éligible":
      color = "text-[#091E42]";
      StatusIcon = CheckCircleIcon;
      break;
    case "Rejeté":
      color = "[#091E42]";
      StatusIcon = CancelIcon;
      break;
    case "SOUMIS":
    case "À Évaluer":
    case "Brouillon":
      color = "text-[#091E42]";
      StatusIcon = TimeProgressIcon;
      break;
    default:
      color = "text-[#091E42]";
      StatusIcon = CheckCircleIcon;
      break;
  }

  return (
    <div className="flex gap-[.5px] items-center ">
      {StatusIcon && (
        <img src={StatusIcon} alt={status} className="w-6 h-6 mr-1" />
      )}
      <span className={color}>
        {status === "SOUMIS" ? " à évaluer" : status?.toLowerCase()}
      </span>
    </div>
  );
};

export const getConventionStatusDetails = (status, files) => {
  let color, msg;

  switch (status) {
    case "NOT_SENT":
      color = "text-pink-500 text-center";
      msg = "-";
      break;
    case "NON SIGNÉ":
      color = "text-[#EB2630] align-middle -mb-[2px]";
      msg = "Non Signée";
      break;
    case "SIGNÉ":
      color = "text-[#05CD99]";
      msg = "Signée";
      break;
    default:
      color = "text-green-500";
      msg = "";
      break;
  }

  return (
    <div className="flex justify-center content-center">
      {msg === "Signée" ? (
        <ConventionModalPreview files={files} />
      ) : (
        <p className={color}>{msg}</p>
      )}
    </div>
  );
};

export const getAction = (status, conventionStatus, juridiqueStatus, id) => {
  let api, msg, link;
  const relancer = async () => {
    try {
      const response = await remindSignConvention(id);

      if (response.message) {
        toast.success("Signature relancer avec succès!", {
          position: "bottom-right",
          autoClose: 2000,
          theme: "colored",
          transition: Bounce,
        });
      }
    } catch (error) {
      console.log("erreur en relancant la candidature");
    }
  };

  switch (status) {
    case "Rejeté":
      api = false;
      msg = "Voir détails";
      link = `${id}`;
      break;
    case "À Évaluer":
    case "SOUMIS":
      api = false;
      msg = "Evaluation";
      link = `${id}`;
      break;
    case "Éligible":
      switch (conventionStatus) {
        case "NON SIGNÉ":
          msg = "Relancer";
          link = "";
          api = true;
          break;
        case "SIGNÉ":
          switch (juridiqueStatus) {
            case "NOT_SENT":
              msg = "Assigner formulaire juridique";
              link = `${id}/formulaires-juridique`;
              api = false;
              break;
            case "SENT":
            case "UPLOADED":
              msg = "Voir détails";
              link = `${id}/details-juridique`;
              api = false;
              break;
            default:
              msg = "Voir détails";
              link = `${id}`;
              api = false;
              break;
          }
      }
      break;
    default:
      msg = "Voir détails";
      link = `${id}`;
      break;
  }

  return (
    <div>
      <button onClick={api ? () => relancer() : undefined}>
        <Link className=" text-white no-underline" to={link}>
          {msg}
        </Link>
      </button>
      <ToastContainer />
    </div>
  );
};
