import axiosInstance from "../helpers/axiosIntercepter";

export const createFormJuridique = async (formData) => {
  try {
    const response = await axiosInstance.post(
      "/jurdique-form/create-juridique-survey",
      formData,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("error creating form", error);
    throw error;
  }
};

export const getAllFormsJur = async (queryParams) => {
  // const {
  //   page,
  //   limit,
  //   status,
  //   searchTerm,
  //   coachNote,
  //   startCreatedAt,
  //   endCreatedAt,
  //   coachId
  // } = queryParams;
  try {
    const response = await axiosInstance.get(
      "/jurdique-form/formulaire-juridique"
    );

    return response?.data;
  } catch (error) {
    console.error("Error fetching all forms:", error);
    throw error;
  }
};


export const assignFormJuridique = async (formJurId, candId) => {
  try {
    const response = await axiosInstance.post(
      `/jurdique-form/send-juridique/${formJurId}`, {'surveyResponseIds' : [ candId ] },
      {
        headers: {
          'Accept': 'application/json',
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching survey response ID:', error);
    throw new Error('Failed to fetch survey response ID. Please try again later.');
  }
};